<template>
    <v-app>
        <!-- Navigation Drawer -->
        <!-- <NavDrawer v-if="$vuetify.breakpoint.xsOnly && !$route.meta.hideNavbar" /> -->
        <!-- Top Navigation -->
        <TopNav v-if="GET_userAuthenticated && !$route.meta.hideNavbar"/>
        <!-- No Connection Banner -->
        <NoConnection v-if="noconnection"/>
        <!-- No Database Banner -->
        <NoCloud v-if="GET_noCloud"/>
        <!-- Alert Box -->
        <!-- Terms and Conditions -->
        <TermsConditions />
        <!-- Main Content -->
        <v-main class="background">
			<!-- App Update Banner -->
			<AppUpdate />
            <!-- Side Navigation Bar - Tablet and Desktop Only -->
            <SideNav v-if="$vuetify.breakpoint.width >= 960 && GET_userAuthenticated && !$route.meta.hideNavbar" />
            <transition enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
                <!-- Router View -->
                <router-view />
            </transition>
        </v-main>
        <!-- Semi - Transparent Blurred Footer -->
        <!-- <div class="" v-if="$vuetify.breakpoint.xsOnly && GET_userAuthenticated && !$route.meta.hideNavbar" ></div> -->
        <!-- <div class="footerbanner frontend-low" v-if="$vuetify.breakpoint.xsOnly && GET_userAuthenticated && !$route.meta.hideNavbar" ></div> -->
        
		<!-- Bottom Navigation -->
        <BottomNav v-if="$vuetify.breakpoint.smAndDown && GET_userAuthenticated && !$route.meta.hideNavbar" mode="out-in" :style="$vuetify.breakpoint.smOnly ? 'padding-top:96px !important;' : $vuetify.breakpoint.xsOnly ? 'padding-top:108px !important;' : ''"/>
            <div :style="$vuetify.breakpoint.xsOnly && GET_showDrawer && GET_userAuthenticated && !$route.meta.hideNavbar ? 'margin-top:108px !important;' : ''" class="grey lighten-3"></div>
        
        <!-- </transition> -->
        <AlertBox />
    </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import TopNav from "./components/navigation/TopNav.vue";
// import NavDrawer from "./components/navigation/NavDrawer.vue";
import SideNav from "./components/navigation/SideNav.vue";
import BottomNav from "./components/navigation/BottomNav.vue";
import AppUpdate from "./components/general/AppUpdate.vue";
import NoConnection from "./components/general/NoConnection.vue";
import NoCloud from "./components/general/NoCloud.vue";
export default {
    name: "App",
    components: {
        TopNav,
        // NavDrawer,
        SideNav,
        BottomNav,
        AppUpdate,
        NoConnection,
        NoCloud,
    },

    data: () => ({
        noconnection: false,
        showDrawer: false,
    }),
    computed: {
      ...mapGetters({
            GET_showDrawer: 'GET_showDrawer',
            GET_currentUserAuth: 'GET_currentUserAuth',
            GET_userAuthenticated: 'GET_userAuthenticated',
            GET_noCloud: 'GET_noCloud',
        }),
    },
    methods: {
    },
    created() {
		// console.log('id = ' + this.MIX_generateId());
    },
        watch: {
           GET_showDrawer: {
            handler() {
                this.showDrawer = this.GET_showDrawer;
            },
            deep: true,
        },
    },
    mounted() {
        // console.log('apiUrl: ' + process.env.VUE_APP_API_URL)
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/* WIDTH / HEIGHT */
.full-width {
    width: 100% !important;
}
.half-width {
    width: 50% !important;
}
.full-height {
    height: 100% !important;
}
.full-viewpoint-width {
    width: 100vw !important;
}
.full-viewpoint-height {
    height: 100vh !important;
}
/* REMOVE BANNER LINE */
.v-banner__wrapper {
    border-bottom: 0px !important;
}
/* POSITIONING */
.fixed {
    position: fixed !important;
}
/* Z-INDEX HIERARCHY */
.foreground-highest {
    z-index: 99999 !important;
}
.foreground-high {
    z-index: 9999 !important;
}
.foreground-medium {
    z-index: 999 !important;
}
.foreground-low {
    z-index: 99 !important;
}
/* ROUNDED CORNERS */
.rounded-of {
  border-radius: 5px !important;
}
.rounded-of-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.rounded-of-bottom {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}
.footerbanner {
    bottom: 0px;
    width: 100vw !important;
    position: fixed !important;
    margin-bottom: 100px !important;
    height: 30px !important;
    background: -moz-linear-gradient(bottom, rgb(224, 224, 224) 30%, rgba(243, 243, 244, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom, rgb(224, 224, 224) 30%, rgba(243, 243, 244, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(224, 224, 224) 30%, rgba(243, 243, 244, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.v-item-group {
    margin-top: 5px !important;
}
a {
    text-decoration: none !important;
}

</style>