<template>
    <!-- Holding page - for users to be redirected to if their accounts are still pending or in any other status that isn't approved -->
    <div class="home full-width full-height d-flex flex-column align-center justify-center">
        <!-- Logo -->
        <v-row no gutters>
            <v-col cols="12" xs="12" sm="12" class="d-flex align-end my-3">
                <img src="@/assets/ardeals_colour.png" width="250" height="50" contain />
            </v-col>
        </v-row>

        <!-- Account Status -->
        <v-row class="d-flex justify-center">
            <v-col cols="12" xs="12" sm="12" class="text-center">

                <!--Personalize information-->
                <span class="text-h6">Welcome to <span class="primary--text">AR:Deals</span> </span><br /><br />

                <!--User Status information & instructions-->
                <span v-if="userStatus === 'pending'"
                    >Your account status is currently <span class="warning--text font-weight-bold">Pending</span>, please wait for your account to be Approved. <br /><br />If you have been waiting more than 24 hours for access then please contact <a :href="''" class="primary--text font-weight-bold">config.email</a></span
                >
                <span v-if="userStatus === 'approved'">Your account is currently <span class="success--text font-weight-bold">Approved</span>, please click the button to continue.</span>
                <span v-if="userStatus === 'suspended'"
                    >Your account is currently <span class="warning--text font-weight-bold">Suspended</span>, please contact <a :href="''" class="primary--text font-weight-bold">config.email</a
                    > for support if you require access.</span
                >
                <span v-if="userStatus === 'rejected'"
                    >Your account is currently <span class="error--text font-weight-bold">Rejected</span>, please contact <a :href="''" class="primary--text font-weight-bold">config.email</a
                    > for support if you require access.</span
                >
                <span v-if="userStatus === 'archived'"
                    >Your account is currently <span class="primary--text font-weight-bold">Archived</span>, please contact <a :href="''" class="primary--text font-weight-bold">config.email</a
                    > for support if you require access.</span
                >

				<!-- Buttons -->
                <br /><br />
                <div class="align-center"><v-btn depressed class="primary text-caption" @click.native="userApproved" v-if="userStatus === 'approved'">Home</v-btn></div>
                <div v-if="userStatus !== 'approved'" style="display: flex; justify-content: space-between; gap: 10px">
                    <AppButton class="primary text-caption" style="flex: 1" @click.native="checkItemStatus(userId)">Check Status</AppButton>
                    <AppButton class="primary text-caption" @click.native="FIREBASE_logout()" style="flex: 1">Log Out</AppButton>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "PendingView",
    // * DATA
    data: () => ({
        userStatus: null,
		userId: null,
		timer: null,
    }),

    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
    },
    // * METHODS
    methods: {
				...mapActions({
			ACT_currentUserProfile: "ACT_currentUserProfile",
		}),
		// TODO - Make this more secure
        async checkItemStatus(userId) {
            // console.log("checkItemStatus", userId);
			let checkUser = await this.REDIS_searchFor("user", "", "", "", "", `@deleted:{false} @createdUserId:{${userId}}`);
			let userStatus = checkUser.data.documents[0].status;
			this.userStatus = userStatus;
            // console.log("userStatus", userStatus);
        },

		//
		async userApproved() {
			// console.log("userApproved", this.$router);
			// console.log("this.userId", this.userId);
			let approvedUser = await this.REDIS_read("user", this.userId);
			let user = approvedUser.data;
			// console.log("approvedUser", user);
			this.ACT_currentUserProfile(user);
			// this.user = { ...this.$schema.user };
			this.$router.push("/");
		},
    },

    // * MOUNTED
    async mounted() {

		//Gets the user Id
		let userId = await this.GET_currentUserProfile.createdUserId;
		this.userId = userId;

		// Check the status initially
		this.checkItemStatus(userId);

        // Set up interval to check the status every minute
    this.timer = setInterval(() => {
		// console.log("temp running ");
      this.checkItemStatus(userId);
    }, 60 * 1000);
  },
    // * BEFORE DESTROY
	beforeDestroy() {
  clearInterval(this.timer)
},
	// * CREATED
    created() {

    },
};
</script>
