<template>
	<v-navigation-drawer v-model="showDrawer" app  clipped width="120" touchless :id="drawerId">
		<div v-for="(item, index) in MIX_filteredNav('SideNav')" :key="index">
		<v-list-item :class="item.title === 'Admin' ? 'bottom-element':''" :disabled="MIX_checkLevelAndStatus(item)"  @click="MIX_go(item.path)" class="text-center px-5">
			<v-list-item-content >
				<v-icon v-if="!MIX_checkLevelAndStatus(item)" class="grey--text text--darken-1" large :disabled="MIX_checkLevelAndStatus(item)">{{ item.icon }}</v-icon>
				<div v-if="!MIX_checkLevelAndStatus(item)" :disabled="MIX_checkLevelAndStatus(item)" class="fullwidth text-center text-body-2 font-weight-bold grey--text text--darken-1">{{ item.title }}</div>
			</v-list-item-content>
		</v-list-item>
		</div>
	</v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
	name: "SideNav",
	data: () => ({
		showDrawer: true,
	}),
	computed: {
		...mapGetters({
			GET_showDrawer: "GET_showDrawer",
			GET_currentUserAuth: "GET_currentUserAuth",
			GET_currentUserProfile: "GET_currentUserProfile",
			GET_userAuthenticated: "GET_userAuthenticated",
		}),
		drawerId() {
      return this.$vuetify.breakpoint.width <= 1260 ? "height-top-margin" : "";
    },
	},
	watch: {
        GET_showDrawer: function () {
            this.showDrawer = this.GET_showDrawer;
        },
        showDrawer: function () {
            this.ACT_showDrawer(this.showDrawer);
        },
    },
	methods: {
        ...mapActions({
            ACT_showDrawer: "ACT_showDrawer",
        }),
		// checkLevelAndStatus(item) {
		// 	if (item.enabled) {
		// 		if (this.GET_userAuthenticated) {
		// 			if (item.status !== undefined && item.level !== undefined) {
		// 				// console.log("status = ' + item.status + ' user status = ' + this.GET_currentUserProfile.status)
		// 				// console.log('level = ' + item.level + ' user level = ' + this.GET_currentUserProfile.level)
		// 				let checkError = 0;
		// 				if (!item.status.includes(this.GET_currentUserProfile.status)) {
		// 					checkError++;
		// 				}
		// 				if (!item.level.includes(this.GET_currentUserProfile.level)) {
		// 					checkError++;
		// 				}
		// 				if (checkError === 0) {
		// 					return false;
		// 				} else {
		// 					return true;
		// 				}
		// 			} else {
		// 				return true;
		// 			}
		// 		}
		// 	} else {
		// 		console.log('item.enabled = ' + item.enabled)
		// 		return true;
		// 	}
		// },
	},
};
</script>

<style scoped>
#height-top-margin {
  margin-top: 60px; /* Height of the top navigation bar */
}

.bottom-element {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* padding: 20px; */
  text-align: center;
  /* z-index: 1; */
}
</style>
