<template>
    <div>
        <!-- Notes: Component used for all the notes in the app -->
        <!-- IF ON EDIT/NEW MODE SHOW BELOW -->
        <div v-if="notesmode !== 'read'" class="d-flex align-center">
            <div class="flex-grow-1 pt-3">
                <TextArea backgroundcolor="white" label="Note" v-model="noteMessage" rows="2" />
            </div>
        </div>
        <v-row v-if="notesmode !== 'read'" dense class="mt-3">
            <v-spacer />
            <v-col cols="6" class="d-flex justify-end">
                <div class="flex-grow-1 pr-2" style="width: 50%"><AppButton block buttonclass="grey" @click.native="closeNote">Cancel</AppButton></div>
                <div class="flex-grow-1" style="width: 50%"><AppButton block buttonclass="secondary" @click.native="saveNote">Add</AppButton></div>
            </v-col>
        </v-row>

        <!-- IF ON VIEW MODE SHOW ADD NEW NOTE BUTTON -->
        <v-row v-if="notesmode === 'read'" dense no-gutters>
            <v-spacer />
            <v-col cols="6" class="d-flex justify-end">
                <div class="flex-grow-1 d-flex justify-end"><v-icon @click.native="changeviewmode">icons8-add-new</v-icon></div>
            </v-col>
        </v-row>

        <!-- NOTES -->
        <v-row dense class="mt-3">
            <v-col cols="12" v-for="(note, i) in orgnotes" :key="i">
                <BasicCard>
                    <div v-if="note.createdUserId === currentuser.entityId" class="d-flex justify-end pt-1">
                        <div class="primary--text" @click="deleteNote(i)"><v-icon size="20">icons8-close-window</v-icon></div>
                        <div class="primary--text px-2" @click="editNote(i)"><v-icon size="19">icons8-edit</v-icon></div>
                    </div>
                    <!-- edit mode -->
                    <div v-if="openEditor === i">
                        <TextArea backgroundcolor="white" label="Note" v-model="note.message" rows="2" />
                        <v-col cols="12" class="d-flex justify-end">
                            <div class="flex-grow-1 pr-2" style="width: 50%"><AppButton block buttonclass="grey" @click.native="openEditor = ''">Cancel</AppButton></div>
                            <div class="flex-grow-1" style="width: 50%"><AppButton block buttonclass="secondary" @click.native="saveChanges(i)">Save</AppButton></div>
                        </v-col>
                    </div>
                    <!-- read mode -->
                    <div v-else>
                        <div class="px-5 pt-3 black--text text--darken-2">{{ note.message }}</div>
                        <div class="px-5 py-1 text-overline">{{ MIX_formatDateTime(note.modifiedDateTime, "ISOString", "DD/MM/YYYY @ HH:mm") }} - {{ note.createdName }}</div>
                    </div>
                </BasicCard>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "ClientView",

    // props
    props: {
        orgnotes: {
            type: Array,
            default: () => [],
        },
        notesmode: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        currentuser: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        noteMessage: "",

        openEditor: "",
    }),

    // * METHODS
    methods: {
        // delete note
        deleteNote(clickedIndex) {
            this.$emit("deleteNote", this.orgnotes[clickedIndex], this.id);
        },
        // save new note
        saveNote() {
            let validationErrors = 0;
            if (this.noteMessage.trim() === "") {
                validationErrors++;
            }
            if (validationErrors > 0) {
                this.MIX_alertBox({ show: true, message: "Notes cannot be left blank", color: "error", timeout: "2000" });
                return;
            } else {
                this.$emit("saveNote", this.noteMessage, this.id);
                this.noteMessage = "";
            }
        },
        // edit notes
        editNote(clickedIndex) {
            this.openEditor = clickedIndex;
        },
        // save note changes
        saveChanges(clickedIndex) {
            let validationErrors = 0;
            if (this.orgnotes[clickedIndex].message.trim() === "") {
                validationErrors++;
            }
            if (validationErrors > 0) {
                this.MIX_alertBox({ show: true, message: "Notes cannot be left blank", color: "error", timeout: "2000" });
                return;
            } else {
                this.$emit("saveChanges", this.orgnotes[clickedIndex]);
                this.openEditor = "";
            }
        },
        // change mode to write
        changeviewmode() {
            this.$emit("updateprop", "write", this.id);
        },
        // change mode to read
        closeNote() {
            this.$emit("updateprop", "read", this.id);
        },
    },

    // * CREATED
    created() {
        this.note = { ...this.$schema.note };
    },
};
</script>
