<template>
    <div class="full-viewpoint-height primary" fluid>
        <!--Logo-->
        <v-row class="d-flex justify-center mt-6">
            <v-col cols="12" xs="12" sm="12" class="d-flex justify-center mt-8">
					<img src="@/assets/ardeals_white.png" width="250"  />
			</v-col>
        </v-row>

        <v-row class="d-flex align-center justify-center mt-8">
            <v-col cols="12" xs="12" sm="8" lg="7" xl="5" class="px-6" >
                <v-card class="pa-5 my-6 elevation-0 rounded-xl white">
                    <div class="text-center">
                        <div class="font-weight-bold primary--text text-h5 my-5 text-center">Reset Password</div>
                        <div class="primary--text text-h7">Please enter your email address to request a password reset</div>
                    </div>

                    <!--Form-->
                    <div class="my-5">
                        <v-form ref="form" lazy-validation>
                            <v-row class="d-flex align-center justify-center mx-2">
                                <v-col cols="12" xs="12" md="8" class="">
                                    <v-text-field v-model="form.userEmail" solo flat background-color="grey lighten-3" hide-details="auto" label="Email" type="email" class="rounded-km" :rules="emailRules" required> </v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </div>

                    <v-row class="d-flex justify-center text-center mt-2 px-5">
                        <v-col cols="12" xs="12" sm="6">
                            <v-btn block x-large class="white--text mb-8 elevation-0 rounded-km secondary white--text" @click="reset()" :disabled="form.userEmail === '' || loading" :loading="loading">Reset </v-btn>
                        </v-col>
                    </v-row>

                    <div class="text-h7 primary--text text-center pr-4">Already have an account? <span class="font-weight-bold text-h7 primary--text" style="cursor: pointer" @click="$router.push('/login')">Login</span></div>
                </v-card>
            </v-col>
        </v-row>
          <v-snackbar top transition="true"  v-model="alertData.show" :color="alertData.color" :timeout="alertData.timeout">
        {{ alertData.message }}
    </v-snackbar>
    </div>
</template>

<script>
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
export default {
    name: "ResetPassword",
    // * DATA
    data: () => ({
        //alert data
        alertData: {
            title: "",
            message: "",
            color: "",
            timeout: "2000",
            show: false,
        },
        // form
        inputBackgroundColor: "white",
        email: "",
        rules: [],
        showDone: false,
        form: {
            email: "",
        },
        loading: false,
        emailRules: [(v) => !!v || "Email is required", (v) => /.+@.+/.test(v) || "Email must be valid"],
    }),

    // * COMPUTED
    computed: {
        // Checks the size of viewport
        smalldevice() {
            return window.innerWidth < 680;
        },
    },

    // * METHODS
    methods: {
        reset() {
            if (this.$refs.form.validate()) {
                var t = this;
                t.loading = true;

                const auth = getAuth();

                sendPasswordResetEmail(auth, t.form.userEmail)
                    .then(function () {
                        // Email sent.
                        t.loading = true;
                        t.alertData = { title: "Account found", message: "A link to reset your password has been sent to your email address.", color: "success", timeout: 2000, show: true }
                        // Wrap the code that sets alertData and calls $nextTick() in a Promise
                        new Promise((resolve) => {
                            // Call $nextTick() inside the Promise
                            t.$nextTick(() => {
                                resolve();
                            });
                        }).then(() => {
                            // The code inside this then() block will only run after $nextTick() has finished
                            t.loading = false;
                            // Use setTimeout() to delay the call to $router.push()
                            setTimeout(() => {
                                t.$router.push("/login");
                            }, 2000);
                        });
                    })
                    .catch(function (error) {
                        console.error("err: " + error);
                        t.alertData = { title: "Account not found", message: "There was an error.", color: "success", timeout: 2000, show: true }
                    })
                    .catch((error) => {
                        // Handle any errors that might occur when setting alertData or calling $nextTick()
                        console.error("Error occurred while setting alert data or calling nextTick():", error);
                    });
            }
        },
        resetPassword: function () {
            let t = this;

            t.email = t.email.toLowerCase();

            t.rules = {
                email: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid", (v) => !/\s+/.test(v) || "E-mail must not contain spaces"],
            };

            t.$nextTick(() => {
                if (t.$refs.email.validate()) {
                    t.$firebase.auth
                        .sendPasswordResetEmail(t.email)
                        .then(function () {
                            t.showDone = true;
                            t.alertData = { title: "Account found", message: "A link to reset your password has been sent to your email address.", color: "success", timeout: 2000, show: true };
                        })
                        .catch(function (error) {
                            console.error("err: " + error);
                              t.alertData = { title: "Account not found", message: "There was an error.", color: "success", timeout: 2000, show: true }
                        });
                } else {
                    t.alertData = { title: "Account not found", message: "Fill out your email address correctly in order to proceed.", color: "warning", timeout: 2000, show: true }
                }
            });
        },
    },
};
</script>
