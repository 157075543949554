// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBlgJQj91Z4UdTtxc70sbhcjJQsT2KkHss",
	authDomain: "gsverde-ardeals-production.firebaseapp.com",
	projectId: "gsverde-ardeals-production",
	storageBucket: "gsverde-ardeals-production.appspot.com",
	messagingSenderId: "905459980151",
	appId: "1:905459980151:web:bfdf5c32d8c1149c36b372",
	measurementId: "G-NNS5EK6THX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export {
    auth,
    analytics
};