<template>
    <v-select
		multiple
        :label="label"
        :items="filteredItems"
        :error-messages="validateMessage"
        v-model="innerValue"
        :disabled="disabled"
        :filled="filled"
        :placeholder="placeholder"
        :rounded="rounded"
        class="rounded-of"
        :background-color="backgroundcolor"
        hide-details="auto"
        :menu-props="{ top: false, offsetY: true }"
        :attach="false"
        :height="height" 
        :dense="dense"               
        :clearable="clearable"
        @click:clear="clear()" />
</template>

<script>
export default {
    name: 'DropdownAdvanced',
    props: {
        rounded: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: true,
        },   
        value: {},             
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        items: {
            type: Array,
            default: () => [],
        },
		filled: {
			type: Boolean,
			default: true,
		},
        filter: {
            type: String,
            default: "",
        },
        returnobject: {
            type: Boolean,
            default: false,
        },
        backgroundcolor: {
            type: String,
            default: "grey lighten-4",
        },   
        itemtext: {
            type: String,
            default: "text",
        },
        itemvalue: {
            type: String,
            default: "value",
        },
        validate: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: "52",
        },        
        dense: {
            type: Boolean,
            default: true,
        }
    },
    data:() => ({
        innerValue: "",
        validateMessage: "",
    }),
    computed: {
        filteredItems() {
            if (this.filter !== "" && this.filterField !== "") {
                return this.items.filter((item) => item.filter === this.filter);
            } else {
                return this.items;
            }
        }
    },
    methods: {
        clear() {
            setTimeout(() => {
                this.innerValue = [];
            }, 1);
        },
    },    
    watch: {
        value: function (value) {
            this.innerValue = value;
        },        
        innerValue: function (value) {
            this.$emit("input", value);
            this.validateMessage = "";
        },
        validate: function (value) {
            this.validateMessage = value;
        },
    },
    mounted() {
        this.innerValue = this.value;
        this.validateMessage = this.error;
    },
};
</script>
