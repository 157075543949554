import { mapActions } from "vuex";

const mixin = {
	data: () => ({
		// API_URL: process.env.VUE_APP_MOFSERVER,
	}),
	methods: {
		...mapActions({
			ACT_alertBox: "ACT_alertBox",
		}),
		MIX_alertBox(payload) {
			this.ACT_alertBox(payload);
		},
		// * CHANGE ROUTE FUNCTION
		MIX_go(path) {
			this.$router.push(path).catch((err) => {
				console.error("Route error: " + err);
			});
		},
		MIX_generateId: function () {
			const characters = "O123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
			let id = "O";
			for (let i = 0; i < 25; i++) {
				const randomIndex = Math.floor(Math.random() * characters.length);
				id += characters.charAt(randomIndex);
			}
			return id;
		},
		// * CHANGE ROUTE FUNCTION
		MIX_goExternal(path) {
			window.open(path, "_blank").focus();
		},
		MIX_mailto(email) {
			window.location.href = "mailto:" + email;
		},
		MIX_call(phone) {
			window.location.href = "call:" + phone;
		},
		MIX_googleMaps(address) {
			window.open("https://www.google.com/maps/search/?api=1&query=" + address, "_blank").focus();
		},
		// SCROLL TO TOP
		MIX_scrollToTop() {
			window.scrollTo(0, 0);
		},
		// FORMAT DATE TIME NOW
		MIX_formatDateTimeNow: function (formatOut) {
			switch (formatOut) {
				case "toISOString":
					return this.$dayjs().toISOString();
				case "toJSON":
					return this.$dayjs().toJSON();
				case "toObject":
					return this.$dayjs().toObject();
				case "toArray":
					return this.$dayjs().toArray();
				case "unix":
					return this.$dayjs().unix();
				case "valueOf":
					return this.$dayjs().valueOf();
				default:
					return this.$dayjs().format(formatOut);
			}
		},
		// FORMAT DATE TIME
		MIX_formatDateTime: function (date, formatIn, formatOut) {
			if (date !== "" && date !== null && date !== undefined && date != 0) {
				if (formatIn === "ISOString") {
					formatIn = "YYYY-MM-DDTHH:mm:ss.SSSZ";
				}
				switch (formatOut) {
					case "toISOString":
						return this.$dayjs(date, formatIn).toISOString();
					case "toJSON":
						return this.$dayjs(date, formatIn).toJSON();
					case "toObject":
						return this.$dayjs(date, formatIn).toObject();
					case "toArray":
						return this.$dayjs(date, formatIn).toArray();
					case "unix":
						return this.$dayjs(date, formatIn).unix();
					case "valueOf":
						return this.$dayjs(date, formatIn).valueOf();
					default:
						return this.$dayjs(date, formatIn).format(formatOut);
				}
			} else {
				return "";
			}
		},
		// FORMAT DATE TIME FROM NOW
		MIX_formatDateTimeFromNow: function (date, formatIn, formatOut) {
			if (date !== "" && date !== null && date !== undefined && date != 0) {
				if (formatIn === "ISOString") {
					formatIn = "YYYY-MM-DDTHH:mm:ss.SSSZ";
				}
				let now = this.$dayjs();
				let newdate = this.$dayjs(date, formatIn);
				var difference;
				switch (formatOut) {
					case "Days":
						difference = now.diff(newdate, "days");
						if (difference === 0) {
							return "Today";
						} else if (difference === 1) {
							return "Yesterday";
						} else {
							return difference + " days ago";
						}
					default:
						difference = now.diff(newdate, "minutes");
						if (difference === 0) {
							return "Just now";
						} else if (difference < 60) {
							return difference + " minutes ago";
						} else if (difference > 60 && difference < 1440) {
							return Math.floor(difference / 60) + " hours ago";
						} else if (difference > 1440 && difference < 43200) {
							return Math.floor(difference / 1440) + " days ago";
						} else if (difference > 43200 && difference < 525600) {
							return Math.floor(difference / 43200) + " months ago";
						}
				}
			} else {
				return "";
			}
		},
		// FORMAT DATE TIME FROM NOW WITH ONLY THE DATA
		MIX_returnDateTimeFromNow: function (date, formatIn, formatOut) {
			if (date !== "" && date !== null && date !== undefined && date != 0) {
				if (formatIn === "ISOString") {
					formatIn = "YYYY-MM-DDTHH:mm:ss.SSSZ";
				}
				let now = this.$dayjs();
				let newdate = this.$dayjs(date, formatIn);
				var difference;
				switch (formatOut) {
					case "Days":
						difference = now.diff(newdate, "days");
						// if (difference === 0) {
						// 	return "Today";
						// } else if (difference === 1) {
						// 	return "Yesterday";
						// } else {
							return difference;
						// }
					default:
						difference = now.diff(newdate, "minutes");
						if (difference === 0) {
							return "Just now";
						} else if (difference < 60) {
							return difference + " minutes ago";
						} else if (difference > 60 && difference < 1440) {
							return Math.floor(difference / 60) + " hours ago";
						} else if (difference > 1440 && difference < 43200) {
							return Math.floor(difference / 1440) + " days ago";
						} else if (difference > 43200 && difference < 525600) {
							return Math.floor(difference / 43200) + " months ago";
						}
				}
			} else {
				return "";
			}
		},

		// ADD HOUR MINUTES SECONDS TO DATE TIME
		MIX_addTimeAndFormatDateTime: function (data, formatIn, formatOut, hours, minutes, seconds) {
			if (data !== "" && data !== null && data !== undefined && data != 0) {
				let date = this.$dayjs(data, formatIn);
				date = date.add(hours, "hour");
				date = date.add(minutes, "minute");
				date = date.add(seconds, "second");
				switch (formatOut) {
					case "toISOString":
						return this.$dayjs(date, formatIn).toISOString();
					case "toJSON":
						return this.$dayjs(date, formatIn).toJSON();
					case "toObject":
						return this.$dayjs(date, formatIn).toObject();
					case "toArray":
						return this.$dayjs(date, formatIn).toArray();
					case "unix":
						return this.$dayjs(date, formatIn).unix();
					case "valueOf":
						return this.$dayjs(date, formatIn).valueOf();
					default:
						return this.$dayjs(date, formatIn).format(formatOut);
				}
			} else {
				return "";
			}
		},
		// Format Address
		MIX_formatAddress: function (address) {
			if (JSON.stringify(address) !== "{}") {
				let fullAddress = "";
				if (address?.addressLine1) {
					fullAddress += address.addressLine1;
				}
				if (address?.addressLine2) {
					fullAddress += ", " + address.addressLine2;
				}
				if (address?.addressLine3) {
					fullAddress += ", " + address.addressLine3;
				}
				if (address?.town) {
					fullAddress += ", " + address.town;
				}
				if (address?.county) {
					fullAddress += ", " + address.county;
				}
				if (address?.postcode) {
					fullAddress += ", " + address.postcode;
				}
				return fullAddress;
			} else {
				return "";
			}
		},
		MIX_formatCurrency(amount, decimals) {
			const formatter = new Intl.NumberFormat("en-GB", {
				style: "currency",
				currency: "GBP",
				minimumFractionDigits: decimals,
			});

			return formatter.format(amount);
		},
		MIX_formatNumber(amount, decimals) {
			const formatter = new Intl.NumberFormat("en-GB", {
				minimumFractionDigits: decimals,
			});

			return formatter.format(amount);
		},		
		// Account Status
		MIX_accountStatus(status) {
			switch (status) {
				case "completed":
					return "success";
				case "approved":
					return "success";
				case "awaiting Approval":
					return "warning";
				case "rejected":
					return "error";
				case "accepted":
					return "success";
				case "pending":
					return "warning";
				default:
					return "grey";
			}
		},
		// Certificate Status
		MIX_certificateStatus(status) {
			switch (status) {
				case "Gold":
					return "gold";
				case "Silver":
					return "silver";
				case "Bronze":
					return "bronze";
				default:
					return "error";
			}
		},
		// // * FORMAT DATE TIME
		// MIX_formatDateTime: function (date, formatIn, formatOut) {
		// 	if (date !== "" && date !== null && date !== undefined && date != 0) {
		// 		return moment(date, formatIn).format(formatOut);
		// 	} else {
		// 		return moment().format(formatOut);
		// 	}
		// },
		// MIX_addFormatDateTime: function(data, formatIn, formatOut, timeValue, timeUnit) {
		// 	if (data !== "" && data !== null && data !== undefined && data != 0) {
		// 		return moment(data, formatIn).add(timeValue, timeUnit).format(formatOut);
		// 	} else {
		// 		return moment().add(timeValue, timeUnit).format(formatOut);
		// 	}
		// },
		// MIX_fromNow: function (date, formatIn, ago) {
		// 	return moment(date, formatIn).fromNow(ago);
		// },
	},
};

export default {
	install(Vue) {
		Vue.mixin(mixin);
	},
};
