<template>
    <v-app-bar clipped-left clipped-right app color="primary" dark class="elevation-0 foreground-high" height="60" >
        <div class="d-flex align-center justify-space-between full-width py-2" :class="$vuetify.breakpoint.width <= 600 ? '' : 'px-3'">
            <v-icon v-if="!$vuetify.breakpoint.width > 600" aria-label="Menu" :disabled="$vuetify.breakpoint.width > 600" color="white" :class="$vuetify.breakpoint.width <= 600 ? 'mx-4' : 'ml-5'" @click="updateShowDrawer()">icons8-menu</v-icon>
            <v-icon v-else aria-label="Menu" color="white" :class="$vuetify.breakpoint.width <= 600 ? 'mx-4' : 'ml-5'" @click="updateShowDrawer()">icons8-menu</v-icon>
            <!-- <v-spacer /> -->
            <div class="d-flex">
                <img src="@/assets/ardeals_white.png" width="150" height="26" alt="AR Deals" />
                <v-chip class="ml-3 mt-3 secondary primary--text font-weight-bold" x-small label>DEVELOPMENT</v-chip>
            </div>

            <!-- <LanguageSelector /> -->
            <!-- <v-spacer /> -->
            <!-- <v-icon aria-label="Menu" color="white" @click="updateShowDrawer()" v-if="$vuetify.breakpoint.xsOnly">icons8-customer</v-icon> -->
            <DropdownMenu  icon="icons8-customer" buttonclass="primary">
                <div v-if="GET_userAuthenticated" @click="showUserDetails++" class="grey--text text--darken-3 full-width mt-5">{{ GET_currentUserProfile.firstName }} {{ GET_currentUserProfile.lastName }}</div>
                <div v-if="GET_userAuthenticated" class="body-2 font-weight-bold grey--text text--darken-3 full-width">{{ GET_currentUserProfile.orgName }}</div>
                <div v-if="showUserDetails > 5">
                    <div v-if="GET_userAuthenticated" class="caption grey--text text--darken-3 full-width">Status: {{ GET_currentUserProfile.status }}</div>
                    <div v-if="GET_userAuthenticated" class="caption grey--text text--darken-3 full-width">OrgType: {{ GET_currentUserProfile.orgType }}</div>
                    <div v-if="GET_userAuthenticated" class="caption grey--text text--darken-3 full-width">Level: {{ GET_currentUserProfile.level }}</div>
                </div>
                <v-divider class="my-4" />
                <AppButton block @click.native="FIREBASE_logout()" buttonclass="secondary--text"><v-icon>icons8-open-door</v-icon><span class="ml-2">Log Out</span></AppButton>
                <div class="caption grey--text text--darken-3 full-width mt-2">Version {{ appVersion }}</div>
            </DropdownMenu>
        </div>
    </v-app-bar>
</template>

<script>
const version = require("@/../package.json").version;
import { mapGetters, mapActions } from "vuex";
// import LanguageSelector from "@/components/LanguageSelector.vue";
export default {
    name: "TopNav",
    data: () => ({
        showMenu: false,
        showUserDetails: 0,
    }),
    components: {
        // LanguageSelector,
    },
    computed: {
        ...mapGetters({
            GET_showDrawer: "GET_showDrawer",
            GET_currentUserProfile: "GET_currentUserProfile",
            GET_userAuthenticated: "GET_userAuthenticated",
        }),
        appVersion() {
            return version;
        },
    },
    methods: {
        ...mapActions({
            ACT_showDrawer: "ACT_showDrawer",
        }),
        updateShowDrawer() {
            // console.log("updateShowDrawer", this.GET_showDrawer)
            this.ACT_showDrawer(!this.GET_showDrawer);
        },
        changeLanguage(language) {
            this.$i18n.locale = language;
        },
    },
};
</script>
<style 
>

</style>