import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
	icons: {
		iconfont: 'mdiSvg' | 'icons8' || 'mdi' // || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
	},
	theme: {
		options: {
            customProperties: true,
        },
		themes: {    
            light: {
                primary: "#0A4652",
                secondary: "#7DB19C",
                accent: "#DF7F53", //"#DBE442",
				accent2: "#522B74",
                error: "#C62828",
                success: "#2E7D32",
                warning: "#EF6C00",
                darkgrey: "#828282",
                lightgrey: "#F2F2F2",
                background: "#E0E0E0",
                gold: "#FDC000",
                silver: "#C0C0C0",
                bronze: "#CD7F32",
            },
        },
    },
});
