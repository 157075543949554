<template>
    <div>
        <div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
        <v-row dense class="px-5 pt-5">
            <v-col cols="12" xs="12" md="8">
                <div class="d-flex align-end justify-space-evenly">
                    <div class="text-h4 primary--text d-flex align-end" style="width: 100%">
                        Lookups
                        <v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
                        <v-spacer />
                        <!-- <AppButton buttonclass="secondary" @click.native="addLookup">Add Lookup</AppButton> -->
                    </div>
                </div>
                <div class="text--secondary pt-4" v-if="showInfo">The following page shows all users that have access to the App/Portal, you can use this page to Manage users and access.</div>
            </v-col>
            <!-- <v-col cols="12" v-if="JSON.stringify(Lookups) !== '[]'" class="body-2"> Found {{ itemsTotal }} vet(s) </v-col> -->
            <!-- Table -->
            <v-col cols="12" xs="12" sm="12" md="8" lg="8" class="pt-2">
                <BasicCard>
                    <div class="pa-5">
                        <div class="d-flex align-center" :class="$vuetify.breakpoint.width <= 600 ? 'd-flex flex-column mr-4' : ''">
                            <div class="text-h5 secondary--text" :class="$vuetify.breakpoint.width <= 600 ? 'px-4' : 'ml-6'" :style="$vuetify.breakpoint.width <= 600 ? 'width:100%;' : ''">
                                Lookup
                                <v-switch v-model="showDeleted" label="Show Deleted">Show Deleted</v-switch>
                            </div>
                            <!-- <v-spacer /> -->
                            <DropdownSimple backgroundcolor="grey lighten-3" v-model="lookupSelected" :items="filteredLookupTypes" :filled="false" label="Select Lookup" :dense="true" />
                        </div>

                        <v-col cols="12" class="" style="overflow-y: scroll">
                            <table width="100%" class="body-2" v-if="lookupSelected !== ''" border="0">
                                <tr height="28">
                                    <td colspan="4"><v-divider /></td>
                                </tr>

                                <tr>
                                    <td class="pl-6 font-weight-bold primary--text" colspan="4">
                                        <span v-if="lookupNew">New Lookup</span>
                                        <span v-if="!lookupNew">Edit Lookup</span>
                                    </td>
                                </tr>
                                <tr height="50">
                                    <td><TextField v-model="lookup.text" :filled="false" backgroundcolor="grey lighten-4" label="Text" :clearable="false" :validate="validate.text" required/></td>
                                    <td><TextField v-model="lookup.value" :filled="false" backgroundcolor="grey lighten-4" label="Value" :clearable="false" :validate="validate.value" :persistentHint="'false'" required :hint="'Lowercase characters only. Use underscores instead of'"/></td>
                                    <td><TextField v-model="lookup.filter" :filled="false" backgroundcolor="grey lighten-4" label="Filter" :clearable="false" /></td>
                                    <td><TextField v-model="lookup.type" :filled="false" backgroundcolor="grey lighten-4" label="Type" :clearable="false" :validate="validate.type" required/></td>
                                    <td align="right" width="100">
                                        <v-btn title="Cancel Lookup" icon @click="cancelLookup()"><v-icon>icons8-stop-squared</v-icon></v-btn>
                                        <v-btn title="Save Lookup" icon @click="saveLookup()"><v-icon>icons8-checked-checkbox</v-icon></v-btn>
                                    </td>
                                </tr>
                                <div v-if="errorList == []" v-html="errorList" class="red--text"></div>
                                <tr></tr>
                                <tr height="20">
                                    <td colspan="4"><v-divider /></td>
                                </tr>
                                <tr class="primary--text">
                                    <th align="left" :class="$vuetify.breakpoint.width <= 500 ? 'pa-2' : 'pa-2 pl-6'">Text</th>
                                    <th align="left" :class="$vuetify.breakpoint.width <= 500 ? 'pa-2' : 'pa-2 pl-6'">Value</th>
                                    <th align="left" :class="$vuetify.breakpoint.width <= 500 ? 'pa-2' : 'pa-2 pl-6'">Filter</th>
                                    <th align="left" :class="$vuetify.breakpoint.width <= 500 ? 'pa-2' : 'pa-2 pl-6'"></th>
                                    <th align="right" :class="$vuetify.breakpoint.width <= 500 ? 'pa-2' : 'pa-2 pl-6'">Action</th>
                                </tr>
                                <tr v-for="item in filteredLookups" :key="item.entityId">
                                    <td :class="$vuetify.breakpoint.width <= 500 ? '' : 'pa-2 pl-6'"><v-icon x-small v-if="item.deleted" class="error--text pr-2">icons8-trash</v-icon>{{ item.text }}</td>
                                    <td :class="$vuetify.breakpoint.width <= 500 ? '' : 'pa-2 pl-6'">{{ item.value }}</td>
                                    <td :class="$vuetify.breakpoint.width <= 500 ? '' : 'pa-2 pl-6'">{{ item.filter }}</td>
                                    <td :class="$vuetify.breakpoint.width <= 500 ? '' : 'pa-2 pl-6'"></td>
                                    <td align="right" width="100">
                                        <v-btn title="Edit" icon @click="editLookup(item)"><v-icon>icons8-menu-squared-2</v-icon></v-btn>
                                        <v-btn v-if="!item.deleted" title="Delete" icon @click="deleteLookup(item)"><v-icon>icons8-close-window</v-icon></v-btn>
                                        <v-btn v-if="item.deleted" title="Restore" icon @click="restoreLookup(item)"><v-icon>icons8-checked-checkbox</v-icon></v-btn>
                                    </td>
                                </tr>
                            </table>
                        </v-col>
                    </div>
                </BasicCard>
            </v-col>
            <!-- Confirmation Box -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirm you want to delete this lookup</p>
                <strong>{{ lookup.text }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeleteLookup">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
    </div>
</template>

<script>
// import BasicCard from "@/components/cards/BasicCard.vue";
import { mapGetters } from "vuex";
export default {
    name: "LookupAdmin",
    // components: { BasicCard },
    // data
    data: () => ({
        loading: false,
        drawer: false,
        lookup: {},
        lookupNew: true,
        lookupSelected: "",
        lookups: [],
        filterDrawer: false,
        confirmBox: false,
        showDeleted: false,
        permenantDelete: false,
        itemsTotal: 0,

        // Validation Data Object
        validate: {},
        errorList: [],

        user: {},
        organisations: [],
        orgTypes: [],
        userStatus: [],
        userGroups: [],
        userLevels: [],
        userLanguages: [],
        action: "Add",
        showInfo: false,
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
        filteredHeaders() {
            let headers = this.tableHeaders.filter((header) => !header.hidden);
            if (this.drawer) {
                headers = headers.filter((header) => header.shrunk);
            }
            return headers;
        },
        filterQuery() {
            let query = "";
            if (this.showDeleted) {
                query = "*";
            } else {
                query = "@deleted:{false}";
            }
            return query;
        },
        itemsFrom() {
            return 0; // (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
        },
        itemsTo() {
            return 9999;
            // if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
            // 	return this.itemsTotal;
            // } else {
            // 	return this.tableOptions.page * this.tableOptions.itemsPerPage;
            // }
        },
        itemsCount() {
            return 9999;
        },
        itemsSortBy() {
            return "text";
        },
        itemsSortDesc() {
            return "ASC";
        },
        filteredLookups() {
            // filter by lookupSelected
            if (this.lookupSelected) {
                return this.lookups.filter((item) => item.type === this.lookupSelected);
            } else {
                return [];
            }
        },
        filteredLookupTypes() {
            // return unique list of lookup types
            return [...new Set(this.lookups.map((item) => item.type))];
        },
    },
    // watch
    watch: {
        filterQuery: {
            handler() {
                this.getLookups();
            },
            deep: true,
        },
    },
    // methods
    methods: {
        // Get Table Data
        async getLookups() {
            try {
                this.loading = true;
                // console.log("this.itemsFrom", this.itemsFrom);
                // console.log("this.itemsCount", this.itemsCount);
                // console.log("this.itemsSortBy", this.itemsSortBy);
                // console.log("this.itemsSortDesc", this.itemsSortDesc);
                // console.log("this.filterQuery", this.filterQuery);
                let dataResult = await this.REDIS_searchFor("lookup", this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery);
                this.lookups = dataResult.data.documents;
                // console.log("this.lookups", JSON.stringify(this.lookups, null, 2));
                this.itemsTotal = dataResult.data.total;
                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },

        // Add new item to lookup
        async saveLookup() {
            // console.log("this.lookup 1", JSON.stringify(this.lookup, null, 2));
            try {
                // validation
                let validationErrors = 0;
                if (this.lookup.text.trim() === "") {
                    this.validate.text = "Text is required";
                    validationErrors++;
                }
                if (this.lookup.value.trim() === "") {
                    this.validate.value = "Value is required";
                    validationErrors++;
                } else if (!/^[a-z0-9!@#$%^&*()_+{}[\]:;<>,.?~-]+$/.test(this.lookup.value)) {
                    this.validate.value = "Value mustn't contain uppercase letters or spaces.";
                    validationErrors++;
                }
                if (this.lookup.type.trim() === "") {
                    this.validate.type = "Type is required";
                    validationErrors++;
                }

                // If no validation errors
                if (validationErrors === 0) {
                    // console.log("this.lookup 2", JSON.stringify(this.lookup, null, 2));
                    if (this.lookup?.entityId === "" || this.lookup?.entityId === undefined || this.lookup?.entityId === null) {
                        await this.REDIS_create("lookup", this.lookup);
                    } else {
                        await this.REDIS_update("lookup", this.lookup.entityId, this.lookup);
                    }
                    this.getLookups();
                    this.lookup = { ...this.$schema.lookup };
                    this.MIX_alertBox({ show: true, message: "Lookup Saved", color: "success", timeout: "2000" });
                } else {
                    // runs if there are validation errors
                    this.errorList = "<ul>";

                    if (this.validate.text) {
                        this.errorList += "<li>" + this.validate.text + "</li>";
                    }
                    if (this.validate.value) {
                        this.errorList += "<li>" + this.validate.value + "</li>";
                    }
                    if (this.validate.type) {
                        this.errorList += "<li>" + this.validate.type + "</li>";
                    }

                    this.errorList += "</ul>";

                    // banner
                    let errorMessage = "Error Adding Lookup.";
                    this.MIX_alertBox({ show: true, message: errorMessage, color: "error", timeout: "4000" });
                }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Adding Lookup", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        // Edit loookup
        editLookup(lookup) {
            this.lookup = { ...lookup };
            this.lookupNew = false;
        },
        // Delete lookup
        deleteLookup(lookup) {
            this.lookup = lookup;
            this.confirmBox = true;
        },
        // Cancel Lookup
        cancelLookup() {
            this.lookup = { ...this.$schema.lookup };
            this.lookupNew = true;
        },
        // Restore lookup
        async restoreLookup(lookup) {
            try {
                this.loading = true;
                this.lookup = lookup;
                this.lookup.deleted = false;
                await this.REDIS_update("lookup", this.lookup.entityId, this.lookup);
                this.loading = false;
                this.MIX_alertBox({ show: true, message: "Lookup Restored", color: "success", timeout: "2000" });
                this.getLookups();
                this.lookup = { ...this.$schema.lookup };
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Restoring", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        // Confirm Delete lookup
        async confirmDeleteLookup() {
            try {
                this.loading = true;
                this.lookup.deleted = true;
                // console.log('this.lookup', JSON.stringify(this.lookup, null, 2));
                await this.REDIS_delete("lookup", this.lookup.entityId, this.lookup);
                this.loading = false;
                this.confirmBox = false;
                this.permenantDelete = false;
                this.MIX_alertBox({ show: true, message: "Lookup Deleted", color: "success", timeout: "2000" });
                this.getLookups();
                this.lookup = { ...this.$schema.lookup };
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Deleting Lookup", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        // Initialise
        async initialise() {
            this.loading = true;
            this.lookup = { ...this.$schema.lookup };
            this.getLookups();
            this.loading = false;
        },
    },
    created() {
        this.initialise();
    },
};
</script>

<style scoped>
.d-flex {
    gap: 15px;
}
</style>
