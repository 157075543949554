<template>
    <div class="pb-10">
        <div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
        <v-row dense class="pt-5 px-5">
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="">
                <BasicCard class="grey lighten-3">
                    <v-row class="mr-2" dense>
                        <v-col>
                            <img src="@/assets/gsverde_colour.png" height="100" alt="AR Deals" />
                        </v-col>
                        <v-col cols="4" xs="2" sm="2" md="2" lg="2">
                            <DashCard :loading="loading" class="white" :valueclass="this.$vuetify.breakpoint.width < 900 ? `grey--text font-weight-bold text-h6` : `text-h5 grey--text font-weight-bold`" textclass="primary--text caption font-weight-bold">
                                <template v-slot:value>{{ MIX_formatNumber(sellBusinessCount) }}</template>
                                <template v-slot:text>Sell Business</template>
                            </DashCard>
                        </v-col>
                        <v-col cols="4" xs="2" sm="2" md="2" lg="2">
                            <DashCard :loading="loading" class="white" :valueclass="this.$vuetify.breakpoint.width < 900 ? `grey--text font-weight-bold text-h5` : `text-h5 grey--text font-weight-bold`" textclass="primary--text caption font-weight-bold">
                                <template v-slot:value>{{ MIX_formatNumber(buyBusinessCount) }}</template>
                                <template v-slot:text>Buy Business</template>
                            </DashCard>
                        </v-col>
                        <v-col cols="4" xs="2" sm="2" md="2" lg="2">
                            <DashCard :loading="loading" class="white" :valueclass="this.$vuetify.breakpoint.width < 900 ? `grey--text font-weight-bold text-h5` : `text-h5 grey--text font-weight-bold`" textclass="primary--text caption font-weight-bold">
                                <template v-slot:value>{{ MIX_formatNumber(clientCount) }}</template>
                                <template v-slot:text>Clients</template>
                            </DashCard>
                        </v-col>
                        <v-col cols="4" xs="2" sm="2" md="2" lg="2">
                            <DashCard :loading="loading" class="white" :valueclass="this.$vuetify.breakpoint.width < 900 ? `grey--text font-weight-bold text-h5` : `text-h5 grey--text font-weight-bold`" textclass="primary--text caption font-weight-bold">
                                <template v-slot:value>{{ MIX_formatNumber(matchCount) }}</template>
                                <template v-slot:text>Matches</template>
                            </DashCard>
                        </v-col>
                    </v-row>

                    <div class="px-5 mt-5 pb-5"><strong>Welcome to AR:Deals</strong>, the cutting-edge dealmaking platform by GS Verde. The platform streamlines the process of identifying and facilitating strategic matches between buyers and sellers, while providing real-time information and dashboards to expedite deal success.</div>
                </BasicCard>
                <div :class="$vuetify.breakpoint.xsOnly ? 'd-flex flex-column' : 'flex-cards-leftSide'" >
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" :class="$vuetify.breakpoint.xsOnly ? 'px-0' : 'pl-0'">
                        <BasicCard style="height: 100% !important; cursor: pointer" @click.native="MIX_go('/business')">
                            <div class="pa-5 d-flex align-start">
                                <div><v-icon class="text-h3">icons8-marketplace</v-icon></div>
                                <div class="ml-3">
                                    <div class="text-h6 font-weight-light primary--text">Marketplace</div>
                                    <div class="text-body-2">The Marketplace is where you can discover a wide range of business opportunities. It encompasses both businesses available for sale (Sell) and opportunities actively seeking acquisition (Buy).</div>
                                </div>
                            </div>
                        </BasicCard>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="6" lg="6" :class="$vuetify.breakpoint.xsOnly ? 'px-0' : 'pl-0'">
                        <BasicCard style="height: 100% !important; cursor: pointer" @click.native="MIX_go('/clients')">
                            <div class="pa-5 d-flex align-start">
                                <div><v-icon class="text-h3">icons8-business-building</v-icon></div>
                                <div class="ml-3">
                                    <div class="text-h6 font-weight-light primary--text">Clients</div>
                                    <div class="text-body-2">The clients section provides a platform to efficiently manage clients and their corresponding match profiles. It also serves as a hub for exploring potential business matches available in the marketplace.</div>
                                </div>
                            </div>
                        </BasicCard>
                    </v-col>
                </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6" lg="6" class="py-0" no-gutters>
                <v-col cols="12" xs="6" sm="10" md="10" lg="10" :class="$vuetify.breakpoint.mdAndUp ?' mx-auto pa-0' : 'pa-0'" no-gutters>
                    <div class="text-h6 primary--text py-0"><strong>Latest Businesses Added</strong></div>
                </v-col>
                <div class="" v-for="item in businessData" :key="item.entityId">
                    <v-col cols="12" xs="6" sm="12" md="10" lg="10" class="mx-auto px-0">
                        <BasicCard footerclass="grey lighten-4">
                            <template v-slot:header>
                                <div class="full-width d-flex align-start px-4 pt-2" style="height: 60px !important; overflow: hidden !important">
                                    <div class="text-body-1 font-weight-bold primary--text text-left" style="cursor: pointer">
                                        {{ item.title.substring(0, 80) }}
                                    </div>
                                    <v-spacer />
                                </div>
                            </template>
                            <div class="mt-2 mb-1 grey lighten-3" style="height: 3px !important"></div>
                            <div class="px-4 text-body-1 grey--text text--darken-4 pb-5 mt-3" style="cursor: pointer">
                                <div class="text-body-2 d-flex font-weight-bold">
                                    <div><v-icon class="grey--text pr-1" small>icons8-tear-off-calendar</v-icon>{{ MIX_formatDateTime(item.listingDateTime, "ISOString", "D MMM YYYY") }}</div>
                                    <v-spacer />
                                    <div class="success--text" v-if="MIX_formatDateTimeFromNow(item.lastSeenDateTime, 'ISOString', 'Days') === 'Today'"><v-icon class="success--text pr-1" small>icons8-important-time</v-icon>{{ MIX_formatDateTimeFromNow(item.lastSeenDateTime, "ISOString", "Days") }}</div>
                                    <div class="success--text" v-else><v-icon class="success--text pr-1" small>icons8-clock-checked</v-icon>{{ MIX_formatDateTimeFromNow(item.lastSeenDateTime, "ISOString", "Days") }}</div>
                                </div>
                                <div class="mt-2 d-flex align-center">
                                    <div class="text-body-2 font-italic">{{ item.sourceName }}</div>
                                    <v-spacer />
                                </div>
                                <div class="mt-1">{{ item.summary.substring(0, 100) }}...</div>
                            </div>
                            <template v-slot:footer>
                                <div class="full-width px-4 py-2 d-flex align-center" style="overflow: hidden !important">
                                    <div class="flex-grow-1 d-flex align-center">
                                        <v-icon class="grey--text text--darken-1 pr-2">icons8-sales-balance</v-icon>
                                        <span v-if="item.turnover !== 0">{{ MIX_formatCurrency(item?.turnover, 0) }}</span>
                                        <span v-else>-</span>
                                    </div>
                                    <div class="flex-grow-1 d-flex align-center">
                                        <v-icon class="grey--text text--darken-1 pr-2">icons8-price-tag-pound</v-icon>
                                        <span v-if="item.price !== 0">{{ MIX_formatCurrency(item?.price, 0) }}</span>
                                        <span v-else>-</span>
                                    </div>

                                    <div class="flex-grow-1 d-flex align-center">
                                        <v-icon class="grey--text text--darken-1 pr-2">icons8-total-sales</v-icon>
                                        <span v-if="item.ebitda !== 0">{{ MIX_formatCurrency(item?.ebitda, 0) }}</span>
                                        <span v-else>-</span>
                                    </div>
                                </div>
                            </template>
                        </BasicCard>
                    </v-col>
                </div>
            </v-col>
        </v-row>
        <!-- <v-row class="px-5">
            <v-col cols="12" xs="12" sm="5" md="4" lg="3" v-if="['director', 'manager'].includes(currentUser.level)">
                <BasicCard style="height: 100% !important; cursor: pointer;" @click.native="MIX_go('/users')">
                    <div class="pa-5 d-flex align-start">
                        <div><v-icon class="text-h3">icons8-staff</v-icon></div>
                        <div class="ml-3">
                            <div class="text-h6 font-weight-light primary--text">Users</div>
                            <div class="text-body-2">In the Users section of AR:Deals, you can easily manage and approve users. This feature allows you to efficiently handle user accounts, granting access to the platform.</div>
                        </div>
                    </div>
                </BasicCard>
            </v-col>
            <v-col cols="12" xs="12" sm="5" md="4" lg="3" v-if="['director', 'manager'].includes(currentUser.level)">
                <BasicCard style="height: 100% !important; cursor: pointer;" @click.native="MIX_go('/sources')">
                    <div class="pa-5 d-flex align-start">
                        <div><v-icon class="text-h3">icons8-database</v-icon></div>
                        <div class="ml-3">
                            <div class="text-h6 font-weight-light primary--text">Sources</div>
                            <div class="text-body-2">The Sources section in AR:Deals provides an administrative function that enables users to monitor the status of each source and view the last feed obtained.</div>
                        </div>
                    </div>
                </BasicCard>
            </v-col>
        </v-row> -->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "HomeView",
    // data
    data: () => ({
        loading: true,
        releasenote: 0,
        currentUser: {},
        sellBusinessCount: 0,
        buyBusinessCount: 0,
        userCount: 0,
        clientCount: 0,
        matchCount: 0,
        businessData: [],
    }),

    // * COMPUTED
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
    },

    // * METHODS
    methods: {
        // find latest businesses
        async getData() {
            let filterQuery = `@deleted:{false} @status:{live}`;
            let dataResult = await this.REDIS_searchFor("business", 0, 4, "createdDateTime", "desc", filterQuery);
            // console.log('dataResult: ', JSON.stringify(dataResult, null, 2));
            this.businessData = dataResult.data.documents;
        },
        // get current user
        getCurrentUser() {
            this.currentUser = this.GET_currentUserProfile;
            return this.currentUser;
        },
        async intialise() {
            this.loading = true;
            let businessSellCount = await this.REDIS_countFor("business", null, null, null, null, `@deleted:{false}`);
            this.sellBusinessCount = businessSellCount.total;
            let businessBuyCount = await this.REDIS_countFor("business", null, null, null, null, `@deleted:{false} @type:{Buy}`);
            this.buyBusinessCount = businessBuyCount.total;
            let userCount = await this.REDIS_countFor("user", null, null, null, null, `@deleted:{false}`);
            this.userCount = userCount.total;
            let clientCount = await this.REDIS_countFor("org", null, null, null, null, `@deleted:{false}`);
            this.clientCount = clientCount.total;
            let matchCount = await this.REDIS_countFor("match", null, null, null, null, `@deleted:{false}`);
            this.matchCount = matchCount.total;
            await this.getData();
            this.loading = false;
        },
    },

    // * CREATED
    created() {
        this.getCurrentUser();
        this.intialise();
    },
};
</script>

<style scoped>
.flex-cards-leftSide {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
</style>
