<template>
	<!-- <div>
		dateMenu: {{ dateMenu }}<br />
		date1: {{ date1 }}<br />
		computedDate: {{ computedDate }}<br />
		innerValue: {{ innerValue }}<br />
		validateMessage: {{ validateMessage }}<br />
		<br /> -->
        <div>

		<v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
			<template v-slot:activator="{ on, attrs }">
				<v-text-field :error-messages="validateMessage" :disabled="disabled" dense filled rounded v-model="computedDate" class="rounded-of" :background-color="backgroundcolor" :label="label" hide-details="auto" append-icon="icons8-timeline-week" readonly v-bind="attrs" v-on="on" :clearable="clearable" @click:clear="clearDate"> </v-text-field>
				<!-- v-model="innerValue" -->
			</template>
			<v-date-picker v-model="date1" no-title @input="dateMenu = false"></v-date-picker>
		</v-menu>
	</div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
		backgroundcolor: {
			type: String,
			default: "grey lighten-4",
		},
        disabled: {
            type: Boolean,
            default: false,
        },
        initialdate: {
            type: String,
            default: "",
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        validate: {
            type: String,
            default: "",
        }
    },
    data: ()=> ({
        innerValue: "",
        validateMessage: "",
        dateMenu: false,
        date1: ""//vm.MIX_formatDateTimeNow("YYYY-MM-DD")
    }),
    computed: {
        // Computed Date
        computedDate() {
            return this.MIX_formatDateTime(this.date1, "YYYY-MM-DD", "DD-MMM-YY");
        },
    },
    watch: {
        value: function (value) {
            this.innerValue = value;
            this.date1 = value;
            this.validateMessage = "";
        },
        initialdate: function (value) {
            this.date1 = value;
        },        
        validate: function (value) {
            this.validateMessage = value;
        },
		date1: function (value) {
			// this.innerValue = this.MIX_formatDateTime(value, "YYYY-MM-DD", "toISOString");
			this.innerValue = value;
		},
        // Update Parent v-model
        innerValue: function (value) {
            this.$emit("input", value);
        },
    },
    methods: {
        clearDate() {
            setTimeout(() => {
                this.innerValue = "";
                this.dateMenu = false;
            }, 1);
        },
    },
    mounted() {
        this.innerValue = this.value;
        this.date1 = this.initialdate;
    },
};
</script>
