<template>
    <div>
        <div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
        <div v-if="filterDrawer" class="leftside-overlay" @click="(filterDrawer = false)"></div>
        <v-row dense class="px-5 pt-5">
            <v-col cols="12">
                <div class="d-flex align-center" style="gap: 8px">
                    <div>
                        <div class="text-h4 primary--text d-flex">Logs</div>
                    </div>
                    <v-spacer />
                    <!-- Search -->
                    <SearchField v-if="$vuetify.breakpoint.width >= 600" label="Search Logs" v-model="search" :dense="true" />
                    <AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>
                    <AppButton @click.native="getTableData"><v-icon>icons8-synchronize</v-icon></AppButton>
                </div>
            </v-col>
            <!-- Table -->
            <v-col cols="12" class="pt-2">
                <!-- Data Table -->
                <DataTable :tableheaders="filteredHeaders" :actions="['Edit']" :loading="loading" custom="logs" :tabledata="tableData" :datatableoptions="tableOptions" :itemstotal="itemsTotal" v-on:tableoptions="updateTableOptions" :lookups="lookups" />
            </v-col>
        </v-row>
        <!-- Filter Drawer -->
        <v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="filterDrawer" absolute clipped right style="z-index: 999">
            <v-row>
                <v-col cols="12">
                    <div class="d-flex align-center pt-4">
                        <div class="text-h5 secondary--text">Filters</div>
                        <v-spacer />
                        <v-btn icon depressed @click="filterDrawer = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                    Use the filters below to narrow your search.
                </v-col>
                <v-col cols="12">
                    <!-- Status -->
                    <div Level="flex-grow-1">
                        <SearchField v-if="$vuetify.breakpoint.width < 600" label="Search Logs" v-model="search" :dense="true" />
                        <div class="my-2 text-h6 primary--text">Log Level</div>
                        <div class="d-flex align-center pb-4">
                            <!-- Log Level -->
                            <DropdownMultiple :items="logLevel" v-model="filter.logLevel" label="Log Level" />
                        </div>
                    </div>
                    <!-- Severity -->
                    <div class="flex-grow-1">
                        <div class="my-2 text-h6 primary--text">Log Severity</div>
                        <div class="d-flex align-center pb-4">
                            <!-- Log Severity -->
                            <DropdownMultiple :items="logSeverity" v-model="filter.logSeverity" label="Log Severity" />
                        </div>
                    </div>
                    <!-- Context -->
                    <!-- <div class="flex-grow-1">
                            <div class="my-2 text-h6 primary--text">Log Context</div>
                            <div class="d-flex align-center pb-4"> -->
                    <!-- Log Context -->
                    <!-- <DropdownSimple :items="logContext" v-model="filter.logContext" label="Log Context" />
                            </div>
                        </div> -->
                    <!-- Start Date -->
                    <div class="flex-grow-1">
                        <div class="my-2 text-h6 primary--text">Start Date</div>
                        <div class="d-flex align-center pb-4">
                            <DatePicker label="Start Date"  style="width:100%;" v-model="filter.startDate" @input="datePickerOutput" />
                        </div>
                    </div>
                    <!-- End Date -->
                    <div class="flex-grow-1">
                        <div class="my-2 text-h6 primary--text">End Date</div>
                        <div class="d-flex align-center pb-4">
                            <DatePicker label="End Date" style="width:100%;" v-model="filter.end3Date" @input="datePickerOutput" />
                        </div>
                    </div>
                     <div class="mt-5 red--text" v-if="isEndDateInvalid">End Date cannot be before Start Date</div>
                </v-col>
            </v-row>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "LogsAdmin",
    data: () => ({
        //loading
        loading: false,

        // search filters
        search: "",
        filter: {
            logLevel: [],
            logContext: "",
            logSeverity: [],
            startDate: "",
            endDate: "",
        },
        filterDrawer: false,
        endDateInvalid: false,

        // lookups
        logLevel: [],
        logSeverity: [],
        logContext: [],
        lookups: [],

        // table
        tableData: [],
        itemsTotal: 0,
        tableHeaders: [
            { text: "Level", value: "logLevel", align: "start", hidden: false, sortable: false, shrunk: true },
            { text: "Severity", value: "severity", align: "center", hidden: false, sortable: false, shrunk: true },
            { text: "Message", value: "message", align: "start", hidden: false, sortable: false, shrunk: true },
            { text: "Context", value: "context", align: "start", hidden: false, sortable: false, shrunk: true },
            { text: "Error", value: "error", align: "start", hidden: false, sortable: false, shrunk: false },
            { text: "Stack", value: "stack", align: "start", hidden: false, sortable: false, shrunk: false },

            { text: "Date", value: "createdDateTime", align: "start", hidden: false, sortable: false, shrunk: true },
            { text: "modifiedDateTime", value: "modifiedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "sourceId", value: "sourceId", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "value", value: "value", align: "start", hidden: true, sortable: false, shrunk: false },
        ],
        tableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["createdDateTime"],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
    }),
    // * COMPUTED
    // computed
    computed: {
        
        isEndDateInvalid() {
            return this.filter.startDate && this.filter.endDate && new Date(this.filter.endDate) < new Date(this.filter.startDate);
        },
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
        filteredHeaders() {
            let headers = this.tableHeaders.filter((header) => !header.hidden);
            if (this.filterDrawer) {
                headers = headers.filter((header) => header.shrunk);
            }
            return headers;
        },
        filterQuery() {
            let query = "";
            if (this.search !== "" && this.search !== null && this.search !== undefined && this.search.length > 1) {
                query += `${this.search}*`;
            }
            // Filter Log Level
            if (JSON.stringify(this.filter.logLevel) !== "[]") {
                query += ` @level:{${this.filter.logLevel.join("|")}}`;
            }
            // Filter Log severity
            if (JSON.stringify(this.filter.logSeverity) !== "[]") {
                query += ` @severity:{${this.filter.logSeverity.join("|")}}`;
            }
            // Filter Log Context
            if (this.filter.logContext !== "") {
                query += ` @context:{${this.filter.logContext}}`;
            }
            // Filter Start Date
            if (this.filter.startDate !== "") {
                if (this.filter.endDate !== "") {
                    let startDate = this.MIX_formatDateTime(this.filter.startDate, "YYYY-MM-DD", "unix");
                    let endDate = this.MIX_formatDateTime(this.filter.endDate, "YYYY-MM-DD", "unix");
                    query += ` @createdDateTime:[${startDate} ${endDate}]`;
                } else {
                    let currentDate = this.MIX_formatDateTimeNow("unix");
                    let startDate = this.MIX_formatDateTime(this.filter.startDate, "YYYY-MM-DD", "unix");
                    query += ` @createdDateTime:[${startDate} ${currentDate}]`;
                }
            }
            // Filter End Date
            if (this.filter.endDate !== "" ) {
                if (this.filter.startDate !== "" && new Date(this.filter.endDate) < new Date(this.filter.startDate)) {
                    let startDate = this.MIX_formatDateTime(this.filter.startDate, "YYYY-MM-DD", "unix");
                    let endDate = this.MIX_formatDateTime(this.filter.endDate, "YYYY-MM-DD", "unix");
                    query += ` @createdDateTime:[${startDate} ${endDate}]`;
                } else {
                    let endDate = this.MIX_formatDateTime(this.filter.endDate, "YYYY-MM-DD", "unix");
                    query += ` @createdDateTime:[0 ${endDate}]`;
                }
            }
            // If all filters are empty, return all
            if (query === "" || query === null || query === undefined) {
                query += "*";
            }
            return query;
        },
        itemsFrom() {
            return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
        },
        itemsTo() {
            if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
                return this.itemsTotal;
            } else {
                return this.tableOptions.page * this.tableOptions.itemsPerPage;
            }
        },
        itemsCount() {
            return this.tableOptions.itemsPerPage;
        },
        itemsSortBy() {
            let sortBy = this.tableOptions.sortBy;
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => x);
                sortBy = sortBy.join(",");
            }
            return sortBy;
        },
        itemsSortDesc() {
            let sortDesc = this.tableOptions.sortDesc[0];
            if (sortDesc) {
                sortDesc = "DESC";
            } else {
                sortDesc = "ASC";
            }
            return sortDesc;
        },
    },
    // watch
    watch: {
        filterQuery: {
            handler() {
                this.tableOptions.page = 1;
                this.getTableData();
            },
            deep: true,
        },
        tableOptions: {
            handler() {
                this.getTableData();
            },
            deep: true,
        },
    },
    // * METHODS
    methods: {
        datePickerOutput(date) {
            console.log("date", date);
        },
        // Next page of the table
        next() {
            if (this.tableOptions.page * this.tableOptions.itemsPerPage < this.itemsTotal) {
                this.tableOptions.page++;
            }
        },
        // Previous page of the table
        previous() {
            if (this.tableOptions.page > 1) {
                this.tableOptions.page--;
            }
        },
        // Update Table Options
        updateTableOptions(options) {
            this.tableOptions = options;
            // console.log("tableOptions = " + JSON.stringify(this.tableOptions));
        },
        //
        async initialise() {
            this.loading = true;
            let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{log_level|log_severity|log_context}");
            this.lookups = lookupResult.data.documents;
            this.logLevel = this.lookups.filter((item) => item.type === "log_level");
            this.logSeverity = this.lookups.filter((item) => item.type === "log_severity");
            this.logContext = this.lookups.filter((item) => item.type === "log_context");
            this.getTableData();
            this.loading = false;
        },

        // Get Table Data
        async getTableData() {
            try {
                this.loading = true;
                // console.log("filterQuery = " + this.filterQuery);
                // console.log("itemsFrom = " + this.itemsFrom);
                // console.log("itemsCount = " + this.itemsCount);
                // console.log("itemsSortBy = " + this.itemsSortBy);
                // console.log("itemsSortDesc = " + this.itemsSortDesc);

                let dataResult = await this.REDIS_searchFor("log", this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery);
                this.tableData = dataResult.data.documents;
                // console.log("tableData" + JSON.stringify(this.tableData, null, 2));
                this.itemsTotal = dataResult.data.total;
                // console.log("itemsTotal = " + this.itemsTotal);
                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },

        // Closes and opens the filter drawer
        toggleFilters() {
            this.filterDrawer = !this.filterDrawer;
        },
    },
    // * CREATED
    created() {
        this.initialise();
    },
};
</script>

<style scoped>
.leftside-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness */
    z-index: 99; /* Specify a stack order in case you're using a different order for other elements */
}
</style>
