<template>
    <div class="">
        <div style="height: 2px !important; margin-bottom: 14px"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
        <!-- Title -->
        <div class="px-5 mt-5 d-flex align-center">
            <div>
                <div class="text-h4 primary--text d-flex ">Admin</div>
            </div>
        </div>
        <v-spacer />
        <div class="pa-5" :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column' : 'd-flex align-start justify-start'" >
            <!-- Users and Sources -->
            <div class="mx-0">
                <v-row class="d-flex justify-start">
                    <v-col cols="12" xs="12" sm="6" md="4" lg="11">
                        <BasicCard style="height: 100% !important; cursor: pointer" @click.native="MIX_go('/users')">
                            <div class="pa-8 d-flex align-start">
                                <div><v-icon class="text-h3">icons8-staff</v-icon></div>
                                <div class="ml-3">
                                    <div class="text-h6 font-weight-light primary--text">Users</div>
                                    <div class="text-body-2">In the Users section of AR:Deals, you can easily manage and approve users. This feature allows you to efficiently handle user accounts, granting access to the platform.</div>
                                </div>
                            </div>
                        </BasicCard>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="4" lg="11">
                        <BasicCard style="height: 100% !important; cursor: pointer" @click.native="MIX_go('/sources')">
                            <div class="pa-8 d-flex align-start">
                                <div><v-icon class="text-h3">icons8-database</v-icon></div>
                                <div class="ml-3">
                                    <div class="text-h6 font-weight-light primary--text">Sources</div>
                                    <div class="text-body-2">The Sources section in AR:Deals provides an administrative function that enables users to monitor the status of each source and view the last feed obtained.</div>
                                </div>
                            </div>
                        </BasicCard>
                    </v-col>
                </v-row>
                <!-- Logs and lookups -->
                <v-row class="d-flex justify-start" >
                    <v-col cols="12" xs="12" sm="6" md="4" lg="11" class="">
                        <BasicCard style="height: 100% !important; cursor: pointer" @click.native="MIX_go('/lookups')">
                            <div class="pa-8 d-flex align-start">
                                <div><v-icon class="text-h3">icons8-marketplace</v-icon></div>
                                <div class="ml-3">
                                    <div class="text-h6 font-weight-light primary--text">Lookups</div>
                                    <div class="text-body-2">The Lookups section in AR:Deals provides an administrative function that enables users to monitor and edit the existing lookups, as well as add new ones.</div>
                                </div>
                            </div>
                        </BasicCard>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="4" lg="11">
                        <BasicCard style="height: 100% !important; cursor: pointer" @click.native="MIX_go('/logs')">
                            <div class="pa-8 d-flex align-start">
                                <div><v-icon class="text-h3">icons8-business-building</v-icon></div>
                                <div class="ml-3">
                                    <div class="text-h6 font-weight-light primary--text">Logs</div>
                                    <div class="text-body-2">The Logs section in AR:Deals provides an administrative function that enables users to monitor and edit the existing logs.</div>
                                </div>
                            </div>
                        </BasicCard>
                    </v-col>
                </v-row>
                
            </div>

                <div v-if="$vuetify.breakpoint.mdAndUp" class="vertical-divider"></div>

                <!-- <v-row class="d-flex justify-center" no-gutters> -->
                    <v-col cols="12" xs="12" sm="12" lg="6" :class="$vuetify.breakpoint.smAndDown  ? 'px-0 pt-5' : 'pt-0'">
                        <div class="font-weight-light primary--text text-h5 mb-4">Release Notes</div>
                        <v-expansion-panels class="rounded-of elevation-0" flat accordion>
                            <v-expansion-panel dense v-for="(note, index) in releaseNotes" :key="index">
                                <v-expansion-panel-header class="white font-weight-bold">
                                    <span class="font-weight-bold primary--text">{{ note.title }}</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="white">
                                    <div class="font-weight-light primary--text">Features</div>
                                    <ul class="grey--text text--darken-2">
                                        <li v-for="(feature, featureIndex) in note.features" :key="featureIndex">{{ feature }}</li>
                                    </ul>
                                    <p class="mt-3 font-weight-light primary--text">Bug Fixes</p>
                                    <ul class="grey--text text--darken-2">
                                        <li v-for="(bug, bugIndex) in note.bugs" :key="bugIndex">{{ bug }}</li>
                                    </ul>
                                    <p class="mt-3 font-weight-light primary--text">Known Issues</p>
                                    <ul class="grey--text text--darken-2">
                                        <li v-for="(issue, issueIndex) in note.issues" :key="issueIndex">{{ issue }}</li>
                                    </ul>
                                    <p class="mt-3 font-weight-light primary--text">Coming Soon</p>
                                    <ul class="grey--text text--darken-2">
                                        <li v-for="(coming, comingIndex) in note.coming" :key="comingIndex">{{ coming }}</li>
                                    </ul>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                <!-- </v-row> -->
        </div>
    </div>
</template>

<script>
export default {
    name: "AdminView",
    data: () => ({
        loading: false,
        releasenote: 0,
        releaseNotes: [
               {
                title: "08 September 23 - V0.2.11 - Development Release",
                features: ["Manual Matches Working", "Added capacity to reset passwords for users", "Clients - Favourite Content Page added"
                ],
                issues: [],
                bugs: ["Fixed bug not allowing users to edit Client Criteria right after creating a new client"],
                coming: ["Logs - Filtering by Log Context"],
            },
                          {
                title: "21 July 23 - V0.2.10 - Development Release",
                features: ["Sources - Status icon now reflects the Last Feed Date: < 2 Days - Green, 2-4 Days - Orange, 5 Days+ - Red.", 
                "Marketplace / Clients - Cards aligned with top bar.", 
                "Clients - Added a Loading Spinner on the Clients Page with the words Loading Clients underneath.", 
                "Client > Matches List - Added Column for Filter for Enquiry", 
                "Client > Matches List - Add Status Tab and Dropdown Filters for Enquiry Status. Enquiry Status can only be filled if the Match has been Accepted or Rejected.",
                "Client > Matches List - Enquiry Statuses change depending on whether Match has been Approved or Rejected.",
                "New Reasons for Rejections added."],
                issues: ["Clients - Favourite Content Page not showing"],
                bugs: [],
                coming: ["Logs - Filtering by Log Context"],
            },
                        {
                title: "18 July 23 - V0.2.9 - Development Release",
                features: ["Marketplace & Clients: when filters are open, the left side of the page becomes slightly opaque.", "Clients: Clients now ordered by number of matches.", "Marketplace Filters: When selecting multiple items from a dropdown only one is visible and the others are summarized as + number.", "Marketplace Filters: Total results shows at the bottom.", "Marketplace Filters: Users can select multiple sources/ providers.", "Marketplace: Sector is suggested when typing a search.", "Match: Match Report is now accurate"],
                issues: ["Clients - Favourite Content Page not showing"],
                bugs: [],
                coming: ["Logs - Filtering by Log Context"],
            },
            {
                title: "05 July 23 - V0.2.8 - Development Release",
                features: ["Responsive View - Optimised for mobile / tablet", "Bug fixes and extra checks to ensure data enter by users complies with the required format.", "Added Admin Section and moved Sources, Lookups, Users and Logs into it.", "Terms & Conditions set as mandatory."],
                issues: ["Match - Match Report not accurate", "Clients - Favourite Content Page not showing"],
                bugs: [],
                coming: ["Logs - Filtering by Log Context"],
            },
            {
                title: "12 June 23 - V0.2.7 - Development Release",
                features: ["Backend - Reworked Matching service, putting more emphasis on sectors."],
                issues: [],
                bugs: ["Client Profile - Unable to select type"],
                coming: [],
            },

            {
                title: "12 June 23 - V0.2.6 - Development Release",
                features: ["Marketplace - Added feature to businesses pages to add, edit and remove notes.", "Clients - Added feature to businesses pages to add, edit and remove notes.", "Client Matches - Added feature to businesses pages to add, edit and remove notes."],
                issues: ["Match - Match Report not accurate", "Clients - Favourite Content Page not showing"],
                bugs: [],
                coming: ["Logs - Filtering by Log Context"],
            },
            {
                title: "05 June 23 - V0.2.5 - Development Release",
                features: ["Sources - Edit Capability", "Logs - View Logs and Filtering"],
                issues: [],
                bugs: [],
                coming: ["Clients - Favourite Content Page", "Logs - Filtering by Log Context"],
            },
            {
                title: "01 June 23 - V0.2.4 - Development Release",
                features: [],
                issues: [],
                bugs: ["Client Profile Bug preventing Saving - Fixed", "Client Match - Match Report not accurate - Fixed"],
                coming: [],
            },
            {
                title: "01 June 23 - V0.2.3 - Development Release",
                features: ["Clients - Matches (Matches Run Every Hour)", "Clients - Match Profile and Matches on Card Mode"],
                issues: ["Responsive View - Not yet optimised for mobile / tablet"],
                bugs: ["N/A"],
                coming: [],
            },
            {
                title: "29 May 23 - V0.2.2 - Development Release",
                features: ["Clients - Card View", "Clients - Favourites / Starred", "Clients - Summary Screen / Edit Mode", "Marketplace - Favourites / Starred"],
                issues: ["Responsive View - Not yet optimised for mobile / tablet"],
                bugs: ["N/A"],
                coming: ["Client Matches"],
            },
            {
                title: "23 May 23 - V0.2.1 - Development Release",
                features: ["Marketplace - Buy / Sell filtering", "Clients - Add / Edit Client", "Clients - Client Profile"],
                issues: ["Responsive View - Not yet optimised for mobile / tablet"],
                bugs: ["Location Fixes", "Sector and Keyword Fixes"],
                coming: ["Client Matches"],
            },
            {
                title: "15 May 23 - V0.2.0 - Development Release",
                features: ["BizSale - Daily Feed", "Mark to Market - Daily Feed", "Business Sale Report - Daily Feed", "Marketplace - Access to Feeds", "Clients - Access to Manage Clients", "Admin - Users - Manage Users", "Admin - Sources - Manage Sources", "Admin - Lookups - Manage Lookups</li>"],
                issues: ["Responsive View - Not yet optimised for mobile / tablet"],
                bugs: ["N/A"],
                coming: ["Client Matches", "Marketplace - Buy / Sell filtering"],
            },
        ],
    }),
};
</script>

<style scoped>
    .vertical-divider {
  border-left: 1px solid var(--v-primary-base); /* Set the desired color for the divider */
  height: 100vh !important; /* Make the divider span the full height */
  margin-right: 10px; /* Adjust the margin as needed */
}

.v-expansion-panel-content {
  max-width: 600px; /* Adjust the value as needed */
  margin: 0 auto; /* Center the panel horizontally */
}
</style>
