<template>
    <div>
        <div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>

        <div v-if="filterDrawer || drawer" class="leftside-overlay" @click="(filterDrawer = false), (drawer = false)"></div>
        <v-row dense class="px-5 pt-5" :class="$vuetify.breakpoint.width > 1000 && $vuetify.breakpoint.width < 1400 && filterDrawer ? 'd-flex justify-space-between ' : ''">
            <v-col cols="12" :class="$vuetify.breakpoint.width < 848 ? '' : 'd-flex justify-space-between'">
                <div class="d-flex align-center">
                    <!-- <div class="red" :style="$vuetify.breakpoint.width < 600 ? 'width:100vw !important;' :''"> -->
                    <div class="text-h4 primary--text" style="padding-left: 10px">
                        Marketplace
                        <div class="d-flex align-center" style="width: 100% !important">
                            <div class="body-2 grey--text text--darken-2">
                                Showing <strong>{{ itemsFrom + 1 }}</strong>
                                <span v-if="itemsTo !== itemsTotal">
                                    to <strong>{{ itemsTo }}</strong></span
                                >
                                of <strong>{{ itemsTotal }}</strong>
                            </div>
                        </div>
                        <div v-show="updateSuggestions.length > 0" class="pt-2">
                            <div class="body-2 black--text text--darken-2">
                                We have found the following sector(s)/ subsector(s) that match your search criteria:
                                <span v-for="(sector, index) in updateSuggestions" :key="sector.value">
                                    <span class="primary--text" style="text-decoration: underline; cursor: pointer" @click="selectSector(sector.value)">{{ sector.text }}</span>
                                    <span v-if="index < updateSuggestions.length - 1">, </span>
                                </span>
                                <!-- <span style="cursor: pointer" @click="clearSuggestions"> <v-icon class="primary--text font-weight-bold" size="14">icons8-close</v-icon> </span> -->
                            </div>

                            <div v-show="subsectorSuggestions.length > 0" class="pt-2">
                                <span v-for="(subsector, index) in subsectorSuggestions" :key="subsector.value">
                                    <span class="primary--text" style="text-decoration: underline; cursor: pointer" @click="selectSector(subsector.value)">{{ subsector.text }}</span>
                                    <span v-if="index < updateSuggestions.length - 1">, </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <v-spacer />
                </div>

                <div :class="$vuetify.breakpoint.width >= 600 && $vuetify.breakpoint.width < 880 ? `d-flex pt-4` : $vuetify.breakpoint.width < 600 ? `pt-4 d-flex flex-wrap align-start justify-space-evenly ` : 'd-flex align-center justify-end'" style="padding-left: 10px; padding-right: 10px">
                    <SearchField v-if="$vuetify.breakpoint.width > 1500 || ($vuetify.breakpoint.width > 880 && !filterDrawer)" label="Search" v-model="search" :dense="true" style="max-width: 48%" />
                    <v-btn-toggle v-model="filter.type" class="rounded-of py-0 mt-0" style="height: 46px !important">
                        <v-btn title="Sell" value="sell" active-class="primary white--text" style="height: 46px !important"><span class="">Sell</span></v-btn>
                        <v-btn title="Buy" value="buy" active-class="primary white--text" style="height: 46px !important"><span class="">Buy</span></v-btn>
                    </v-btn-toggle>

                    <AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>
                    <AppButton @click.native="seeFavourites = !seeFavourites"><v-icon class="gold--text">icons8-rating</v-icon></AppButton>
                    <AppButton @click.native="previous()" :disabled="this.tableOptions.page === 1" class="mr-2"><v-icon class="primary--text">icons8-previous</v-icon></AppButton>
                    <AppButton v-if="!table" @click.native="table = true"><v-icon class="primary--text">icons8-table</v-icon></AppButton>
                    <AppButton v-if="table" @click.native="table = false"><v-icon class="primary--text">icons8-rows</v-icon></AppButton>
                    <AppButton @click.native="next()" :disabled="tableOptions.page * tableOptions.itemsPerPage >= itemsTotal"><v-icon class="primary--text">icons8-next</v-icon></AppButton>

                    <AppButton @click.native="getTableData"><v-icon>icons8-synchronize</v-icon></AppButton>
                    <!-- TODO - Invite Button required -->
                    <!-- TODO - Add User needs to work with Firebase Authentication -->
                    <!-- <div class="text--secondary pt-4" v-if="showInfo">The following page shows all users that have access to the App/Portal, you can use this page to Manage users and access.</div> -->
                    <div class="white--text">
                        <DropdownMenu>
                            <div class="d-flex align-center">
                                <v-switch color="secondary" class="" inset dense v-model="enableMatch" />
                                <div class="font-weight-bold secondary--text">Enable Match</div>
                            </div>
                        </DropdownMenu>
                    </div>
                    <AppButton v-if="enableMatch" buttonclass="secondary" @click.native="addMatches">Add Matches</AppButton>
                </div>
            </v-col>

            <!-- Table -->
            <v-col cols="12" class="pt-6">
                <!-- Data Table -->
                <DataTable v-if="table && tableData.length > 0" :actions="['View']" :enablematch="enableMatch" :tableheaders="filteredHeaders" :loading="loading" @checkbox-changed="handleCheckboxChange" custom="business" :tabledata="tableData" :datatableoptions="tableOptions" :itemstotal="itemsTotal" v-on:delete="deleteBusiness" v-on:edit="editBusiness" v-on:restore="restoreBusiness" @star="starItem" v-on:view="viewBusiness" v-on:tableoptions="updateTableOptions" :lookups="lookups" />
                <div v-if="table && tableData.length === 0 && seeFavourites">No favourites added</div>
                <!-- <v-row v-if="!table && tableData.length > 0" class="card-container blue">
                    <v-col cols="12" xs="12" sm="6" md="4" lg="4" v-for="item in tableData" :key="item.entityId" @click="viewBusiness(item)" class=" red card"> -->
                <div v-if="!table && tableData.length > 0" class="business-cards-container">
                    <div class="business-cards" v-for="item in tableData" :key="item.entityId" @click="viewBusiness(item)">
                        <BasicCard footerclass="grey lighten-4" class="business-card-component">
                            <template v-slot:header>
                                <div class="full-width d-flex justify-start align-start px-4 pt-2" style="height: 60px !important; overflow: hidden !important">
                                    <v-checkbox v-if="enableMatch" v-model="selectedBusinesses[item.id]" color="primary" :value="item" class="ma-0 pa-0" hide-details @click.stop></v-checkbox>

                                    <div class="text-body-1 font-weight-bold primary--text text-left" style="cursor: pointer">
                                        {{ item.title.substring(0, 80) }}
                                    </div>
                                    <v-spacer />
                                    <div class="d-flex align-start pa-0" no-gutters>
                                        <v-icon @click.stop="starItem(item)" :class="item.isStarred ? 'gold--text' : 'grey--text'" size="22">icons8-rating</v-icon>
                                    </div>
                                    <!-- <div class="text-right" style="min-width: 120px !important;">
										<v-btn icon @click.stop="alertme()"><v-icon class="grey--text">icons8-star</v-icon></v-btn>
										<v-btn icon @click.stop="alertme()"><v-icon class="primary--text">icons8-hide</v-icon></v-btn>
										<v-btn icon @click.stop="alertme()"><v-icon class="error--text">icons8-trash-can</v-icon></v-btn>
									</div> -->
                                </div>
                            </template>
                            <div class="mt-2 mb-1 grey lighten-3" style="height: 3px !important"></div>
                            <div class="px-4 text-body-1 grey--text text--darken-4 pb-5 mt-3" style="cursor: pointer">
                                <div class="text-body-2 d-flex font-weight-bold">
                                    <div><v-icon class="grey--text pr-1" small>icons8-tear-off-calendar</v-icon>{{ MIX_formatDateTime(item.listingDateTime, "ISOString", "D MMM YYYY") }}</div>
                                    <v-spacer />
                                    <div class="success--text" v-if="MIX_formatDateTimeFromNow(item.lastSeenDateTime, 'ISOString', 'Days') === 'Today'"><v-icon class="success--text pr-1" small>icons8-important-time</v-icon>{{ MIX_formatDateTimeFromNow(item.lastSeenDateTime, "ISOString", "Days") }}</div>
                                    <div class="success--text" v-else><v-icon class="success--text pr-1" small>icons8-clock-checked</v-icon>{{ MIX_formatDateTimeFromNow(item.lastSeenDateTime, "ISOString", "Days") }}</div>
                                </div>
                                <div class="mt-2 d-flex align-center">
                                    <div class="text-body-2 font-italic">{{ item.sourceName }}</div>
                                    <v-spacer />
                                    <div class="text-body-2">
                                        {{ lookupValue(item.type) }} <span v-if="item.ownership">/ {{ lookupValue(item.ownership) }}</span>
                                    </div>
                                </div>
                                <div class="mt-1 d-flex flex-wrap" style="overflow: hidden !important">
                                    {{ item.summary?.substring(0, 80) }}...</div>
                                <!-- <v-icon class="grey--text pr-1" small>icons8-link</v-icon><a :href="item.sourceURL" class="" target="_blank">Mark to Market</a></div> -->
                            </div>
                            <template v-slot:footer>
                                <div :class="$vuetify.breakpoint.width > 1200 ? 'full-width px-4 py-2 d-flex align-center' : 'd-flex flex-column'" style="overflow: hidden !important">
                                    <!-- <div class=""><v-icon class="grey--text text--darken-1">icons8-address</v-icon></div> style="overflow: hidden !important"
									<div class="">{{ item?.location }}</div> -->
                                    <div :class="$vuetify.breakpoint.width > 1200 ? 'd-flex flex-row ' : $vuetify.breakpoint.width > 800 ? 'px-2 pt-2 d-flex flex-row' : 'px-2 pt-1 d-flex flex-row'">
                                        <div class="flex-grow-1 d-flex align-center">
                                            <v-icon class="grey--text text--darken-1">icons8-sales-balance</v-icon>
                                            <span v-if="item.turnover !== 0">{{ MIX_formatCurrency(item?.turnover, 0) }}</span>
                                            <span v-else>-</span>
                                        </div>
                                        <div class="flex-grow-1 d-flex align-center">
                                            <v-icon class="grey--text text--darken-1">icons8-price-tag-pound</v-icon>
                                            <span v-if="item.price !== 0">{{ MIX_formatCurrency(item?.price, 0) }}</span>
                                            <span v-else>-</span>
                                        </div>
                                    </div>
                                    <div :class="$vuetify.breakpoint.width > 1200 ? '' : 'px-2 pb-1'">
                                        <div class="flex-grow-1 d-flex align-center">
                                            <v-icon class="grey--text text--darken-1">icons8-total-sales</v-icon>
                                            <span v-if="item.ebitda !== 0">{{ MIX_formatCurrency(item?.ebitda, 0) }}</span>
                                            <span v-else>-</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </BasicCard>
                    </div>
                </div>
                <!-- </v-col>
                </v-row> -->
                <v-row v-if="!table && tableData.length === 0 && seeFavourites">
                    <div v-if="!loading" class="primary--text d-flex justify-center text-h6 font-weight-bold pa-8" style="width: 100%">No favourites added yet</div>
                </v-row>
            </v-col>

            <!-- Filter Drawer -->
            <v-navigation-drawer :width="$vuetify.breakpoint.width >= 600 ? '400px' : ''" class="px-5 pb-5 grey lighten-3" v-model="filterDrawer" style="z-index: 999" absolute clipped right>
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex align-center pt-4">
                            <div class="text-h5 secondary--text">Filters</div>
                            <v-spacer />
                            <v-btn icon depressed @click="filterDrawer = false"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        Use the filters below to narrow your search.
                    </v-col>

                    <v-col cols="12">
                        <!-- Status -->
                        <div class="flex-grow-1">
                            <!-- Search -->
                            <div class="d-flex align-center">
                                <div class="flex-grow-1 mb-4">
                                    <SearchField v-if="$vuetify.breakpoint.width <= 1500 && filterDrawer" label="Search" v-model="search" :dense="true" />
                                </div>
                            </div>
                            <!-- Sectors -->
                            <!-- <div class="mt-4 mb-2 text-h6 primary--text">Sectors</div> -->
                            <div class="d-flex align-center">
                                <div class="flex-grow-1">
                                    <v-select v-model="filter.sectors" :items="sectors" clearable solo filled class="rounded-of" flat @click:clear="clear()" label="Sectors" multiple background-color="white">
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip v-if="index === 0">
                                                <span>{{ item.text }}</span>
                                            </v-chip>
                                            <span v-if="index === 1" class="grey--text text-caption"> (+{{ filter.sectors.length - 1 }} others) </span>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <!-- SubSectors -->
                            <!-- <div class="mt-4 mb-2 text-h6 primary--text">Subsectors</div> -->
                            <div class="d-flex align-center">
                                <div class="flex-grow-1">
                                    <v-select v-model="filter.subsectors" :items="filteredsubsectors.length !== 0 ? filteredsubsectors : subsectors" clearable solo filled class="rounded-of" flat @click:clear="clear()" label="Subsectors" multiple background-color="white">
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip v-if="index === 0">
                                                <span>{{ item.text }}</span>
                                            </v-chip>
                                            <span v-if="index === 1" class="grey--text text-caption"> (+{{ filter.subsectors.length - 1 }} others) </span>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <!-- Region -->
                            <!-- <div class="mt-4 mb-2 text-h6 primary--text">Regions</div> -->
                            <div class="d-flex align-center">
                                <div class="flex-grow-1">
                                    <v-select v-model="filter.regions" :items="regions" clearable solo filled class="rounded-of" flat @click:clear="clear()" label="Regions" multiple background-color="white">
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip v-if="index === 0">
                                                <span>{{ item.text }}</span>
                                            </v-chip>
                                            <span v-if="index === 1" class="grey--text text-caption"> (+{{ filter.regions.length - 1 }} others) </span>
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                            <!-- Financials -->
                            <!-- <div class="mt-4 mb-2 text-h6 primary--text">Financials</div> -->
                            <!-- Turnover -->
                            <div class="mt-4 d-flex align-center">
                                <div class="flex-grow-1">
                                    <TextField backgroundcolor="white" label="Turnover Min" v-model="filter.turnoverMin" clearable />
                                    <div class="text-overline ml-6">{{ MIX_formatCurrency(filter.turnoverMin, 0) }}</div>
                                </div>
                                <div class="flex-grow-1">
                                    <TextField backgroundcolor="white" label="Turnover Max" v-model="filter.turnoverMax" clearable />
                                    <div class="text-overline ml-6">{{ MIX_formatCurrency(filter.turnoverMax, 0) }}</div>
                                </div>
                            </div>
                            <!-- Validation Message -->
                            <div class="flex-grow-1">
                                <div v-if="filter.turnoverMax < filter.turnoverMin && filter.turnoverMin && filter.turnoverMax" class="red--text">Turnover Max cannot be lower than Turnover Min</div>
                            </div>

                            <div class="mt-4 mb-2 text-h6 primary--text">Other</div>
                            <!-- Price -->
                            <div class="d-flex align-center mt-2">
                                <div class="flex-grow-1">
                                    <TextField backgroundcolor="white" label="Price Min" v-model="filter.priceMin" clearable />
                                    <div class="text-overline ml-6" v-if="filter.priceMin !== '' || filter.priceMax !== ''">{{ MIX_formatCurrency(filter.priceMin, 0) }}</div>
                                </div>
                                <div class="flex-grow-1">
                                    <TextField backgroundcolor="white" label="Price Max" v-model="filter.priceMax" clearable />
                                    <div class="text-overline ml-6" v-if="filter.priceMin !== '' || filter.priceMax !== ''">{{ MIX_formatCurrency(filter.priceMax, 0) }}</div>
                                </div>
                            </div>
                            <!-- Validation Message -->
                            <div class="flex-grow-1">
                                <div v-if="filter.priceMax < filter.priceMin && filter.priceMin && filter.priceMax" class="red--text">Price Max cannot be lower than Price Min</div>
                            </div>

                            <!-- EBITDA -->
                            <div class="d-flex align-center mt-2">
                                <div class="flex-grow-1">
                                    <TextField backgroundcolor="white" label="EBITDA Min" v-model="filter.ebitdaMin" clearable />
                                    <div class="text-overline ml-6" v-if="filter.ebitdaMin !== '' || filter.ebitdaMax !== ''">{{ MIX_formatCurrency(filter.ebitdaMin, 0) }}</div>
                                </div>
                                <div class="flex-grow-1">
                                    <TextField backgroundcolor="white" label="EBITDA Max" v-model="filter.ebitdaMax" clearable />
                                    <div class="text-overline ml-6" v-if="filter.ebitdaMin !== '' || filter.ebitdaMax !== ''">{{ MIX_formatCurrency(filter.ebitdaMax, 0) }}</div>
                                </div>
                            </div>
                            <!-- Validation Message -->
                            <div class="flex-grow-1">
                                <div v-if="filter.ebitdaMax < filter.ebitdaMin && filter.ebitdaMin && filter.ebitdaMax" class="red--text">EBITDA Max cannot be lower than EBITDA Min</div>
                            </div>

                            <!-- PROFIT -->
                            <div class="d-flex align-center mt-2">
                                <div class="flex-grow-1">
                                    <TextField backgroundcolor="white" label="Profit Min" v-model="filter.profitMin" clearable />
                                    <div class="text-overline ml-6" v-if="filter.profitMin !== '' || filter.profitMax !== ''">{{ MIX_formatCurrency(filter.profitMin, 0) }}</div>
                                </div>
                                <div class="flex-grow-1">
                                    <TextField backgroundcolor="white" label="Profit Max" v-model="filter.profitMax" clearable />
                                    <div class="text-overline ml-6" v-if="filter.profitMin !== '' || filter.profitMax !== ''">{{ MIX_formatCurrency(filter.profitMax, 0) }}</div>
                                </div>
                            </div>
                            <!-- Validation Message -->
                            <div class="flex-grow-1">
                                <div v-if="filter.profitMax < filter.profitMin && filter.profitMin && filter.profitMax" class="red--text">Price Max cannot be lower than Price Min</div>
                            </div>

                            <!-- Ownership -->
                            <!-- <div class="mt-4 mb-2 text-h6 primary--text">Property/Tenure</div> -->
                            <div class="d-flex align-center mt-4">
                                <div class="flex-grow-1">
                                    <DropdownMultiple backgroundcolor="white" label="Property/Tenure" :items="businessOwnership" v-model="filter.ownership" />
                                </div>
                            </div>
                            <!-- <div class="mt-4 mb-2 text-h6 primary--text">Source</div> -->
                            <!-- Source -->
                            <div class="d-flex align-center mt-4">
                                <div class="flex-grow-1">
                                    <v-select v-model="filter.sourceId" :items="sources" clearable solo filled class="rounded-of" item-text="name" item-value="entityId" flat @click:clear="clear()" label="Provider" multiple background-color="white">
                                        <template v-slot:selection="{ item, index }">
                                            <v-chip v-if="index === 0">
                                                <span>{{ item.name }}</span>
                                            </v-chip>
                                            <span v-if="index === 1" class="grey--text text-caption"> (+{{ filter.sourceId.length - 1 }} others) </span>
                                        </template>
                                    </v-select>
                                    <!-- <DropdownAdvanced backgroundcolor="white" label="Provider" :items="sources" itemtext="name" itemvalue="entityId" v-model="filter.sourceId" /> -->
                                </div>
                            </div>
                            <!-- Result Total -->
                            <div class="mb-2 text-h6 primary--text">{{ itemsTotal }} matching results found</div>

                            <!-- Listing Date -->
                            <!-- Sample Date Start -->
                            <!-- <div class="mt-4 mb-2 text-h6 primary--text">Listing Date</div>
							<div class="d-flex align-center">
								<div class="flex-grow-1">
									<DatePicker backgroundcolor="white" label="Start Date" v-model="filter.sampleDateStart" :dense="true" />
								</div>
							</div>
							<div class="d-flex align-center mt-2">
								<div class="flex-grow-1">
									<DatePicker backgroundcolor="white" label="End Date" v-model="filter.sampleDateEnd" :dense="true" />
								</div>
							</div> -->
                            <!-- Sample Date End -->
                        </div>
                    </v-col>
                </v-row>
                <!-- </v-navigation-drawer> -->
            </v-navigation-drawer>
            <!-- Edit Window -->
            <v-navigation-drawer width="550px" class="grey lighten-3" v-model="drawer" absolute clipped right style="z-index: 999">
                <!-- Practice Details -->
                <v-row dense class="mt-5">
                    <v-col cols="12">
                        <div class="d-flex align-start pb-2" style="height: 70px">
                            <div class="px-5 text-h6 primary--text">
                                {{ business?.title }}
                                <v-icon class="ml-1" @click="starItem(business)" :class="business.isStarred ? 'gold--text' : 'grey--text'">icons8-rating</v-icon>
                                <!-- <v-icon title="Star" class="ml-1" @click="starItem(item)" :class="item.isStarred ? 'gold--text' : 'grey--text'">icons8-rating</v-icon> -->
                                <!-- <v-icon class="ml-auto" @click="starItem(item)" >icons8-rating</v-icon> -->
                            </div>
                            <v-spacer />
                            <v-btn class="pr-5" icon depressed @click="closeUser"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        <!-- Use the form below to edit the User's details -->
                    </v-col>
                    <v-col cols="12" class="grey lighten-3">
                        <v-tabs v-model="tab" fixed-tabs background-color="primary" dark>
                            <v-tab href="#description"> Description </v-tab>
                            <v-tab href="#financials"> Financials </v-tab>
                            <v-tab href="#notes"> Notes </v-tab>
                            <!-- <v-tab href="#matches"> Matches </v-tab> -->
                            <v-tab-item value="description" class="white pa-5 pt-2" style="height: calc(100vh - 230px); overflow: scroll">
                                <div class="mt-4 text-body-1" v-if="JSON.stringify(business.location) !== '[]' && business?.location">
                                    <div class="text-h6 primary--text">Location</div>
                                    <!-- <pre>{{ business.location }}</pre> -->
                                    <v-chip label v-for="(location, index) in business.location" :key="index" class="mr-1 my-1 secondary lighten-2 primary--text">{{ lookupValue(location) }}</v-chip>
                                </div>
                                <div class="mt-4 text-body-1" v-if="JSON.stringify(business.sectors) !== '[]' && business?.sectors">
                                    <div class="text-h6 primary--text">Sectors</div>
                                    <v-chip label v-for="(sector, index) in business.sectors" :key="index" class="mr-1 my-1 secondary lighten-2 primary--text">{{ lookupValue(sector) }}</v-chip>
                                </div>
                                <div class="mt-4 text-body-1" v-if="JSON.stringify(business.subsectors) !== '[]' && business?.subsectors">
                                    <div class="text-h6 primary--text">Subsectors</div>
                                    <v-chip label v-for="(subsector, index) in business.subsectors" :key="index" class="mr-1 my-1 secondary lighten-2 primary--text">{{ lookupValue(subsector) }}</v-chip>
                                </div>
                                <!-- Description -->
                                <div class="mt-4 text-h6 primary--text">Description</div>
                                <div class="mt-2 text-body-1" v-html="business?.description"></div>
                                <!-- <div class="mt-4 text-h6 primary--text">JSON</div>
                                <div class="mt-2 text-body-1"><pre>{{ business }}</pre></div> -->
                                <!-- Keywords -->
                                <div class="mt-4 text-body-1" v-if="JSON.stringify(business.keywords) !== '[]' && business?.keywords">
                                    <div class="text-h6 primary--text">Keywords</div>
                                    <v-chip @click:close="addIgnoreKeyword(keyword)" close close-icon="mdi-delete" label v-for="(keyword, index) in filterIgnoreList(business.keywords)" :key="index" class="mr-1 my-1 secondary lighten-2 primary--text">{{ keyword }}</v-chip>
                                </div>
                            </v-tab-item>
                            <v-tab-item value="financials" class="grey lighten-3">
                                <div class="mt-2 px-5"><SimpleTable :tabledata="computedBusiness" class="my-3 white" /></div>
                            </v-tab-item>
                            <v-tab-item value="matches" class="grey lighten-3">
                                <div class="mt-2 px-5">Matches to come</div>
                            </v-tab-item>
                            <v-tab-item value="notes" class="grey lighten-3 px-4" style="height: calc(100vh - 230px); overflow: scroll">
                                <NotesComponent @saveNote="saveNewNote" :orgnotes="notes" :notesmode="noteFormMode" @updateprop="updateNotesProp" :currentuser="GET_currentUserProfile" @saveChanges="editNotes" @deleteNote="deleteNote" />
                            </v-tab-item>
                        </v-tabs>
                    </v-col>
                </v-row>
            </v-navigation-drawer>

            <!-- Confirmation Box -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false" style="z-index: 999">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirm you want to delete this Client</p>
                <strong>{{ user.name }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeleteUser">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
            <!-- Notes confirmation box -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBoxNotes" v-on:close="confirmBoxNotes = false" style="z-index: 999">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBoxNotes = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirm you want to delete this Note</p>
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBoxNotes = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeleteNote">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
            <!-- Matches confirmation box -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBoxMatches" v-on:close="confirmBoxMatches = false" style="z-index: 999">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Select a Client to match your selection</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBoxMatches = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <!-- <DropdownSimple :items="clientList" :item-value="clientList.id" label="Client" v-model="selectedClient" :validate="validate.selectedClient" /> -->
                <v-select :items="clientList" item-text="text" item-value="id" label="Client" filled rounded height="52" class="rounded-of mb-0" v-model="selectedClient" :validate="validate.selectedClient"></v-select>
                <DropdownSimple :disabled="selectedClient === {}" :items="enquiryStatusApproved" label="Reason" v-model="enquiryStatus" :validate="validate.enquiryStatus" />
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBoxMatches = false">Cancel</AppButton>
                            <AppButton buttonclass="success" @click.native="saveManualMatches">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "BusinessView",
    // data
    data: () => ({
        // NOTES RELATED
        notes: [],
        note: {},
        noteExists: false,
        noteFormMode: "",
        confirmBoxNotes: false,
        deleteNoteObj: {},

        // matches
        enableMatch: false,
        selectedBusinesses: [],
        confirmBoxMatches: false,
        clientList: [],
        selectedClient: {},
        enquiryStatus: "",
        enquiryStatusApproved: ["Match Pending Approval (AR:Deals)", "Match Pending Approval (Manual)", "Match Verified", "Match Shared with Client", "Client Accepted – Info Required", "Client Accepted – Information Shared", "Client Accepted – Meeting Scheduled", "Client Accepted – Offer Made", "Client Accepted – Deal Completed"],

        // suggestions
        showSuggestions: false,
        suggestionsList: [],

        //stat elements
        isStarred: false,
        stat: {},
        seeFavourites: false,
        starredIdsArray: [],
        idsArr: [],

        loading: false,
        drawer: false,
        tab: "description",
        filterDrawer: false,
        table: false,
        confirmBox: false,
        permenantDelete: false,
        search: "",
        filter: {
            turnoverMin: "",
            turnoverMax: "",
            priceMin: "",
            priceMax: "",
            ebitdaMin: "",
            ebitdaMax: "",
            profitMin: "",
            profitMax: "",
            sectors: [],
            subsectors: [],
            location: [],
            regions: [],
            ownership: [],
            sourceId: [],
            type: "sell",
            showDeleted: false,
        },
        filteredsubsectors: [],
        tableData: [],
        itemsTotal: 0,
        tableHeaders: [
            { text: "Listing Date", value: "listingDateTime", align: "start", hidden: false, sortable: false, shrunk: true, width: "100px", smallDevice: true },
            { text: "Title", value: "title", align: "start", hidden: false, sortable: false, shrunk: true, smallDevice: true },
            { text: "Summary", value: "summary", align: "start", hidden: true, sortable: false, shrunk: true, smallDevice: true },
            { text: "Location", value: "location", align: "start", hidden: false, sortable: false, shrunk: true, smallDevice: true },
            { text: "Turnover", value: "turnover", align: "start", hidden: false, sortable: false, shrunk: true, smallDevice: false },
            { text: "Price", value: "price", align: "start", hidden: false, sortable: false, shrunk: true, smallDevice: false },
            { text: "EBITDA", value: "ebitda", align: "start", hidden: false, sortable: false, shrunk: true, smallDevice: false },
            { text: "Profit", value: "profit", align: "start", hidden: false, sortable: false, shrunk: true, smallDevice: false },
            { text: "Source", value: "source", align: "start", hidden: false, sortable: false, shrunk: true, smallDevice: true },

            // summary: "",
            // description: "",
            // raw: "",
            // ref: "",
            // location: "",
            // turnover: 0,
            // price: 0,
            // profit: 0,
            // ebitda: 0,
            // financialYear: "",
            // sourceName: "",
            // sourceURL: "",
            // sectors: [],
            // subSectors: [],
            // keywords: [],
            // type: "",
            // status: "",
            // listingDateTime: "",
            { text: "Last Seen", value: "lastSeenDateTime", align: "start", hidden: false, sortable: false, shrunk: true, smallDevice: false },
            { text: "createdUserId", value: "createdUserId", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: true },
            { text: "createdOrgId", value: "createdOrgId", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: true },
            { text: "createdDateTime", value: "createdDateTime", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: true },
            { text: "modifiedUserId", value: "modifiedUserId", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: true },
            { text: "modifedOrgId", value: "modifedOrgId", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: true },
            { text: "modifiedDateTime", value: "modifiedDateTime", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: true },
            { text: "deletedUserId", value: "deletedUserId", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: true },
            { text: "deletedOrgId", value: "deletedOrgId", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: true },
            { text: "deletedDateTime", value: "deletedDateTime", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: true },
            { text: "deleted", value: "deleted", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: true },
            { text: "", value: "action", align: "center", hidden: false, sortable: false, width: "150px", shrunk: true, smallDevice: true },
        ],
        tableOptions: {
            page: 1,
            itemsPerPage: 12,
            sortBy: ["listingDateTime"],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        validate: {},
        user: {},
        organisations: [],
        orgTypes: [],
        sectors: [],
        subsectors: [],
        regions: [],
        action: "Add",
        showInfo: false,
        business: {},
        ignorekeywordlist: [],
        sources: [],
        lookups: [],
        businessTypes: [],
        businessOwnership: [],
        locations: [],
    }),
    // computed
    computed: {
        // search suggestions from the sectors list
        updateSuggestions() {
            let query = "";
            if (this.search !== "" && this.search !== null && this.search !== undefined && this.search.length > 2) {
                query += `${this.search.toLowerCase()}`;
                const filteredList = this.sectors.filter((item) => item.value.includes(query));
                // if the filtered list is less than 2 items, return the list, else return nothing
                if (filteredList.length <= 2) {
                    query = filteredList;
                } else {
                    query = "";
                }
            }
            return query;
        },

        //map getters
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
        filteredHeaders() {
            let headers = this.tableHeaders.filter((header) => !header.hidden);
            if (this.drawer) {
                headers = headers.filter((header) => header.shrunk);
            }
            if (this.isSmallDevice()) {
                headers = headers.filter((header) => header.smallDevice);
            }
            return headers;
        },
        statSearchQuery() {
            let query = "";
            // Filter Type
            if (this.filter.type !== "") {
                query += ` @subcategory:{${this.filter.type}}`;
            }
            return query;
        },

        filterQuery() {
            let query = "";
            if (this.search !== "" && this.search !== null && this.search !== undefined && this.search.length > 1) {
                query += `${this.search.trim()}*`;
                                console.log("query", query);
                // console.log("this.search", this.search);
            }
            if (this.filter.showDeleted) {
                query += ` @deleted:{true}`;
            } else {
                query += `@deleted:{false}`;
            }
            // Filter Turnover
            if (this.filter.turnoverMin !== "" || this.filter.turnoverMax !== "") {
                const minTurnover = this.filter.turnoverMin !== "" ? parseFloat(this.filter.turnoverMin) : 0;
                const maxTurnover = this.filter.turnoverMax !== "" ? parseFloat(this.filter.turnoverMax) : 9999999999;

                if (minTurnover <= maxTurnover) {
                    query += ` @turnover:[${minTurnover} ${maxTurnover}]`;
                }
            }

            // Filter Price
            if (this.filter.priceMin !== "" || this.filter.priceMax !== "") {
                const minPrice = this.filter.priceMin !== "" ? parseFloat(this.filter.priceMin) : 0;
                const maxPrice = this.filter.priceMax !== "" ? parseFloat(this.filter.priceMax) : 9999999999;

                if (minPrice <= maxPrice) {
                    query += ` @price:[${minPrice} ${maxPrice}]`;
                }
            }

            // Filter Profit
            if (this.filter.profitMin !== "" || this.filter.profitMax !== "") {
                const minProfit = this.filter.profitMin !== "" ? parseFloat(this.filter.profitMin) : 0;
                const maxProfit = this.filter.profitMax !== "" ? parseFloat(this.filter.profitMax) : 9999999999;

                if (minProfit <= maxProfit) {
                    query += ` @profit:[${minProfit} ${maxProfit}]`;
                }
            }

            // Filter EBITDA
            if (this.filter.ebitdaMin !== "" || this.filter.ebitdaMax !== "") {
                const minEbitda = this.filter.ebitdaMin !== "" ? parseFloat(this.filter.ebitdaMin) : 0;
                const maxEbitda = this.filter.ebitdaMax !== "" ? parseFloat(this.filter.ebitdaMax) : 9999999999;

                if (minEbitda <= maxEbitda) {
                    query += ` @ebitda:[${minEbitda} ${maxEbitda}]`;
                }
            }

            // Filter Sector
            if (JSON.stringify(this.filter.sectors) !== "[]") {
                let sectors = this.filter.sectors.map((sector) => sector.toLowerCase());
                // replace any & with \&
                sectors = sectors.map((sector) => sector.replace("&", "\\&"));
                query += ` @sectors:{${sectors.join("|").toLowerCase()}}`;
            }
            // Filter SubSector
            if (JSON.stringify(this.filter.subsectors) !== "[]") {
                let subsectors = this.filter.subsectors.map((subsector) => subsector.toLowerCase());
                // replace any & with \&
                subsectors = subsectors.map((subsectors) => subsectors.replace("&", "\\&"));
                query += ` @subSectors:{${subsectors.join("|").toLowerCase()}}`;
            }
            // Filter Source
            if (JSON.stringify(this.filter.sourceId) !== "[]") {
                query += ` @sourceId:{${this.filter.sourceId.join("|")}}`;
            }
            // Filter Ownership
            if (JSON.stringify(this.filter.ownership) !== "[]") {
                query += ` @ownership:{${this.filter.ownership.join("|").toLowerCase()}}`;
            }
            // Filter Region
            if (JSON.stringify(this.filter.regions) !== "[]") {
                query += ` @region:{${this.filter.regions.join("|").toLowerCase()}}`;
            }
            // Filter Type
            if (this.filter.type !== "") {
                query += ` @type:{${this.filter.type}}`;
            }
            // Filter Status
            // if (JSON.stringify(this.filter.status) !== "[]") {
            // 	query += ` @status:{${this.filter.status.join("|")}}`;
            // }
            // Filter starred
            if (this.seeFavourites) {
                return (query += ` @id:{${this.idsArr.join("|")}}`);
            }
            // console.log(query);
            return query;
        },
        itemsFrom() {
            return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
        },
        itemsTo() {
            if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
                return this.itemsTotal;
            } else {
                return this.tableOptions.page * this.tableOptions.itemsPerPage;
            }
        },
        itemsCount() {
            return this.tableOptions.itemsPerPage;
        },
        itemsSortBy() {
            let sortBy = this.tableOptions.sortBy;
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => x);
                sortBy = sortBy.join(",");
            }
            return sortBy;
        },
        itemsSortDesc() {
            let sortDesc = this.tableOptions.sortDesc[0];
            if (sortDesc) {
                sortDesc = "DESC";
            } else {
                sortDesc = "ASC";
            }
            return sortDesc;
        },
        computedBusiness() {
            return [
                {
                    text: "Turnover",
                    value: this.business.turnover !== 0 ? this.MIX_formatCurrency(this.business.turnover, 0) : "-",
                },
                {
                    text: "Profit",
                    value: this.business.profit !== 0 ? this.MIX_formatCurrency(this.business.profit, 0) : "-",
                },
                {
                    text: "EBITDA",
                    value: this.business.ebitda !== 0 ? this.MIX_formatCurrency(this.business.ebitda, 0) : "-",
                },
                {
                    text: "Asking Price",
                    value: this.business.price !== 0 ? this.MIX_formatCurrency(this.business.price, 0) : "-",
                },
                {
                    text: "Financial Year",
                    value: this.business.financialYear,
                },
                {
                    text: "Source",
                    value: this.business.sourceName,
                    link: this.business.sourceURL,
                },
                {
                    text: "Ref",
                    value: this.business.ref,
                },
                {
                    text: "Listing Date",
                    value: this.MIX_formatDateTime(this.business.listingDateTime, "ISOString", "DD MMM YYYY"),
                },
                {
                    text: "Last Seen",
                    value: this.MIX_formatDateTime(this.business.lastSeenDateTime, "ISOString", "DD MMM YYYY"),
                },
                {
                    text: "id",
                    value: this.business.id,
                },
            ];
        },
    },
    // * before Destroy
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
    // watch
    watch: {
        filterQuery: {
            handler() {
                this.tableOptions.page = 1;
                this.getTableData();
            },
            deep: true,
        },
        tableOptions: {
            handler() {
                this.getTableData();
            },
            deep: true,
        },
        filter: {
            handler() {
                if (this.filter.sectors.length <= 1) {
                    this.subsectorSuggestions();
                }
            },
            deep: true,
        },
    },
    // methods
    methods: {
        // subsectors
        subsectorSuggestions() {
            if (this.filter.sectors.length == 0) {
                this.filteredsubsectors = [];
            } else if (this.filter.sectors.length === 1) {
                this.filteredsubsectors = this.lookups.filter((lookup) => lookup.filter == this.filter.sectors[0]);
            } else if (this.filter.sectors.length > 1) {
                this.filteredsubsectors.push(this.lookups.filter((lookup) => lookup.filter == this.filter.sectors[0]));
            }
        },
        //
        handleCheckboxChange(item) {
            // Do something with the item data received from the checkbox
            this.selectedBusinesses = item;
        },
        //
        async saveManualMatches() {
            let matchedBusiness = Object.values(this.selectedBusinesses);
            let matchedClient = this.clientList.find((client) => client.id === this.selectedClient);
            for (let i = 0; i < matchedBusiness.length; i++) {
                this.match = { ...this.$schema.match };
                try {
                    this.loading = true;

                    // validation
                    let validationErrors = 0;
                    if (this.selectedClient === "") {
                        validationErrors++;
                    }

                    if (this.enquiryStatus === "") {
                        validationErrors++;
                    }

                    // if no validation errors
                    if (validationErrors === 0) {
                        this.match.id = this.MIX_generateId();
                        this.match.status = "accepted";
                        this.match.orgId = matchedClient.id;
                        this.match.orgName = matchedClient.organisation;
                        this.match.businessId = matchedBusiness[i].id;
                        this.match.businessTitle = matchedBusiness[i].title;
                        this.match.businessType = matchedBusiness[i].type;
                        this.match.matched = "manual";
                        this.match.matchPercent = 100;
                        this.match.enquiryStatus = this.enquiryStatus;
                        this.match.createdUserId = this.GET_currentUserProfile.entityId;
                        this.match.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
                        this.match.modifiedUserId = this.GET_currentUserProfile.entityId;
                        this.match.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
                        // console.log("addOrgProfile=", JSON.stringify(this.match, null, 2));
                        await this.REDIS_createWithId("match", this.match, this.match.id);
                        // await this.REDIS_create("match", this.match);
                        this.MIX_alertBox({ show: true, message: "Match Saved", color: "success", timeout: "2000" });
                        this.match = "";
                        this.loading = false;
                        this.confirmBoxMatches = false;
                        this.enableMatch = false;
                    } else {
                        // runs if there are validaion errors
                        this.MIX_alertBox({ show: true, message: "Enquiry Status must be added before creating a match", color: "error", timeout: "4000" });
                        this.loading = false;
                    }
                } catch (error) {
                    this.MIX_alertBox({ show: true, message: "Error Saving Match", color: "error", timeout: "4000" });
                    console.error("error", error);
                    this.loading = false;
                }
            }
        },
        //
        async addMatches() {
            // console.log("addMatches", Object.values(this.selectedBusinesses));
            let dataResult = await this.REDIS_searchFor("org", "", "", "contactFirstName", "asc", `@deleted:{false}`);
            let tableData = dataResult.data.documents;
            this.clientList = tableData.map((item) => {
                return { text: item.contactFirstName + " " + item.contactLastName, organisation: item.name, id: item.id };
            });
            // console.log("this.clientList", this.clientList);
            this.confirmBoxMatches = !this.confirmBoxMatches;
        },
        //
        selectSector(sector) {
            this.filter.sectors.push(sector);
            this.search = "";
        },
        // removes sector from filter and from search bar
        clearSuggestions() {
            this.search = "";
            this.filter.sectors = [];
            this.showSuggestions = false;
        },
        // checks size and sets the columns on the table
        isSmallDevice() {
            return window.innerWidth < 880;
        },
        handleResize() {
            this.filterDrawer = window.innerWidth > 600;
        },
        // confirmation before deleting notes
        deleteNote(note) {
            this.deleteNoteObj = note;
            this.action = "Delete";
            this.confirmBoxNotes = true;
        },
        // delete notes
        async confirmDeleteNote() {
            this.confirmBoxNotes = false;
            this.loading = true;
            await this.REDIS_delete("note", this.deleteNoteObj.entityId, { modifiedUserId: this.GET_currentUserProfile.entityId, modifiedDateTime: this.GET_currentUserProfile.entityId, deletedUserId: this.GET_currentUserProfile.entityId, deletedDateTime: this.GET_currentUserProfile.entityId, deleted: true });
            this.MIX_alertBox({ show: true, message: "Note Deleted", color: "success", timeout: "2000" });
            this.deleteNoteObj = {};
            this.readnotes();
            this.loading = false;
        },
        // edit notes
        async editNotes(note) {
            this.loading = true;
            // console.log("editNote", note );
            note.modifiedUserId = this.GET_currentUserProfile.entityId;
            note.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
            // console.log("editnote=", JSON.stringify(note, null, 2));
            await this.REDIS_update("note", note.entityId, note);
            this.MIX_alertBox({ show: true, message: "Note Saved", color: "success", timeout: "2000" });
            this.loading = false;
        },
        // upate notes prop
        updateNotesProp(value) {
            // console.log("updateNotesProp", value);
            this.noteFormMode = value;
        },
        // save new note
        async saveNewNote(newNote) {
            this.loading = true;
            this.note = { ...this.$schema.note };
            this.note.message = newNote;
            this.note.businessId = this.business.id;
            // this.note.orgName = this.business.title;
            this.note.relatedSection = "business";
            this.note.createdName = this.GET_currentUserProfile.firstName + " " + this.GET_currentUserProfile.lastName;
            this.note.createdUserId = this.GET_currentUserProfile.entityId;
            this.note.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
            this.note.modifiedUserId = this.GET_currentUserProfile.entityId;
            this.note.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
            // console.log("addnote=", this.note);
            await this.REDIS_create("note", this.note);
            this.MIX_alertBox({ show: true, message: "Note Saved", color: "success", timeout: "2000" });
            this.noteFormMode = "read";
            this.readnotes();
            this.loading = false;
        },
        // reads notes
        async readnotes() {
            let noteResult = await this.REDIS_searchFor("note", "", "", "createdDateTime", "desc", `@deleted:{false} @businessId:{${this.business.id}}`);
            this.notes = noteResult.data.documents;
            // console.log("readnotes", this.notes);
        },
        // Add User
        addUser() {
            this.user = { ...this.$schema.user };
            this.action = "Add";
            this.filterDrawer = false;
            this.drawer = true;
        },
        // View Buesiness
        async viewBusiness(item) {
            this.business = { ...item };
            this.filterDrawer = false;
            this.drawer = true;
            this.notes = [];

            // Fetching related Notes
            let noteResult = await this.REDIS_searchFor("note", "", "", "createdDateTime", "desc", `@deleted:{false} @businessId:{${item.id}}`);
            if (noteResult.data.documents.length === 0) {
                this.noteFormMode = "write";
                // console.log("0 notes", this.notes, this.noteFormMode);
            } else {
                this.noteFormMode = "read";
                this.notes = noteResult.data.documents;
                // fields for form
                // console.log("notes", this.notes, this.noteFormMode);
            }
        },
        // Edit business
        editBusiness() {
            console.log("editBusiness");
        },
        // Delete business
        deleteBusiness() {
            console.log("deleteBusiness");
        },
        // Close ORg
        closeUser(user) {
            this.user = { ...user };
            this.drawer = false;
        },
        // Restore business
        restoreBusiness() {
            console.log("restoreBusiness");
        },

        // Confirm Delete User
        async confirmDeleteUser() {
            try {
                this.loading = true;
                this.user.modifiedUserId = this.GET_currentUserProfile.entityId;
                this.user.modifiedOrgId = this.GET_currentUserProfile.orgId;
                this.user.deleted = true;
                this.user.deletedUserId = this.GET_currentUserProfile.entityId;
                this.user.deletedOrgId = this.GET_currentUserProfile.orgId;
                this.user.deletedDateTime = this.MIX_formatDateTimeNow("toISOString");
                await this.REDIS_update("user", this.user.entityId, this.user);
                this.loading = false;
                this.confirmBox = false;
                this.permenantDelete = false;
                this.MIX_alertBox({ show: true, message: "Deleted", color: "success", timeout: "2000" });
                this.getTableData();
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Deleting", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        // Save User
        async saveUser() {
            try {
                this.loading = true;
                this.updateOrgName();
                this.user.modifiedUserId = this.GET_currentUserProfile.entityId;
                this.user.modifiedOrgId = this.GET_currentUserProfile.orgId;
                let validationErrors = null;
                validationErrors = 0;
                if (this.user.firstName === "") {
                    this.validate.firstName = "First Name is required";
                    validationErrors++;
                }
                if (this.user.lastName === "") {
                    this.validate.lastName = "Last Name is required";
                    validationErrors++;
                }
                if (this.user.email === "") {
                    this.validate.email = "Email is required";
                    validationErrors++;
                }
                if (this.user.telephone === "") {
                    this.validate.telephone = "Telephone is required";
                    validationErrors++;
                }
                if (this.user.status === "") {
                    this.validate.status = "Status is required";
                    validationErrors++;
                }
                // loop through the this.user object and trim all the values
                Object.keys(this.user).forEach((key) => {
                    if (typeof this.user[key] === "string") {
                        this.user[key] = this.user[key].trim();
                        // remove any . from the end of the string
                        if (this.user[key].slice(-1) === ".") {
                            this.user[key] = this.user[key].slice(0, -1);
                        }
                    }
                });
                // If no validation errors
                if (this.action === "Add" && validationErrors === 0) {
                    this.user.createdUserId = this.GET_currentUserProfile.entityId;
                    this.user.createdOrgId = this.GET_currentUserProfile.orgId;
                    this.user.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
                    await this.REDIS_create("user", this.user);
                    this.loading = false;
                    this.user = { ...this.$schema.user };
                    this.drawer = false;
                    this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
                    this.getTableData();
                } else if (this.action === "Edit") {
                    await this.REDIS_update("user", this.user.entityId, this.user);
                    this.loading = false;
                    this.user = { ...this.$schema.user };
                    this.drawer = false;
                    this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
                    this.getTableData();
                } else {
                    this.loading = false;
                }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Saving", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        // Get Table Data
        async getTableData() {
            try {
                this.loading = true;
                // console.log('this.itemsFrom: ', this.itemsFrom);
                // console.log('this.itemsCount: ', this.itemsCount);
                // console.log('this.itemsSortBy: ', this.itemsSortBy);
                // console.log('this.itemsSortDesc: ', this.itemsSortDesc);

                let dataResult = await this.REDIS_searchFor("business", this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery);
                // console.log('dataResult: ', JSON.stringify(dataResult, null, 2));
                let tableData = dataResult.data.documents;
                this.itemsTotal = dataResult.data.total;

                // Add isStarred to tableData
                if (this.starredIdsArray.length > 0 && this.starredIdsArray !== undefined) {
                    for (let item of tableData) {
                        item.isStarred = this.starredIdsArray.some((star) => {
                            if (star === item.id) {
                                return true;
                            }
                        });
                    }
                }
                this.tableData = tableData;
                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },
        // Star Items
        async starItem(item) {
            try {
                // console.log("starItem=", item.id, item.isStarred);
                if (item.isStarred) {
                    // if item is already starred then remove it
                    let searchQuery = `@createdUserId:{${this.GET_currentUserProfile.entityId}} @linkedId:{${item.id}} @deleted:{false}`;
                    let contentResult = await this.REDIS_searchFor("stat", "", "", "", "", searchQuery);
                    let statToRemove = contentResult.data.documents[0];
                    // console.log("statToRemove=", statToRemove);
                    await this.REDIS_delete("stat", statToRemove.id, { modifiedUserId: this.GET_currentUserProfile.entityId, modifiedDateTime: this.GET_currentUserProfile.entityId, deletedUserId: this.GET_currentUserProfile.entityId, deletedDateTime: this.GET_currentUserProfile.entityId, deleted: true });
                    // console.log("deletestat=", JSON.stringify(statToRemove, null, 2));
                    await this.getStatData();
                } else {
                    // add item to starred
                    this.stat = { ...this.$schema.stat };
                    // data for db
                    this.stat.id = this.MIX_generateId();
                    (this.stat.type = "star"), (this.stat.category = "business");
                    this.stat.subcategory = item.type;
                    this.stat.linkedId = item.id;
                    this.stat.createdUserId = this.GET_currentUserProfile.entityId;
                    this.stat.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
                    this.stat.modifiedUserId = this.GET_currentUserProfile.entityId;
                    this.stat.modifiedDateTime = this.GET_currentUserProfile.entityId;
                    // console.log("addstat=", JSON.stringify(this.stat, null, 2));
                    await this.REDIS_createWithId("stat", this.stat, this.stat.id);
                    await this.getStatData();
                }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Saving Starred Item", color: "error", timeout: "4000" });
                console.error("error", error);
            } finally {
                // starred and table data
                this.getTableData();
            }
        },
        // Initialise
        async initialise() {
            this.loading = true;
            let sourcesResult = await this.REDIS_searchFor("source", "", "", "name", "asc", "@deleted:{false} @enabled:{true}");
            this.sources = sourcesResult.data.documents;

            // ignored keywords list
            let ignorekeywordlistResult = await this.REDIS_getIgnoreKeywordList();
            this.ignorekeywordlist = ignorekeywordlistResult.data;

            // lookup data
            let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{city|county|region|country|sector|subsector|business_type|business_ownership}");
            this.lookups = lookupResult.data.documents;
            this.sectors = this.lookups.filter((item) => item.type === "sector");
            this.subsectors = this.lookups.filter((item) => item.type === "subsector");
            this.locations = this.lookups.filter((item) => item.type === "city" || item.type === "county" || item.type === "region" || item.type === "country");
            this.regions = this.lookups.filter((item) => item.type === "region");
            this.businessTypes = this.lookups.filter((item) => item.type === "business_type");
            this.businessOwnership = this.lookups.filter((item) => item.type === "business_ownership");

            // starred and table data
            await this.getStatData();
            this.getTableData();
            this.loading = false;
        },
        // Get starred content
        async getStatData() {
            this.loading = true;
            let statData = [];
            let searchQuery = `@type:{star} @category:{business} @deleted:{false} @createdUserId:{${this.GET_currentUserProfile.entityId}}` + this.statSearchQuery;
            // console.log("searchQuery=", this.statSearchQuery);
            statData = await this.REDIS_searchFor("stat", "", "", "createdDateTime", "desc", searchQuery);
            this.starredIdsArray = statData.data.documents.map((item) => item.linkedId);
            // console.log("this.starredIdsArray=", this.starredIdsArray);
            if (this.starredIdsArray.length > 0 && this.starredIdsArray !== undefined) {
                this.idsArr = this.starredIdsArray.filter((item) => typeof item === "string");
            } else {
                this.idsArr = [];
            }
            this.loading = false;
        },
        // Update Table Options
        updateTableOptions(options) {
            this.tableOptions = options;
        },
        // Get Organisations
        async getOrganisations() {
            try {
                this.loading = true;
                let query = `@deleted:{false} @type:{${this.user.orgType}}`;
                if (this.user.orgType !== "") {
                    let organisationResult = await this.REDIS_searchFor("org", "", "", "name", "asc", query);
                    this.organisations = organisationResult.data.documents;
                } else {
                    this.organisations = [];
                }
                this.loading = false;
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Getting Organisations", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        updateOrgName() {
            if (this.user.orgId !== "") {
                this.user.orgName = this.organisations.find((item) => item.entityId === this.user.orgId).name;
                return;
            }
        },
        updateStatus(status) {
            this.user.status = status;
            this.saveUser();
        },
        toggleFilters() {
            // if (this.drawer) {
            //     this.drawer = false;
            // }
            this.filterDrawer = !this.filterDrawer;
        },
        next() {
            if (this.tableOptions.page * this.tableOptions.itemsPerPage < this.itemsTotal) {
                this.tableOptions.page++;
            }
        },
        previous() {
            if (this.tableOptions.page > 1) {
                this.tableOptions.page--;
            }
        },
        alertme() {
            alert("Testing");
        },
        filterIgnoreList(keywords) {
            // if any of the keywords are in the ignoreList then filter them out
            const result = [];
            for (let i = 0; i < keywords.length; i++) {
                if (!this.ignorekeywordlist.includes(keywords[i])) {
                    result.push(keywords[i]);
                }
            }
            return result;
        },
        async addIgnoreKeyword(keyword) {
            try {
                await this.REDIS_addIgnoreKeyword(keyword);
                this.MIX_alertBox({ show: true, message: "Keyword Added to Ignore List", color: "success", timeout: "2000" });
                let ignorekeywordlistResult = await this.REDIS_getIgnoreKeywordList();
                this.ignorekeywordlist = ignorekeywordlistResult.data;
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Adding Keyword", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        lookupSector(sectorvalue) {
            if (sectorvalue !== "") {
                return this.sectors.find((item) => item.value === sectorvalue).text;
            }
        },
        lookupSubsector(subsectorvalue) {
            if (subsectorvalue !== "") {
                return this.subsectors.find((item) => item.value === subsectorvalue).text;
            }
        },
        lookupValue(value) {
            if (value !== "") {
                let lookup = this.lookups.find((item) => item.value === value);
                if (lookup !== undefined) {
                    return lookup.text;
                } else {
                    return "";
                }
            }
        },
    },
    created() {
        this.initialise();
    },
};
</script>

<style scoped>
.leftside-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness */
    z-index: 99; /* Specify a stack order in case you're using a different order for other elements */
}

.d-flex {
    gap: 15px;
}

.business-cards-container {
    display: flex;
    flex-wrap: wrap;
}

.business-card-component {
    height: 280px;
}

.business-cards {
    flex-basis: calc(100% / 4);
   /* max-width: 420px; */
    min-height: 230px; /* Default height for larger screens */
    padding: 10px;
    box-sizing: border-box;
}

@media screen and (max-width: 1100px) {
    /* Adjust styles for smaller screens */
    .business-cards {
        flex-basis: calc(100% / 3); /* Displays 2 cards per row */
        min-height: 230px; /* Height for small screens */
    }
    .business-card-component {
        height: 268px;
    }
}

@media screen and (max-width: 860px) {
    /* Adjust styles for smaller screens */
    .business-cards {
        flex-basis: calc(100% / 2); /* Displays 2 cards per row */
        min-height: 230px; /* Height for small screens */
    }
    .business-card-component {
        height: 268px;
    }
}

@media screen and (max-width: 480px) {
    /* Adjust styles for extra-small screens */
    .business-cards {
        flex-basis: 100%; /* Displays 1 card per row */
    }

    .business-card-component {
        height: 260px;
    }
}
</style>
