<template>
    <div>
        <v-data-table :headers="tableheaders" :items="tabledata" :options.sync="tableOptions" :footer-props="{ 'items-per-page-options': [5, 10, 15, 25, 50, 100] }" class="elevation-0 rounded-of" :server-items-length="itemstotal">
            <template v-slot:top="{ pagination, updateOptions }">
                <v-data-footer :pagination="pagination" :options="tableOptions" @update:options="updateOptions" :items-per-page-options="[5, 10, 15, 25, 50, 100]" items-per-page-text="$vuetify.dataTable.itemsPerPageText" />
            </template>
            <!-- Name -->
            <template #[`item.name`]="{ item }">
                <div class="font-weight-bold">{{ item.name }}</div>
            </template>
            <template #[`item.matchCount`]="{ item }">
                <div @click="viewMatch(item)" :class="item.matchCount > 0 ? 'success--text' : 'grey--text'" class="font-weight-bold">{{ item.matchCount }}</div>
            </template>
            <!-- Email -->
            <template #[`item.email`]="{ item }">
                <a class="text-decoration-none" :href="`mailto:${item.email}`">{{ item.email }}</a>
            </template>
            <!-- Website -->
            <template #[`item.website`]="{ item }">
                <a class="text-decoration-none" :href="`${item.website}`" target="_blank">{{ item.website }}</a>
            </template>
            <!-- Status -->
            <template #[`item.status`]="{ item }">
                <v-icon title="Approved" class="success--text font-weight-bold" v-if="item.status === 'approved'">icons8-filled-circle</v-icon>
                <v-icon title="Pending" class="warning--text font-weight-bold" v-if="item.status === 'pending'">icons8-filled-circle</v-icon>
                <v-icon title="Suspended" class="info--text font-weight-bold" v-if="item.status === 'suspended'">icons8-filled-circle</v-icon>
                <v-icon title="Rejected" class="error--text font-weight-bold" v-if="item.status === 'rejected'">icons8-filled-circle</v-icon>
                <v-icon title="Achived" class="grey--text font-weight-bold" v-if="item.status === 'archived'">icons8-filled-circle</v-icon>
                <v-icon title="Up" class="success--text font-weight-bold" v-if="item.status === 'up'">icons8-filled-circle</v-icon>
                <v-icon title="Down" class="error--text font-weight-bold" v-if="item.status === 'down'">icons8-filled-circle</v-icon>
                <v-icon title="Active" class="success--text font-weight-bold" v-if="item.status === 'active'">icons8-filled-circle</v-icon>
            </template>
            <template #[`item.action`]="{ item }">
                <div class="d-flex align-end justify-start">
                    <v-icon title="Restore" v-if="item.deleted && actions.includes('Restore')" @click="restoreItem(item)">icons8-checked-checkbox</v-icon>
                    <v-icon class="error--text" title="Delete" v-if="!item.deleted && actions.includes('Edit') && enabledelete" @click="deleteItem(item)" size="24">icons8-close-window</v-icon>
                    <v-icon title="Edit" class="primary--text" v-if="!item.deleted && actions.includes('Edit')" @click="editItem(item)" size="24">icons8-right-button</v-icon>
                    <v-icon class="primary--text" title="View" v-if="!item.deleted && actions.includes('View')" @click="viewItem(item)">icons8-info-squared</v-icon>
                    <v-icon title="Star" v-if="(!item.deleted && (actions.includes('View') || actions.includes('Edit')) && custom === 'business') || custom === 'orgs'" @click="starItem(item)" :class="item.isStarred ? 'gold--text' : 'primary--text'" size="24">icons8-rating</v-icon>
                    <v-checkbox v-if="enablematch && !item.deleted" v-model="selectedBusinesses[item.id]" color="primary" :value="item" hide-details @click.stop="emitCheckboxValue()" small></v-checkbox>
                </div>
                <!-- <v-icon title="Star" v-if="!item.deleted && actions.includes('Star')" @click="starItem(item)">icons8-star</v-icon> -->
            </template>
            <template #[`item.enabled`]="{ item }"> <span v-if="item.enabled">Yes</span><span v-else>No</span> </template>
            <!-- Custom -->
            <template #[`item.listingDateTime`]="{ item }" v-if="custom === 'business'">
                <span>{{ MIX_formatDateTime(item.listingDateTime, "ISOString", "DD MMM YY") }}</span>
            </template>

            <template #[`item.statusDate`]="{ item }" v-if="custom === 'source'">
                <v-icon title="Up" class="success--text font-weight-bold" v-if="MIX_returnDateTimeFromNow(item.lastFeedDateTime, 'ISOString', 'Days') < 2">icons8-filled-circle</v-icon>
                <v-icon title="Down" class="error--text font-weight-bold" v-if="MIX_returnDateTimeFromNow(item.lastFeedDateTime, 'ISOString', 'Days') >= 5">icons8-filled-circle</v-icon>
                <v-icon title="Warning" class="warning--text font-weight-bold" v-if="MIX_returnDateTimeFromNow(item.lastFeedDateTime, 'ISOString', 'Days') >= 2 && MIX_returnDateTimeFromNow(item.lastFeedDateTime, 'ISOString', 'Days') < 5">icons8-filled-circle</v-icon>
            </template>

            <template #[`item.lastFeedDateTime`]="{ item }" v-if="custom === 'source'">
                <span>{{ MIX_formatDateTime(item.lastFeedDateTime, "ISOString", "DD MMM YY @ HH:mm") }}</span>
                | <span>{{ MIX_formatDateTimeFromNow(item.lastFeedDateTime, "ISOString", "Days") }}</span>
            </template>
            <template #[`item.turnover`]="{ item }" v-if="custom === 'business'">
                <span v-if="item.turnover !== 0">{{ MIX_formatCurrency(item.turnover, 0) }}</span
                ><span v-else>-</span>
            </template>
            <template #[`item.price`]="{ item }" v-if="custom === 'business'">
                <span v-if="item.price !== 0">{{ MIX_formatCurrency(item.price, 0) }}</span
                ><span v-else>-</span>
            </template>
            <template #[`item.profit`]="{ item }" v-if="custom === 'business'">
                <span v-if="item.profit !== 0">{{ MIX_formatCurrency(item.profit, 0) }}</span
                ><span v-else>-</span>
            </template>
            <template #[`item.ebitda`]="{ item }" v-if="custom === 'business'">
                <span v-if="item.ebitda !== 0">{{ MIX_formatCurrency(item.ebitda, 0) }}</span
                ><span v-else>-</span>
            </template>
            <template #[`item.lastSeenDateTime`]="{ item }" v-if="custom === 'business'">
                <span class="success--text" v-if="MIX_formatDateTimeFromNow(item.lastSeenDateTime, 'ISOString', 'Days') === 'Today'">{{ MIX_formatDateTimeFromNow(item.lastSeenDateTime, "ISOString", "Days") }}</span>
                <span v-else class="warning--text">{{ MIX_formatDateTimeFromNow(item.lastSeenDateTime, "ISOString", "Days") }}</span>
                <!-- <span>{{ MIX_formatDateTime(item.lastSeenDateTime, "ISOString", "DD MMM YY @ HH:mm") }}</span> -->
            </template>
            <template #[`item.level`]="{ item }" v-if="custom === 'users'">
                {{ lookupValue(item.level) }}
            </template>
            <template #[`item.location`]="{ item }" v-if="custom === 'business'">
                {{ item.location.map((location) => lookupValue(location)).join(",") }}
            </template>
            <!-- <template #[`item.type`]="{ item }" v-if="custom === 'orgs'">
				{{ item.type.map(type => lookupValue(type)).join(',') }}
			</template> -->
            <!-- <template #[`item.engaged`]="{ item }" v-if="custom === 'orgs'">
				{{ lookupValue(item.engaged) }}
			</template> -->
            <template #[`item.severity`]="{ item }" v-if="custom === 'logs'">
                <!-- {{ lookupValue(item.severity) }} -->
                <v-icon title="medium" class="warning--text font-weight-bold" v-if="item.severity === 'medium'">icons8-filled-circle</v-icon>
                <v-icon title="high" class="error--text font-weight-bold" v-if="item.severity === 'high'">icons8-filled-circle</v-icon>
                <v-icon title="low" class="grey--text font-weight-bold" v-if="item.severity === 'low'">icons8-filled-circle</v-icon>
            </template>
            <template #[`item.logLevel`]="{ item }" v-if="custom === 'logs'">
                {{ lookupValue(item.level) }}
            </template>
            <template #[`item.createdDateTime`]="{ item }" v-if="custom === 'logs'">
                <span>{{ MIX_formatDateTime(item.createdDateTime, "ISOString", "DD MMM YY @ HH:mm") }}</span>
                | <span>{{ MIX_formatDateTimeFromNow(item.createdDateTime, "ISOString", "Days") }}</span>
            </template>
        </v-data-table>
        <CopyrightBanner />
    </div>
</template>
<script>
export default {
    // props
    props: {
        tableheaders: {
            type: Array,
            default: () => [],
        },
        tabledata: {
            type: Array,
            default: () => [],
        },
        datatableoptions: {
            type: Object,
            default: () => ({}),
        },
        itemstotal: {
            type: Number,
            default: 0,
        },
        custom: {
            type: String,
            default: "",
        },
        dense: {
            type: Boolean,
            default: false,
        },
        enabledelete: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        actions: {
            type: Array,
            default: () => ["Edit", "Delete"],
        },
        lookups: {
            type: Array,
            default: () => [],
        },
        matches: {
            type: Number,
            default: 0,
        },
        enablematch: {
            type: Boolean,
            default: false,
        },
    },
    // data
    data: () => ({
        tableOptions: {},
        selectedBusinesses: {},
    }),
    // computed: {
    // 	calcTableHeight() {
    // 		let tableHeight = window.innerHeight - 420;
    // 		if (tableHeight < 300) {
    // 			tableHeight = 300;
    // 		}
    // 		return tableHeight;
    // 	},
    // },
    // methods
    methods: {
        emitCheckboxValue() {
            this.$emit("checkbox-changed", this.selectedBusinesses);
        },
        restoreItem(item) {
            this.$emit("restore", item);
        },
        editItem(item) {
            this.$emit("edit", item);
        },
        deleteItem(item) {
            this.$emit("delete", item);
        },
        viewItem(item) {
            this.$emit("view", item);
        },
        viewMatch(item) {
            this.$emit("viewmatch", item);
        },
        starItem(item) {
            this.$emit("star", item);
        },
        lookupValue(value) {
            if (value !== "") {
                let lookup = this.lookups.find((item) => item.value === value);
                if (lookup !== undefined) {
                    return lookup.text;
                } else {
                    return "";
                }
            }
        },
    },
    created() {
        this.tableOptions = this.datatableoptions;
    },
    watch: {
        datatableoptions: {
            handler: function (val) {
                this.tableOptions = val;
            },
            deep: true,
        },
        tableOptions: {
            handler: function (val) {
                this.$emit("tableoptions", val);
            },
            deep: true,
        },
    },
};
</script>
