import { mapGetters } from "vuex";

const mixin = {
	data: () => ({
		MIX_nav: [
			// Project Nav (Admin)
			{
				title: "Home",
				path: "/",
				icon: "icons8-home",
				orgType: [""],
				level: ["staff","manager", "director", "administrator"],
				status: ["approved"],
				enabled: true,
				nav: ["SideNav", "BottomNav"],
			},
			{
				title: "Marketplace",
				path: "/business",
				icon: "icons8-marketplace",
				orgType: [""],
				level: ["staff","manager", "director", "administrator"],
				status: ["approved"],
				enabled: true,
				nav: ["SideNav", "BottomNav"],
			},		
			{
				title: "Clients",
				path: "/clients",
				icon: "icons8-business-building",
				orgType: [""],
				level: ["staff","manager", "director", "administrator"],
				status: ["approved"],
				enabled: true,
				nav: ["SideNav", "BottomNav"],
			},	
			{
				title: "Users",
				path: "/users/",
				icon: "icons8-staff",
				orgType: [""],
				level: ["administrator"],
				status: ["approved"],
				enabled: true,
				nav: [],
			},
			{
				title: "Lookups",
				path: "/admin/",
				icon: "icons8-list",
				orgType: [""],
				level: ["administrator"],
				status: ["approved"],
				enabled: true,
				nav: [],
			},	
			{
				title: "Sources",
				path: "/sources",
				icon: "icons8-database",
				orgType: [""],
				level: ["administrator"],
				status: ["approved"],
				enabled: true,
				nav: [],
			},	
			{
				title: "Logs",
				path: "/logs",
				icon: "icons8-notes",
				orgType: [""],
				level: ["administrator"],
				status: ["approved"],
				enabled: true,
				nav: [],
			},					
			{
				title: "Admin",
				path: "/admin",
				icon: "icons8-admin",
				orgType: [""],
				level: ["administrator"],
				status: ["approved"],
				enabled: true,
				nav: ["SideNav", "BottomNav"],
			},			
			
		],
	}),
	computed: {
		...mapGetters({
			GET_currentUserProfile: "GET_currentUserProfile",
			GET_userAuthenticated: "GET_userAuthenticated",
			
		}),
	},
	methods: {
		MIX_filteredNav(nav) {
			// console.log("GET_currentUserProfile: " + this.GET_currentUserProfile),
			// console.log("GET_userAuthenticated: " + this.GET_userAuthenticated)
			if (this.GET_userAuthenticated) {
				return this.MIX_nav.filter((item) => {
					return item.orgType.includes(this.GET_currentUserProfile.orgType) && item.nav.includes(nav);
				});
			} else {
				return [];
			}
		},
		// CHeck User Level and Status
		MIX_checkLevelAndStatus(item) {
			if (item.enabled) {
				if (this.GET_userAuthenticated) {
					if (item.status !== undefined && item.level !== undefined) {
						let checkError = 0;
						if (!item.status.includes(this.GET_currentUserProfile.status)) {
							checkError++;
						}
						if (!item.level.includes(this.GET_currentUserProfile.level)) {
							checkError++;
						}
						if (checkError === 0) {
							return false;
						} else {
							return true;
						}
					} else {
						return true;
					}
				}
			} else {
				return true;
			}
		},
	},
};

export default {
	install(Vue) {
		Vue.mixin(mixin);
	},
};
