<template>
    <div>
        <div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
          <div
      v-if="filterDrawer || drawer"
      class="leftside-overlay"
        @click="filterDrawer = false, drawer = false"
    ></div>
        <v-row dense class="px-5 pt-5">
            <v-col cols="12">
                <div class="d-flex align-center">
                    <div>
                        <div class="text-h4 primary--text d-flex">
                            Users
                            <v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
                        </div>
                    </div>
                    <v-spacer />
                    <!-- Search -->
                    <SearchField v-if="$vuetify.breakpoint.width > 1500 || ($vuetify.breakpoint.width > 600 && !filterDrawer)" label="Search Users" v-model="search" :dense="true" />
                    <AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>
                    <AppButton @click.native="getTableData"><v-icon>icons8-synchronize</v-icon></AppButton>
                    <!-- TODO - Invite Button required -->
                    <!-- TODO - Add User needs to work with Firebase Authentication -->
                    <!-- <AppButton :disabled="drawer" buttonclass="secondary" @click.native="addUser">Add User</AppButton> -->
                    <div class="white--text">
                        <DropdownMenu>
                            <div class="d-flex align-center">
                                <v-switch color="secondary" class="" inset dense v-model="filter.showDeleted" />
                                <div class="font-weight-bold secondary--text">Show Deleted</div>
                            </div>
                        </DropdownMenu>
                    </div>
                </div>
                <div class="text--secondary pt-4" v-if="showInfo">The following page shows all users that have access to the App/Portal, you can use this page to Manage users and access.</div>
            </v-col>
            <!-- <v-col cols="12" v-if="JSON.stringify(tableData) !== '[]'" class="body-2"> Found {{ itemsTotal }} vet(s) </v-col> -->
            <!-- Table -->
            <v-col cols="12" class="pt-2">
                <!-- Data Table -->
                <DataTable :tableheaders="filteredHeaders" :loading="loading" custom="users" :tabledata="tableData" :datatableoptions="tableOptions" :itemstotal="itemsTotal" v-on:delete="deleteUser" v-on:edit="editUser" v-on:restore="restoreUser" v-on:tableoptions="updateTableOptions" :lookups="lookups" />
            </v-col>
            <!-- Filter Drawer -->
            <v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="filterDrawer" absolute clipped right style="z-index: 999;">
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex align-center pt-4">
                            <div class="text-h5 secondary--text">Filters</div>
                            <v-spacer />
                            <v-btn icon depressed @click="filterDrawer = false"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        Use the filters below to narrow your search.
                    </v-col>
                    <v-col cols="12">
                        <SearchField v-if="$vuetify.breakpoint.width <= 1500 && filterDrawer" label="Search Clients" v-model="search" :dense="true" />
                        <!-- Status -->
                        <div class="flex-grow-1">
                            <div class="my-2 text-h6 primary--text">Account Status</div>
                            <div class="d-flex align-center pb-4">
                                <!-- Status -->
                                <v-btn-toggle v-model="filter.status" multiple class="" style="flex-direction: column !important; width: 100% !important">
                                    <!-- <v-btn title="Approved" value="approved"><v-icon class="success--text">icons8-100-</v-icon><span v-if="!drawer && $vuetify.breakpoint.lgAndUp" class="ml-2">Approved</span></v-btn> -->
                                    <v-btn title="Approved" value="approved"><v-icon class="success--text">icons8-100-</v-icon><span class="ml-2">Approved</span></v-btn>
                                    <v-btn title="Pending" value="pending"><v-icon class="warning--text">icons8-100-</v-icon><span class="ml-2">Pending</span></v-btn>
                                    <v-btn title="Rejected" value="rejected"><v-icon class="error--text">icons8-100-</v-icon><span class="ml-2">Rejected</span></v-btn>
                                    <v-btn title="Suspended" value="suspended"><v-icon class="info--text">icons8-100-</v-icon><span class="ml-2">Suspended</span></v-btn>
                                    <v-btn title="Archived" value="archived"><v-icon class="grey--text">icons8-100-</v-icon><span class="ml-2">Archived</span></v-btn>
                                </v-btn-toggle>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-navigation-drawer>
            <!-- Edit Window -->
            <v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="drawer" absolute clipped right style="z-index: 999;">
                <!-- Practice Details -->
                <v-row dense class="mt-5">
                    <v-col cols="12">
                        <div class="d-flex align-center pb-2">
                            <div class="text-h5 secondary--text">{{ action }} User</div>
                            <v-spacer />
                            <v-btn icon depressed @click="closeUser"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        Use the form below to edit the User's details
                    </v-col>

                    <v-col cols="12" class="d-flex align-center" v-if="action === 'Edit'">
                        <div v-if="user.status === 'pending'" class="flex-grow-1"><AppButton block buttonclass="success" @click.native="updateStatus('approved')">Approve</AppButton></div>
                        <div v-if="user.status === 'pending'" class="flex-grow-1"><AppButton block buttonclass="error" @click.native="updateStatus('rejected')">Reject</AppButton></div>
                        <div v-if="user.status === 'approved'" class="flex-grow-1"><AppButton block buttonclass="info" @click.native="updateStatus('suspended')">Suspend</AppButton></div>
                        <div v-if="user.status === 'approved'" class="flex-grow-1"><AppButton block buttonclass="grey white--text" @click.native="updateStatus('archived')">Archive</AppButton></div>
                        <div v-if="user.status === 'suspended' || user.status === 'archived'" class="flex-grow-1"><AppButton block buttonclass="success" @click.native="updateStatus('approved')">Restore</AppButton></div>
                    </v-col>
                    <v-col cols="12"> <div class="mt-2 text-h6 primary--text">User Details</div> </v-col>
                    <v-col cols="6"> <TextField backgroundcolor="white" label="First Name *" v-model="user.firstName" :validate="validate.firstName" /> </v-col>
                    <v-col cols="6"> <TextField backgroundcolor="white" label="Last Name *" v-model="user.lastName" :validate="validate.lastName" /> </v-col>
                    <v-col cols="12"> <TextField backgroundcolor="white" label="Telephone *" v-model="user.telephone" :validate="validate.telephone" :hint="'Telephone number must be at least 7 numbers long'"/> </v-col>
                    <v-col cols="12"> <TextField :disabled="true" backgroundcolor="white" label="Email *" v-model="user.email" :validate="validate.email" /> </v-col>
                </v-row>
                <!-- User Organisation Details -->
                <!-- TODO - User can be assigned to multiple organisations -->
                <v-row dense class="mt-5">
                    <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Organisation Details</div> </v-col>
                    <v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Organisation Type *" v-model="user.orgType" :items="orgTypes" /> </v-col>
                    <v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Organisation *" @change="updateOrgName()" v-model="user.orgId" :items="organisations" itemtext="name" itemvalue="entityId" /> </v-col>
                </v-row>
                <!-- User Security Details -->
                <v-row dense class="mt-5">
                    <v-col cols="12"> <div class="mt-2 text-h6 primary--text">App Security Details</div> </v-col>
                    <!-- <v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Group" v-model="user.group" :items="userGroups" :validate="validate.group" /> </v-col> -->
                    <v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="User Level" v-model="user.level" :items="userLevels" :validate="validate.level" /> </v-col>
                    <v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Status" v-model="user.status" :items="userStatus" :validate="validate.status" /> </v-col>
                </v-row>
                <!-- User Other -->
                <v-row dense class="mt-5">
                    <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Other</div> </v-col>
                    <v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Language" v-model="user.language" :items="userLanguages" :validate="validate.language" /> </v-col>
                    <v-col cols="12"><CheckboxField v-model="user.termsAgreed" disabled background="white"> Terms & Conditions (Not Editable)</CheckboxField> </v-col>
                    <v-col cols="12">
                        <CheckboxField v-model="user.optInAgreed" disabled background="white"> Communication Opt-in (Not Editable)</CheckboxField>
                    </v-col>
                </v-row>
                <!-- <pre>{{ user }}</pre> -->
                <!-- Action Buttons -->
                <v-row dense class="mt-5">
                    <v-col cols="6">
                        <div><AppButton block buttonclass="grey" @click.native="closeUser">Cancel</AppButton></div>
                    </v-col>
                    <v-col cols="6">
                        <div><AppButton block buttonclass="secondary" @click.native="saveUser">Save</AppButton></div>
                    </v-col>
                </v-row>
            </v-navigation-drawer>
            <!-- Confirmation Box -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false" style="z-index: 999;" >
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirm you want to delete this user</p>
                <strong>{{ user.name }}</strong
                ><br />
                <!-- permenantDelete : {{ permenantDelete }}<br /> -->
                <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeleteUser">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
        <!--Dialog-->
        <v-dialog style="z-index: 3000 !important" v-model="awaitingApproval" persistent max-width="512" >
            <v-card class="white">
                <v-card-title class="primary white--text">Awaiting Approval</v-card-title>
                <v-card-text class="mt-4">
                    <div v-if="user.status == 'approved'" category="text-medium">You are about to approve the account for {{ user.firstName }}. This will grant them access to the app, and also set the permissions they will have for the role they're assigned to.</div>
                    <div v-else category="text-medium">You are about to change the account status for {{ user.firstName }}. This will deny them access to the app so they cannot log in.</div>
                    <div v-if="user.status == 'approved'" category="text-medium-bold" class="mt-4">Please choose a level from the list below.</div>
                    <div v-else category="text-medium-bold" class="mt-4">Are you sure you want to do this?</div>
                    <!--User role-->
                    <v-col v-if="user.status == 'approved'" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <DropdownAdvanced input-type="select" :class="{ 'px-1 mt-4': $vuetify.breakpoint.smAndUp, 'mt-4': $vuetify.breakpoint.xsOnly }" :items="userLevels" v-model="user.level" label="User Level">
                            <template v-slot:item="data">
                                <div category="text-default">{{ data.item }}</div>
                            </template>
                        </DropdownAdvanced>
                    </v-col>
                </v-card-text>
                <v-card-actions class="text-center">
                    <!--Dialog Actions-->
                    <v-row class="ma-0 pa-0" justify="center">
                        <v-col cols="6">
                            <AppButton @click.native="noAction()" buttonclass="primary white--text" block>No</AppButton>
                        </v-col>
                        <v-col cols="6">
                            <AppButton block @click.native="yesAction()" buttonclass="green white--text">{{ user.status === "approved" ? "Save" : "Yes" }}</AppButton>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "VetsAdmin",
    // data
    data: () => ({
        loading: false,
        drawer: false,
        filterDrawer: false,
        confirmBox: false,
        awaitingApproval: false,
        permenantDelete: false,
        search: "",
        filter: {
            status: ["approved", "pending"],
            showDeleted: false,
        },
        tableData: [],
        itemsTotal: 0,
        tableHeaders: [
            { text: "Status", value: "status", align: "start", hidden: false, sortable: false, shrunk: true },
            { text: "ID", value: "entityId", align: "start", hidden: true, sortable: false, shrunk: true },
            { text: "First Name", value: "firstName", align: "start", hidden: false, sortable: false, shrunk: true },
            { text: "Last Name", value: "lastName", align: "start", hidden: false, sortable: false, shrunk: true },
            { text: "Job Title", value: "jobRole", align: "start", hidden: false, sortable: false, shrunk: false },
            { text: "Telephone", value: "telephone", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "Email", value: "email", align: "start", hidden: false, sortable: false, shrunk: false },
            // { text: "Group", value: "group", align: "start", hidden: false, sortable: false, shrunk: true },
            { text: "Org Id", value: "orgId", align: "start", hidden: true, sortable: false, shrunk: true },
            { text: "Organisation", value: "orgName", align: "start", hidden: true, sortable: false, shrunk: true },
            { text: "Organisation Type", value: "orgType", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "User Level", value: "level", align: "start", hidden: false, sortable: false, shrunk: true },
            { text: "Terms Agreed", value: "termsAgreed", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "Opt In Agreed", value: "optInAgreed", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "Language", value: "language", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "createdUserId", value: "createdUserId", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "createdOrgId", value: "createdOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "createdDateTime", value: "createdDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "modifiedUserId", value: "modifiedUserId", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "modifedOrgId", value: "modifedOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "modifiedDateTime", value: "modifiedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "deletedUserId", value: "deletedUserId", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "deletedOrgId", value: "deletedOrgId", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "deletedDateTime", value: "deletedDateTime", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "deleted", value: "deleted", align: "start", hidden: true, sortable: false, shrunk: false },
            { text: "", value: "action", align: "center", hidden: false, sortable: false, width: "110px", shrunk: true },
        ],
        tableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["firstName"],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        validate: {},
        user: {},
        organisations: [],
        orgTypes: [],
        userStatus: [],
        userGroups: [],
        userLevels: [],
        userLanguages: [],
        action: "Add",
        showInfo: false,
        lookups: [],
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
        filteredHeaders() {
            let headers = this.tableHeaders.filter((header) => !header.hidden);
            if (this.drawer) {
                headers = headers.filter((header) => header.shrunk);
            }
            // console.log("headers", headers)

            return headers;
        },
        filterQuery() {
            let query = "";
            if (this.search !== "" && this.search !== null && this.search !== undefined && this.search.length > 1) {
                query += `${this.search}*`;
            }
            if (this.filter.showDeleted) {
                query += ` @deleted:{true}`;
            } else {
                query += `@deleted:{false}`;
            }
            // Filter Status
            if (JSON.stringify(this.filter.status) !== "[]") {
                query += ` @status:{${this.filter.status.join("|")}}`;
            }
            return query;
        },
        itemsFrom() {
            return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
        },
        itemsTo() {
            if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
                return this.itemsTotal;
            } else {
                return this.tableOptions.page * this.tableOptions.itemsPerPage;
            }
        },
        itemsCount() {
            return this.tableOptions.itemsPerPage;
        },
        itemsSortBy() {
            let sortBy = this.tableOptions.sortBy;
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => x);
                sortBy = sortBy.join(",");
            }
            return sortBy;
        },
        itemsSortDesc() {
            let sortDesc = this.tableOptions.sortDesc[0];
            if (sortDesc) {
                sortDesc = "DESC";
            } else {
                sortDesc = "ASC";
            }
            return sortDesc;
        },
    },
    // watch
    watch: {
        filterQuery: {
            handler() {
                this.tableOptions.page = 1;
                this.getTableData();
            },
            deep: true,
        },
        tableOptions: {
            handler() {
                this.getTableData();
            },
            deep: true,
        },
        user: {
            handler() {
                // Update the selected value and yourParameter
                if (this.user.status === "approved" && this.user.level === "guest") {
                    this.awaitingApproval = true;
                }
                // else if (this.user.status === "Rejected" || this.user.status === "Suspended" || this.user.status === "Archived") {
                // 	this.awaitingApproval = true;
                // }
            },
            deep: true,
        },
    },
    // methods
    methods: {
        // sets user level to guest if the dialogue is closed by selecting No
        noAction() {
            // console.log("noAction");
            this.awaitingApproval = false;
            this.user.level = "guest";
        },

        // closes the dialogue when selected yes
        yesAction() {
            // console.log("yesAction");
            this.awaitingApproval = false;
        },
        // Get Table Data
        async getTableData() {
            try {
                this.loading = true;
                let dataResult = await this.REDIS_searchFor("user", this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery);
                this.tableData = dataResult.data.documents;
                this.itemsTotal = dataResult.data.total;
                // console.log("this.tableData", this.tableData)
                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },
        // Add User
        addUser() {
            this.user = { ...this.$schema.user };
            this.action = "Add";
            this.filterDrawer = false;
            this.drawer = true;
        },
        // Edit User
        editUser(user) {
            this.user = { ...user };
            this.action = "Edit";
            this.getOrganisations();
            this.filterDrawer = false;
            this.drawer = true;
        },
        // Delete User
        deleteUser(user) {
            this.user = { ...user };
            this.action = "Delete";
            this.confirmBox = true;
        },
        // Close ORg
        closeUser(user) {
            this.user = { ...user };
            this.drawer = false;
        },
        // Restore User
        async restoreUser(user) {
            try {
                this.loading = true;
                this.user = { ...user };
                this.user.modifiedUserId = this.GET_currentUserProfile.entityId;
                this.user.modifiedOrgId = this.GET_currentUserProfile.orgId;
                this.user.deleted = false;
                this.user.deletedUserId = null;
                this.user.deletedOrgId = null;
                this.user.deletedDateTime = null;
                await this.REDIS_update("user", this.user.entityId, this.user);
                this.loading = false;
                this.confirmBox = false;
                this.permenantDelete = false;
                this.MIX_alertBox({ show: true, message: "Restored", color: "success", timeout: "2000" });
                this.getTableData();
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Restoring", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        // Confirm Delete User
        async confirmDeleteUser() {
            try {
                this.loading = true;
                this.user.modifiedUserId = this.GET_currentUserProfile.entityId;
                this.user.modifiedOrgId = this.GET_currentUserProfile.orgId;
                this.user.deleted = true;
                this.user.deletedUserId = this.GET_currentUserProfile.entityId;
                this.user.deletedOrgId = this.GET_currentUserProfile.orgId;
                this.user.deletedDateTime = this.MIX_formatDateTimeNow("toISOString");
                await this.REDIS_update("user", this.user.entityId, this.user);
                this.loading = false;
                this.confirmBox = false;
                this.permenantDelete = false;
                this.MIX_alertBox({ show: true, message: "Deleted", color: "success", timeout: "2000" });
                this.getTableData();
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Deleting", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        // Save User
        async saveUser() {
            try {
                this.loading = true;
                this.updateOrgName();
                this.user.modifiedUserId = this.GET_currentUserProfile.entityId;
                this.user.modifiedOrgId = this.GET_currentUserProfile.orgId;
                let validationErrors = null;
                validationErrors = 0;
                if (this.user.firstName === "") {
                    this.validate.firstName = "First Name is required";
                    validationErrors++;
                }
                if (this.user.lastName === "") {
                    this.validate.lastName = "Last Name is required";
                    validationErrors++;
                }
                if (this.user.email === "") {
                    this.validate.email = "Email is required";
                    validationErrors++;
                }
                this.validate.telephone = "";
                if (this.user.telephone === "" || this.user.telephone.length < 7) {
                    this.validate.telephone = "Please enter a minimum of 7 digits";
                    validationErrors++;
                } 
                 if (!/^\+?\d{1,}$/g.test(this.user.telephone)) {
                    this.validate.telephone = "Telephone must be valid";
                    validationErrors++;
                } 
                if (this.user.status === "") {
                    this.validate.status = "Status is required";
                    validationErrors++;
                }
                if (this.user.level === "" || this.user.level === null || this.user.level === "guest") {
                    this.validate.level = "Level is required";
                    validationErrors++;
                }
                // console.log(this.user.level)
                // loop through the this.user object and trim all the values
                Object.keys(this.user).forEach((key) => {
                    if (typeof this.user[key] === "string") {
                        this.user[key] = this.user[key].trim();
                        // remove any . from the end of the string
                        if (this.user[key].slice(-1) === ".") {
                            this.user[key] = this.user[key].slice(0, -1);
                        }
                    }
                });
                // If no validation errors
                if (this.action === "Add" && validationErrors === 0) {
                    this.user.createdUserId = this.GET_currentUserProfile.entityId;
                    this.user.createdOrgId = this.GET_currentUserProfile.orgId;
                    this.user.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
                    await this.REDIS_create("user", this.user);
                    this.loading = false;
                    this.user = { ...this.$schema.user };
                    this.drawer = false;
                    this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
                    this.getTableData();
                } else if (this.action === "Edit" && validationErrors === 0) {
                    await this.REDIS_update("user", this.user.entityId, this.user);
                    this.loading = false;
                    this.user = { ...this.$schema.user };
                    this.drawer = false;
                    this.MIX_alertBox({ show: true, message: "Saved", color: "success", timeout: "2000" });
                    this.getTableData();
                } else {
                    validationErrors = 0;
                    this.loading = false;
                    this.MIX_alertBox({ show: true, message: "Fields Missing", color: "error", timeout: "4000" });
                }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Saving", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        // Initialise
        async initialise() {
            this.loading = true;
            let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{user_status|user_level|language|organisation_type}");
            this.lookups = lookupResult.data.documents;
            this.orgTypes = this.lookups.filter((item) => item.type === "organisation_type");
            this.userStatus = this.lookups.filter((item) => item.type === "user_status");
            // this.userGroups = this.lookups.filter((item) => item.type === "userGroups");
            this.userLevels = this.lookups.filter((item) => item.type === "user_level");
            this.userLanguages = this.lookups.filter((item) => item.type === "language");
            // console.log(this.userLevels)
            this.getTableData();
            this.loading = false;
        },
        // Update Table Options
        updateTableOptions(options) {
            this.tableOptions = options;
        },
        // Get Organisations
        async getOrganisations() {
            try {
                this.loading = true;
                let query = `@deleted:{false} @type:{${this.user.orgType}}`;
                if (this.user.orgType !== "") {
                    let organisationResult = await this.REDIS_searchFor("org", "", "", "name", "asc", query);
                    this.organisations = organisationResult.data.documents;
                } else {
                    this.organisations = [];
                }
                this.loading = false;
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Getting Organisations", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        updateOrgName() {
            if (this.user.orgId !== "") {
                this.user.orgName = this.organisations.find((item) => item.entityId === this.user.orgId).name;
                return;
            }
        },
        updateStatus(status) {
            this.user.status = status;
            this.saveUser();
        },
        toggleFilters() {
            if (this.drawer) {
                this.drawer = false;
            }
            this.filterDrawer = !this.filterDrawer;
        },
    },
    created() {
        this.initialise();
    },
};
</script>

<style scoped>
.leftside-overlay {
    position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness */
  z-index: 99 ; /* Specify a stack order in case you're using a different order for other elements */
}

.d-flex {
    gap: 15px;
}
</style>
