<template>
  <div>
    <div style="height: 2px !important">
      <v-progress-linear :color="loading ? 'primary' : 'grey lighten-2'" :indeterminate="loading"
                         :value="!loading ? 100 : 0"></v-progress-linear>
    </div>

    <!-- OVERLAY -->

    <div v-if="filterDrawer" class="leftside-overlay" @click="filterDrawer = false"></div>
    <!-- MAIN PAGE -->
    <v-row class="px-5 pt-5" dense>
      <v-container v-if="loading" class="loader-overlay" style="z-index: 9999;">
        <v-progress-circular :color="loading ? 'primary' : 'grey lighten-2'" :indeterminate="loading"
                             :value="!loading ? 100 : 0"
                             class="text-center" size="108">
          {{ 'Loading Clients' }}
        </v-progress-circular>
        <!-- <div class="text-h7">{{ loading ? "Loading Clients" : "" }}</div> -->
      </v-container>
      <v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'd-flex justify-space-between'" cols="12">
        <div class="">
          <div class="d-flex align-center" style="padding-left: 10px;">
            <div>
              <div class="text-h4 primary--text d-flex flex-column">
                Clients
                <div class="d-flex align-center pb-2" style="width: 100% !important">
                  <div class="body-2 grey--text text--darken-2">
                    Showing <strong>{{ itemsFrom + 1 }}</strong>
                    <span v-if="itemsTo !== itemsTotal">
                                            to <strong>{{ itemsTo }}</strong></span
                    >
                    of <strong>{{ itemsTotal }}</strong>
                  </div>
                </div>
                <!-- <v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon> -->
              </div>
              <!-- <pre class="caption">{{ filterQuery }}</pre> -->
            </div>
          </div>
          <v-spacer/>
        </div>
        <div
            :class="$vuetify.breakpoint.width >= 600 && $vuetify.breakpoint.width < 880 ? `d-flex justify-end` : $vuetify.breakpoint.width < 600 ? `d-flex flex-wrap align-start justify-space-evenly ` : 'd-flex'"
            :style="$vuetify.breakpoint.width < 600 ? 'padding-left: 10px;' : 'padding-right: 10px;'">
          <!-- Search -->
          <SearchField v-if="$vuetify.breakpoint.width > 1500 || ($vuetify.breakpoint.width > 880 && !filterDrawer)"
                       v-model="search" :dense="true" label="Search Clients"/>
          <AppButton @click.native="toggleFilters">
            <v-icon>icons8-filter</v-icon>
          </AppButton>
          <AppButton @click.native="seeFavourites = !seeFavourites">
            <v-icon class="gold--text">icons8-rating</v-icon>
          </AppButton>
          <AppButton @click.native="getTableData">
            <v-icon>icons8-synchronize</v-icon>
          </AppButton>
          <AppButton :disabled="this.tableOptions.page === 1" @click.native="previous()">
            <v-icon class="primary--text">icons8-previous</v-icon>
          </AppButton>
          <AppButton v-if="!table" @click.native="table = true">
            <v-icon class="primary--text">icons8-table</v-icon>
          </AppButton>
          <AppButton v-if="table" @click.native="table = false">
            <v-icon class="primary--text">icons8-rows</v-icon>
          </AppButton>
          <AppButton :disabled="tableOptions.page * tableOptions.itemsPerPage >= itemsTotal" @click.native="next()">
            <v-icon class="primary--text">icons8-next</v-icon>
          </AppButton>
          <!-- TODO - Invite Button required -->
          <!-- TODO - Add Org needs to work with Firebase Authentication -->
          <AppButton :disabled="drawer" buttonclass="secondary" @click.native="addOrg">Add Client</AppButton>
          <div class="white--text">
            <DropdownMenu>
              <div class="d-flex align-center">
                <v-switch v-model="enableDelete" class="" color="secondary" dense inset/>
                <div class="font-weight-bold secondary--text">Enable Delete</div>
              </div>
            </DropdownMenu>
          </div>

          <div v-if="showInfo" class="text--secondary pt-4">The following page shows all orgs that have access to the
            App/Portal, you can use this page to Manage orgs and access.
          </div>
        </div>
      </v-col>
      <!-- <v-col cols="12" v-if="JSON.stringify(tableData) !== '[]'" class="body-2"> Found {{ itemsTotal }} vet(s) </v-col> -->
      <!-- Table -->
      <v-col class="pt-2" cols="12" no-gutters>
        <!-- Data Table :matches="matchCount"-->
        <DataTable v-if="table && tableData.length > 0" :actions="['Edit']" :datatableoptions="tableOptions"
                   :enabledelete="enableDelete" :itemstotal="itemsTotal" :loading="loading" :lookups="lookups"
                   :tabledata="tableData" :tableheaders="filteredHeaders" custom="orgs" @star="starItem"
                   @viewmatch="openMatches" v-on:delete="deleteOrg" v-on:edit="editOrg"
                   v-on:restore="restoreOrg" v-on:tableoptions="updateTableOptions"/>
        <!-- <div v-if="table && tableData.length === 0 && seeFavourites">No favourites added</div> -->
        <!-- <v-row v-if="!table && tableData.length > 0">
            <v-col cols="12" xs="12" sm="4" md="4" lg="4" v-for="item in tableData" :key="item.entityId"> -->
        <div v-if="!table && tableData.length > 0" class="business-cards-container">
          <div v-for="item in tableData" :key="item.entityId" class="business-cards">
            <BasicCard class="business-card-component" footerclass="grey lighten-4"
                       style="min-height: 230px !important">
              <template v-slot:header>
                <div class="full-width d-flex align-start px-4 pt-2"
                     style="height: 60px !important; overflow: hidden !important">
                  <div class="text-body-1 font-weight-bold primary--text text-left" style="cursor: pointer"
                       @click="editOrg(item)">
                    {{ item.firstName }} {{ item.lastName }}<br/>
                    <span class="secondary--text">{{ item.companyName }}</span>
                  </div>
                  <v-spacer/>
                  <v-icon v-if="enableDelete" class="error--text mt-1" size="32" title="Delete"
                          @click.stop="deleteOrg(item)">icons8-close-window
                  </v-icon>
                  <v-icon :class="item.isStarred ? 'gold--text' : 'grey--text'" class="mt-1"
                          size="32" @click.stop="starItem(item)">icons8-rating
                  </v-icon>
                  <div class="d-flex align-center" no-gutters style="cursor: pointer" @click.stop="openMatches(item)">
                    <v-icon :class="item.matchCount > 0 ? 'success--text' : 'grey--text'" size="36">icons8-match
                    </v-icon>
                    <span :class="item.matchCount > 0 ? 'success--text' : 'grey--text'">{{
                        Math.min(item.matchCount, 20)
                      }}</span>
                  </div>
                  <!-- <div class="text-right" style="min-width: 120px !important;">
  <v-btn icon @click.stop="alertme()"><v-icon class="grey--text">icons8-star</v-icon></v-btn>
  <v-btn icon @click.stop="alertme()"><v-icon class="primary--text">icons8-hide</v-icon></v-btn>
  <v-btn icon @click.stop="alertme()"><v-icon class="error--text">icons8-trash-can</v-icon></v-btn>
</div> -->
                </div>
              </template>
              <div class="mt-2 mb-1 grey lighten-3" style="height: 3px !important"></div>
              <div class="px-4 text-body-1 grey--text text--darken-4 pb-5 mt-3"
                   style="cursor: pointer; overflow: hidden !important" @click="editOrg(item)">
                <strong class="grey--text">Match Profile</strong><br/>
                <strong class="font-italic">Sectors</strong><br/>
                <div v-if="item?.sectors">
                  <v-chip
                      v-for="(sector, sectorIndex) in item.sectors.slice(0, 3).sort((a, b) => lookupValue(b).found - lookupValue(a).found)"
                      :key="sectorIndex"
                      :class="['mr-1 my-1 lighten-2 primary--text sector-chip', lookupValue(sector).found ? 'secondary' : 'orange']"
                      label
                  >
                    {{ lookupValue(sector).text }}
                  </v-chip>
                  <v-chip
                      v-if="item.sectors.length > 3"
                      class="mr-1 my-1 secondary lighten-2 primary--text ellipsis-chip"
                      label
                  >
                    ...
                  </v-chip>
                </div>
                <div v-else>-<br/></div>
                <strong class="font-italic">Locations</strong><br/>
                <div v-if="item?.locations">
                  <v-chip
                      v-for="(location, locationIndex) in item.locations.slice(0, 3).sort((a, b) => lookupValue(b).found - lookupValue(a).found)"
                      :key="locationIndex"
                      :class="['mr-1 my-1 lighten-2 primary--text location-chip', lookupValue(location).found ? 'secondary' : 'orange']"
                      label
                  >
                    {{ lookupValue(location).text }}
                  </v-chip>
                  <v-chip
                      v-if="item.locations.length > 3"
                      class="mr-1 my-1 secondary lighten-2 primary--text ellipsis-chip"
                      label
                  >
                    ...
                  </v-chip>
                </div>
                <div v-else>-<br/></div>
              </div>
              <!-- <div class="text-body-2 d-flex font-weight-bold">
    <div><v-icon class="grey--text pr-1" small>icons8-tear-off-calendar</v-icon>{{ MIX_formatDateTime(item.listingDateTime, "ISOString", "D MMM YYYY") }}</div>
    <v-spacer />
    <div class="success--text" v-if="MIX_formatDateTimeFromNow(item.lastSeenDateTime, 'ISOString', 'Days') === 'Today'"><v-icon class="success--text pr-1" small>icons8-important-time</v-icon>{{ MIX_formatDateTimeFromNow(item.lastSeenDateTime, "ISOString", "Days") }}</div>
    <div class="success--text" v-else><v-icon class="success--text pr-1" small>icons8-clock-checked</v-icon>{{ MIX_formatDateTimeFromNow(item.lastSeenDateTime, "ISOString", "Days") }}</div>
  </div>
  <div class="mt-2 d-flex align-center">
    <div class="text-body-2 font-italic">{{ item.sourceName }}</div>
    <v-spacer />
    <div class="text-body-2">{{ item.type }}</div>
  </div>
  <div class="mt-1">{{ item.summary.substring(0,100) }}...</div> -->
              <!-- <v-icon class="grey--text pr-1" small>icons8-link</v-icon><a :href="item.sourceURL" class="" target="_blank">Mark to Market</a></div> -->
              <!-- <template v-slot:footer>
  <div class="full-width px-4 py-2 d-flex align-center" style="overflow: hidden !important">
    <div class="flex-grow-1 d-flex align-center">
      <v-icon class="grey--text text--darken-1">icons8-sales-balance</v-icon>
      <span v-if="item.turnover !== 0">{{ MIX_formatCurrency(item?.turnover, 0) }}</span>
      <span v-else>-</span>
    </div>
    <div class="flex-grow-1 d-flex align-center">
      <v-icon class="grey--text text--darken-1">icons8-price-tag-pound</v-icon>
      <span v-if="item.price !== 0">{{ MIX_formatCurrency(item?.price, 0) }}</span>
      <span v-else>-</span>
    </div>
    <div class="flex-grow-1 d-flex align-center">
      <v-icon class="grey--text text--darken-1">icons8-total-sales</v-icon>
      <span v-if="item.ebitda !== 0">{{ MIX_formatCurrency(item?.ebitda, 0) }}</span>
      <span v-else>-</span>
    </div>
  </div>
</template> -->
            </BasicCard>
          </div>
        </div>
        <!-- </v-col>
        </v-row> -->
        <!-- <v-row v-if="!table && tableData.length === 0 && seeFavourites">
            <div v-if="!loading" class="primary--text d-flex justify-center text-h6 font-weight-bold pa-8" style="width: 100%">No favourites added yet</div>
        </v-row> -->
      </v-col>
      <!-- Filter Drawer -->
      <v-navigation-drawer v-model="filterDrawer" absolute class="px-5 pb-5 grey lighten-3" clipped
                           right style="z-index: 999" width="400px">
        <v-row>
          <v-col cols="12">
            <div class="d-flex align-center pt-4">
              <div class="text-h5 secondary--text">Filters</div>
              <v-spacer/>
              <v-btn depressed icon @click="filterDrawer = false">
                <v-icon>icons8-close</v-icon>
              </v-btn>
            </div>
            Use the filters below to narrow your search.
          </v-col>
          <v-col cols="12">
            <!-- HIDE EMPTY PROFILES -->
            <div class="flex-grow-1">
              <div class="d-flex align-center pb-4 pl-2">
                <v-switch v-model="filter.hideEmptyProfiles" class="" color="secondary" dense inset/>
                <div class="font-weight-bold secondary--text">Hide Empty Profiles</div>
              </div>
            </div>
          </v-col>

          <!-- Search -->
          <v-col cols="12">
            <!-- Status -->
            <div class="flex-grow-1">
              <div class="d-flex align-center pb-4">
                <SearchField v-if="$vuetify.breakpoint.width <= 1500 && filterDrawer" v-model="search"
                             :dense="true" label="Search Clients"/>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <!-- Status -->
            <div class="flex-grow-1">
              <div class="my-2 text-h6 primary--text">Engaged</div>
              <div class="d-flex align-center pb-4">
                <DropdownMultiple v-model="filter.engaged" :items="orgEngaged" label="Engaged"/>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <!-- SHOW DELETED -->
            <div class="flex-grow-1">
              <!-- <div class="my-2 text-h6 primary--text">Engaged</div> -->
              <div class="d-flex align-center pb-4 pl-2">
                <v-switch v-model="filter.showDeleted" class="" color="secondary" dense inset/>
                <div class="font-weight-bold secondary--text">Show Deleted</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-navigation-drawer>

      <!-- Confirmation Box -->
      <ConfirmBox :value="confirmBox" footerclass="grey lighten-2" headerclass="primary" style="z-index: 999"
                  v-on:close="confirmBox = false">
        <template v-slot:header>
          <div class="full-width d-flex align-center">
            <div>Confirm</div>
            <v-spacer/>
            <v-btn depressed icon @click="confirmBox = false">
              <v-icon>icons8-close</v-icon>
            </v-btn>
          </div>
        </template>
        <p>Please confirm you want to delete this Client</p>
        <strong>{{ org.name }}</strong
        ><br/>
        <!-- permenantDelete : {{ permenantDelete }}<br /> -->
        <!-- <CheckboxField v-model="permenantDelete">Permenantly Delete</CheckboxField> -->
        <template v-slot:footer>
          <v-row>
            <v-col class="d-flex justify-end" cols="12">
              <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
              <AppButton buttonclass="warning" @click.native="confirmDeleteOrg">Confirm</AppButton>
            </v-col>
          </v-row>
        </template>
      </ConfirmBox>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ClientsView",
  // data
  data: () => ({
    loading: false,
    drawer: false,
    table: false,
    filterDrawer: false,
    confirmBox: false,
    permenantDelete: false,
    search: "",
    delayedSearch: "",

    // enable delete
    enableDelete: false,

    //stat elements
    isStarred: false,
    stat: {},
    seeFavourites: false,
    starredIdsArray: [],
    idsArr: [],

    // filter elements
    filter: {
      status: ["approved", "pending"],
      showDeleted: false,
      engaged: [],
      hideEmptyProfiles: false,
    },
    tableData: [],
    itemsTotal: 0,
    tableHeaders: [
      {
        text: "Status",
        value: "status",
        align: "start",
        hidden: true,
        sortable: false,
        shrunk: true,
        smallDevice: false
      },
      {text: "ID", value: "id", align: "start", hidden: true, sortable: false, shrunk: true, smallDevice: true},
      {
        text: "First Name",
        value: "firstName",
        align: "start",
        hidden: false,
        sortable: false,
        shrunk: true,
        smallDevice: true
      },
      {
        text: "Last Name",
        value: "lastName",
        align: "start",
        hidden: false,
        sortable: false,
        shrunk: true,
        smallDevice: true
      },
      {
        text: "Company",
        value: "companyName",
        align: "start",
        hidden: false,
        sortable: false,
        shrunk: true,
        smallDevice: true
      },
      {
        text: "Matches",
        value: "matchCount",
        align: "center",
        hidden: false,
        sortable: false,
        shrunk: false,
        smallDevice: true
      },

      {
        text: "Sectors",
        value: "sectors",
        align: "start",
        hidden: true,
        sortable: false,
        shrunk: false,
        smallDevice: false
      },
      {
        text: "Locations",
        value: "locations",
        align: "start",
        hidden: true,
        sortable: false,
        shrunk: false,
        smallDevice: false
      },
      {
        text: "deleted",
        value: "deleted",
        align: "start",
        hidden: true,
        sortable: false,
        shrunk: false,
        smallDevice: true
      },
      {
        text: "",
        value: "action",
        align: "center",
        hidden: false,
        sortable: false,
        width: "110px",
        shrunk: true,
        smallDevice: true
      },
    ],
    tableOptions: {
      page: 1,
      itemsPerPage: 12,
      sortBy: ["name"],
      sortDesc: [false],
      groupBy: [],
      groupDesc: [],
      mustSort: false,
      multiSort: false,
    },
    validate: {},
    org: {},
    users: [],

    // lookup data
    orgEngaged: [],
    countries: [],
    orgTypes: [],
    orgStatus: [],
    orgGroups: [],
    orgLevels: [],
    orgLanguages: [],
    action: "Add",
    showInfo: false,
    lookups: [],
  }),

  // computed
  computed: {
    ...mapGetters({
      GET_currentUserProfile: "GET_currentUserProfile",
    }),
    filteredHeaders() {
      let headers = this.tableHeaders.filter((header) => !header.hidden);
      if (this.drawer) {
        headers = headers.filter((header) => header.shrunk);
      }
      if (this.isSmallDevice()) {
        headers = headers.filter((header) => header.smallDevice);
      }
      return headers;
    },

    filterQuery() {
      let query = "";
      if (this.delayedSearch !== "" && this.delayedSearch !== null && this.delayedSearch !== undefined && this.delayedSearch.length > 1) {
        query += `${this.delayedSearch.trim()}* `;
      }
      if (this.filter.showDeleted) {
        query += `@deleted:{true}`;
      } else {
        query += `@deleted:{false}`;
      }
      // Filter Engaged
      if (JSON.stringify(this.filter.engaged) !== "[]") {
        query += ` @engaged:{${this.filter.engaged.join("|")}}`;
      }

      // Filter starred
      if (this.seeFavourites) {
        query += ` @id:${this.idsArr.join("|")}`;
      }

      return query;
    },
    itemsFrom() {
      return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
    },
    itemsTo() {
      if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
        return this.itemsTotal;
      } else {
        return this.tableOptions.page * this.tableOptions.itemsPerPage;
      }
    },
    itemsCount() {
      return this.tableOptions.itemsPerPage;
    },
  },
  // watch
  watch: {
    search: {
      handler(newSearch) {
        // Clear any previous timeout
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout);
        }

        // Set a new timeout to delay the update
        this.searchTimeout = setTimeout(() => {
          this.delayedSearch = newSearch;
        }, 1000); // Adjust the delay time as needed (e.g., 1000 milliseconds = 1 second)
      },
      immediate: false,
    },

    filter: {
      handler() {
        this.getTableData();
      },
      deep: true,
    },

    filterQuery: {
      handler() {
        this.tableOptions.page = 1;
        this.getTableData();
      },
      deep: true,
    },
    tableOptions: {
      handler() {
        this.getTableData();
      },
      deep: true,
    },
  },
  // methods
  methods: {
    // checks size and sets the columns on the table
    isSmallDevice() {
      return window.innerWidth < 880;
    },
    // Star Items
    async starItem(item) {
      try {
        if (item.isStarred) {
          // if item is already starred then remove it
          let searchQuery = `@createdUserId:{${this.GET_currentUserProfile.entityId}} @linkedId:{${item.id}} @deleted:{false}`;
          let contentResult = await this.REDIS_searchFor("stat", "", "", "", "", searchQuery);
          let statToRemove = contentResult.data.documents[0];
          await this.REDIS_delete("stat", statToRemove.id, {
            modifiedUserId: this.GET_currentUserProfile.entityId,
            modifiedDateTime: this.GET_currentUserProfile.entityId,
            deletedUserId: this.GET_currentUserProfile.entityId,
            deletedDateTime: this.GET_currentUserProfile.entityId,
            deleted: true
          });
          await this.getStatData();
        } else {
          // add item to starred
          this.stat = {...this.$schema.stat};
          // data for db
          this.stat.id = this.MIX_generateId();
          this.stat.type = "star";
          this.stat.category = "clients";
          this.stat.subcategory = "";
          this.stat.linkedId = item.id;
          this.stat.createdUserId = this.GET_currentUserProfile.entityId;
          this.stat.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
          this.stat.modifiedUserId = this.GET_currentUserProfile.entityId;
          this.stat.modifiedDateTime = this.GET_currentUserProfile.entityId;
          await this.REDIS_createWithId("stat", this.stat, this.stat.id);
          await this.getStatData();
        }
      } catch (error) {
        this.MIX_alertBox({show: true, message: "Error Saving Starred Item", color: "error", timeout: "4000"});
        console.error("error", error);
      } finally {
        // starred and table data
        this.getTableData();
      }
    },
    // Get starred content
    async getStatData() {
      let searchQuery = `@type:{star} @deleted:{false} @category:{clients} @createdUserId:{${this.GET_currentUserProfile.entityId}} `;
      let statData = await this.REDIS_searchFor("stat", "", "", "createdDateTime", "desc", searchQuery);
      this.starredIdsArray = statData.data.documents.map((item) => item.linkedId);
      // console.log("this.starredIdsArray=", this.starredIdsArray);
      if (this.starredIdsArray.length > 0 && this.starredIdsArray !== undefined) {
        this.idsArr = this.starredIdsArray.filter((item) => typeof item === "string");
      } else {
        this.idsArr = [];
      }
    },
    // Get Table Data
    async getTableData() {
      try {
        this.loading = true;
        this.tableData = [];
        let dataResult;
        try {
          dataResult = await this.REDIS_searchByMatch(this.itemsFrom, this.itemsCount, this.filterQuery, this.filter.hideEmptyProfiles);
        } catch (error) {
          this.tableOptions.page = 1;
          dataResult = await this.REDIS_searchByMatch(this.itemsFrom, this.itemsCount, this.filterQuery, this.filter.hideEmptyProfiles);
        }
        let tableData = dataResult.data.data;
        // console.log("tableData", JSON.stringify(tableData, null, 2));
        this.itemsTotal = dataResult.data.total;

        // Add isStarred to tableData
        if (this.starredIdsArray.length > 0 && this.starredIdsArray !== undefined) {
          for (let item of tableData) {
            item.isStarred = this.starredIdsArray.some((star) => {
              if (star === item.id) {
                return true;
              }
            });
          }
        }

        this.tableData = tableData;
        // console.log("this.tableData", JSON.stringify(this.tableData, null, 2));
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    // Add Org
    addOrg() {
      this.MIX_go("/client/new");
    },
    // Next page of the table
    next() {
      if (this.tableOptions.page * this.tableOptions.itemsPerPage < this.itemsTotal) {
        this.tableOptions.page++;
      }
    },
    // Previous page of the table
    previous() {
      if (this.tableOptions.page > 1) {
        this.tableOptions.page--;
      }
    },
    // Edit Org
    editOrg(org) {
      this.MIX_go({name: "ClientView", params: {id: org.id, formMode: "read", orgTab: "details"}});
    },
    // Open Matches
    openMatches(item) {
      this.MIX_go({name: "ClientView", params: {id: item.id, formMode: "read", orgTab: "profile"}});
    },

    // Delete Org
    async deleteOrg(org) {
      let clientInfo = await this.REDIS_read("org", org.id);
      clientInfo = clientInfo.data;
      this.org = {...clientInfo};
      this.action = "Delete";
      this.confirmBox = true;
    },

    // Restore Org
    async restoreOrg(org) {
      try {
        this.loading = true;
        this.org = {...org};
        this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
        this.org.modifiedOrgId = this.GET_currentUserProfile.orgId;
        this.org.deleted = false;
        this.org.deletedUserId = null;
        this.org.deletedOrgId = null;
        this.org.deletedDateTime = null;
        await this.REDIS_update("org", this.org.entityId, this.org);
        this.loading = false;
        this.confirmBox = false;
        this.permenantDelete = false;
        this.MIX_alertBox({show: true, message: "Restored", color: "success", timeout: "2000"});
        this.getTableData();
      } catch (error) {
        this.MIX_alertBox({show: true, message: "Error Restoring", color: "error", timeout: "4000"});
        console.error(error);
      }
    },
    // Confirm Delete Org
    async confirmDeleteOrg() {
      try {
        this.loading = true;
        this.org.modifiedUserId = this.GET_currentUserProfile.entityId;
        this.org.modifiedOrgId = this.GET_currentUserProfile.orgId;
        this.org.deleted = true;
        this.org.deletedUserId = this.GET_currentUserProfile.entityId;
        this.org.deletedOrgId = this.GET_currentUserProfile.orgId;
        this.org.deletedDateTime = this.MIX_formatDateTimeNow("toISOString");
        // NOTE uncomment line below to permanently delete a client from REDIS
        // await this.REDIS_destroy("org", this.org.entityId);
        await this.REDIS_update("org", this.org.entityId, this.org);
        this.loading = false;
        this.confirmBox = false;
        // this.permenantDelete = false;
        this.MIX_alertBox({show: true, message: "Deleted", color: "success", timeout: "2000"});
        this.getTableData();
      } catch (error) {
        this.MIX_alertBox({show: true, message: "Error Deleting", color: "error", timeout: "4000"});
        console.error(error);
      }
    },

    // Initialise
    async initialise() {
      this.loading = true;
      let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{county|country|city|region|organisation_type|country|organisation_status|organisation_engagement|sector}");
      this.lookups = lookupResult.data.documents;
      this.orgEngaged = this.lookups.filter((item) => item.type === "organisation_engagement");
      this.orgTypes = this.lookups.filter((item) => item.type === "organisation_type");
      this.orgStatus = this.lookups.filter((item) => item.type === "organisation_status");
      this.countries = this.lookups.filter((item) => item.type === "country");
      // starred and table data
      await this.getStatData();
      this.getTableData();
      // this.loading = false;
    },
    // Update Table Options
    updateTableOptions(options) {
      this.tableOptions = options;
    },
    // Get Users
    async getUsers() {
      try {
        this.loading = true;
        let query = `@deleted:{false}`;
        let usersResult = await this.REDIS_searchFor("user", "", "", "firstName", "asc", query);
        let users = usersResult.data.documents;
        for (let i = 0; i < users.length; i++) {
          const user = users[i];
          user.fullName = user.firstName + " " + user.lastName + " (" + user.email + ")";
        }
        this.users = users;
        // console.log("this.users", JSON.stringify(this.users, null, 2));
        this.loading = false;
      } catch (error) {
        this.MIX_alertBox({show: true, message: "Error Getting Users", color: "error", timeout: "4000"});
        console.error(error);
      }
    },
    toggleFilters() {
      if (this.drawer) {
        this.drawer = false;
      }
      this.filterDrawer = !this.filterDrawer;
    },
    lookupValue(value) {
      if (value !== "") {
        let lookup = this.lookups.find((item) => item.value === value);
        if (lookup !== undefined) {
          return {text: lookup.text, found: true};
        } else {
          return {text: value.toString().toLocaleUpperCase(), found: false};
        }
      }
    },
  },

  // * CREATED
  created() {
    this.initialise();
  },
};
</script>

<style scoped>
.leftside-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness */
  z-index: 99; /* Specify a stack order in case you're using a different order for other elements */
}

.loader-overlay {
  position: fixed;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex {
  gap: 15px;
}

.business-cards-container {
  display: flex;
  flex-wrap: wrap;
}

.business-cards {
  flex-basis: calc(100% / 4); /* Displays 3 cards per row */
  min-height: 230px; /* Default height for larger screens */
  padding: 10px;
  box-sizing: border-box;
}

.business-card-component {
  height: 320px;
}

@media screen and (max-width: 1100px) {
  /* Adjust styles for smaller screens */
  .business-cards {
    flex-basis: calc(100% / 3); /* Displays 2 cards per row */
    min-height: 230px; /* Height for small screens */
  }

  .business-card-component {
    height: 288px;
  }
}

@media screen and (max-width: 860px) {
  /* Adjust styles for smaller screens */
  .business-cards {
    flex-basis: calc(100% / 2); /* Displays 2 cards per row */
    min-height: 230px; /* Height for small screens */
  }

  .business-card-component {
    height: 288px;
  }
}

@media screen and (max-width: 480px) {
  /* Adjust styles for extra-small screens */
  .business-cards {
    flex-basis: 100%; /* Displays 1 card per row */
  }

  .business-card-component {
    min-height: 260px;
  }
}
</style>
