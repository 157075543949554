const config = {
    name: "AR Deals",
    description: "Description of the App goes here",
    companyName: "GS Verde",
    country: "United Kingdom",
    email: "support@vindico.net",
    address: "The Maltings, East Tyndall Street, Cardiff, CF24 5EZ",
	website: "https://www.gsverde.group/"
};

module.exports = {
    config,
};
