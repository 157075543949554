<template>
    <div>
        <div style="height: 2px !important"><v-progress-linear :indeterminate="loading" :color="loading ? 'primary' : 'grey lighten-2'" :value="!loading ? 100 : 0"></v-progress-linear></div>
        <div v-if="filterDrawer || drawer || editDrawer" class="leftside-overlay" @click="(filterDrawer = false), (drawer = false), (editDrawer = false)"></div>
        <v-row dense class="px-5 pt-5">
            <v-col cols="12" xs="12" sm="2" md="4" lg="2">
                <div class="d-flex align-center">
                    <div>
                        <div class="text-h4 primary--text d-flex">
                            Sources
                            <v-icon class="secondary--text" @click="showInfo = !showInfo">icons8-info</v-icon>
                        </div>
                    </div>
                    <v-spacer />
                </div>
            </v-col>
            <v-col cols="12" xs="12" sm="10" md="4" lg="10" :class="this.$vuetify.breakpoint.width >= 900 ? `d-flex align-center justify-end` : $vuetify.breakpoint.width >= 600 && $vuetify.breakpoint.width < 880 ? `d-flex justify-end` : `d-flex flex-wrap justify-space-evenly`">
                <!-- Search -->
                <SearchField v-if="$vuetify.breakpoint.width > 1500 || ($vuetify.breakpoint.width > 880 && !filterDrawer)" label="Search Sources" v-model="search" :dense="true" />
                <AppButton @click.native="toggleFilters"><v-icon>icons8-filter</v-icon></AppButton>
                <AppButton @click.native="getTableData"><v-icon>icons8-synchronize</v-icon></AppButton>
                <AppButton :disabled="drawer" buttonclass="secondary" @click.native="addSource">Add Source</AppButton>
                <!-- TODO - Invite Button required -->
                <!-- TODO - Add User needs to work with Firebase Authentication -->
                <!-- <AppButton :disabled="drawer" buttonclass="secondary" @click.native="addSource">Add User</AppButton> -->
                <div class="white--text">
                    <DropdownMenu>
                        <div class="d-flex align-center">
                            <v-switch color="secondary" class="" inset dense v-model="enableDelete" />
                            <div class="font-weight-bold secondary--text">Enable Delete</div>
                        </div>
                    </DropdownMenu>
                </div>

                <div class="text--secondary pt-4" v-if="showInfo">The following page shows all sources that have access to the App/Portal, you can use this page to Manage sources and access.</div>
            </v-col>
            <!-- Table -->
            <v-col cols="12" class="pt-2">
                <!-- Data Table -->
                <DataTable :enabledelete="enableDelete" :tableheaders="filteredHeaders" :loading="loading" custom="source" :tabledata="tableData" :datatableoptions="tableOptions" :itemstotal="itemsTotal" v-on:delete="deleteSource" v-on:edit="viewSource" v-on:tableoptions="updateTableOptions" />
            </v-col>
            <!-- Filter Drawer -->
            <v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" v-model="filterDrawer" absolute clipped right style="z-index: 999">
                <v-row>
                    <v-col cols="12">
                        <div class="d-flex align-center pt-4">
                            <div class="text-h5 secondary--text">Filters</div>
                            <v-spacer />
                            <v-btn icon depressed @click="filterDrawer = false"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        Use the filters below to narrow your search.
                    </v-col>
                    <v-col cols="12">
                        <!-- Status -->
                        <div class="flex-grow-1">
                            <!-- Search -->
                            <SearchField v-if="$vuetify.breakpoint.width <= 1500 && filterDrawer" label="Search Clients" v-model="search" :dense="true" />
                            <div class="my-2 text-h6 primary--text">Status</div>
                            <div class="d-flex align-center pb-4">
                                <!-- Status -->
                                <DropdownMultiple :items="sourceStatus" v-model="filter.sourceStatus" label="Source Status" />
                            </div>
                            <!-- Show Deleted -->
                            <div class="d-flex align-center">
                                <v-switch color="secondary" class="pl-4" inset dense v-model="filter.showDeleted" />
                                <div class="font-weight-bold secondary--text">Show Deleted</div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-navigation-drawer>
            <!-- View Window -->
            <v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" style="overflow: scroll !important; z-index: 999" v-model="drawer" absolute clipped right>
                <!-- Practice Details -->
                <div class="mt-5 px-5">
                    <!-- Source Details -->
                    <div class="d-flex align-start pb-2" style="height: 60px">
                        <div class="text-h5 primary--text">
                            {{ source?.name }}
                        </div>
                        <v-spacer />
                        <v-btn class="pr-5" icon depressed @click="editSource(source)"><v-icon>icons8-edit</v-icon></v-btn>
                        <v-btn class="" icon depressed @click="closeSource"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                    <!-- Description -->
                    <div class="mt-4 text-body-1" v-if="source?.description">
                        <div class="mt-4 text-h6 primary--text">Description</div>
                        <div class="mt-2 text-body-1" v-html="source?.description"></div>
                    </div>
                    <div class="mt-6 text-body-1" v-if="source?.website">
                        <div class="mt-4 text-h6 primary--text">Website</div>
                        <div class="mt-2 text-body-1" v-html="source?.website"></div>
                    </div>

                    <!-- Source's Contact Details -->
                    <!-- <div class="mt-2 text-h6 primary--text">Contact Details</div> -->
                    <div class="mt-6 text-body-1" v-if="source?.contactName">
                        <div class="mt-4 text-h6 primary--text">Contact Name</div>
                        <div class="mt-2 text-body-1" v-html="source?.contactName"></div>
                    </div>
                    <div class="mt-4 text-body-1" v-if="source?.contactEmail">
                        <div class="mt-4 text-h6 primary--text">Contact Email</div>
                        <div class="mt-2 text-body-1" v-html="source?.contactEmail"></div>
                    </div>
                    <div class="mt-4 text-body-1" v-if="source?.contactTelephone">
                        <div class="mt-4 text-h6 primary--text">Contact Telephone</div>
                        <div class="mt-2 text-body-1" v-html="source?.contactTelephone"></div>
                    </div>
                    <!-- Status -->
                    <!-- <div class="mt-2 text-h6 primary--text">Source Login Details</div> -->
                    <div class="mt-6 text-body-1" v-if="source?.username">
                        <div class="mt-4 text-h6 primary--text">Username</div>
                        <div class="mt-2 text-body-1" v-html="source?.username"></div>
                    </div>
                    <div class="mt-4 text-body-1" v-if="source?.password">
                        <div class="mt-4 text-h6 primary--text">Password</div>
                        <div class="mt-2 text-body-1">***********</div>
                    </div>
                    <!-- Status -->
                    <!-- <div class="mt-2 text-h6 primary--text">Source Status</div> -->
                    <!-- <div class="mt-6 text-h6 primary--text">Status</div>
                    <div class="mt-2 text-body-1">{{ lookupValue(source.status) }}</div> -->
                </div>
            </v-navigation-drawer>

            <!-- Edit Window -->
            <v-navigation-drawer width="400px" class="px-5 pb-5 grey lighten-3" style="overflow: scroll !important; z-index: 999" v-model="editDrawer" absolute clipped right>
                <!-- Practice Details -->
                <v-row dense class="mt-5 pr-2">
                    <v-col cols="12">
                        <div class="d-flex align-center pb-2">
                            <div class="text-h5 secondary--text">{{ action }} Source</div>
                            <v-spacer />
                            <v-btn icon depressed @click="closeSource"><v-icon>icons8-close</v-icon></v-btn>
                        </div>
                        Use the form below to {{ action }} the Source's details
                    </v-col>
                    <!-- Source Details -->
                    <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Source Details</div> </v-col>
                    <v-col cols="12"> <TextField backgroundcolor="white" label="Name *" v-model="source.name" :validate="validate.name" /> </v-col>
                    <v-col cols="12"> <TextArea backgroundcolor="white" label="Description" v-model="source.description" :validate="validate.description" /> </v-col>
                    <v-col cols="12"> <TextField backgroundcolor="white" label="Website *" v-model="source.website" :validate="validate.website" /> </v-col>

                    <!-- Source's Contact Details -->
                    <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Contact Details</div> </v-col>
                    <v-col cols="12"> <TextField backgroundcolor="white" label="Name" v-model="source.contactName" :validate="validate.contactName" /> </v-col>
                    <v-col cols="12"> <TextField backgroundcolor="white" label="Email" v-model="source.contactEmail" :validate="validate.contactEmail" /> </v-col>
                    <v-col cols="12"> <TextField backgroundcolor="white" label="Telephone" v-model="source.contactTelephone" :validate="validate.contactTelephone" /> </v-col>
                    <!-- Status -->
                    <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Source Login Details</div> </v-col>
                    <v-col cols="12"> <TextField backgroundcolor="white" label="Username" v-model="source.username" :validate="validate.username" /> </v-col>
                    <v-col cols="12" class="pb-4"> <TextField backgroundcolor="white" label="Password" v-model="source.password" :validate="validate.password" /> </v-col>
                    <!-- Status -->
                    <!-- <v-col cols="12"> <div class="mt-2 text-h6 primary--text">Source Status</div> </v-col> -->
                    <!-- <v-col cols="12"> <DropdownAdvanced backgroundcolor="white" label="Status *" v-model="source.status" :items="sourceStatus" :validate="validate.status" class="pb-4" /> </v-col> -->
                    <!-- Action Buttons -->
                    <v-col cols="6">
                        <div><AppButton block buttonclass="grey" @click.native="closeSource">Cancel</AppButton></div>
                    </v-col>
                    <v-col cols="6">
                        <div><AppButton block buttonclass="secondary" @click.native="saveSource">Save</AppButton></div>
                    </v-col>
                </v-row>
            </v-navigation-drawer>
            <!-- Confirmation Box -->
            <ConfirmBox headerclass="primary" footerclass="grey lighten-2" :value="confirmBox" v-on:close="confirmBox = false">
                <template v-slot:header>
                    <div class="full-width d-flex align-center">
                        <div>Confirm</div>
                        <v-spacer />
                        <v-btn icon depressed @click="confirmBox = false"><v-icon>icons8-close</v-icon></v-btn>
                    </div>
                </template>
                <p>Please confirm you want to delete this Source</p>
                <strong>{{ source.name }}</strong
                ><br />
                <template v-slot:footer>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-end">
                            <AppButton buttonclass="grey" @click.native="confirmBox = false">Cancel</AppButton>
                            <AppButton buttonclass="warning" @click.native="confirmDeleteSource">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </template>
            </ConfirmBox>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "SourcesAdmin",
    // data
    data: () => ({
        // RANDOM
        loading: false,

        // drawers
        drawer: false,
        filterDrawer: false,
        editDrawer: false,
        confirmBox: false,
        permenantDelete: false,

        //lookup
        lookups: [],

        // ENABLE DELETE
        enableDelete: false,
        // DATA TABLE
        search: "",
        filter: {
            sourceStatus: [],
            showDeleted: false,
        },
        tableData: [],
        itemsTotal: 0,
        tableHeaders: [
            { text: "Status", value: "statusDate", align: "start", hidden: false, sortable: false, shrunk: true, smallDevice: true },
            { text: "Name", value: "name", align: "start", hidden: false, sortable: false, shrunk: true, smallDevice: true },
            { text: "Website", value: "website", align: "start", hidden: false, sortable: false, shrunk: false, smallDevice: true },
            { text: "Contact Name", value: "contactName", align: "start", hidden: false, sortable: false, shrunk: false, smallDevice: true },
            { text: "Email", value: "contactEmail", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: false },
            { text: "Telephone", value: "contactTelephone", align: "start", hidden: false, sortable: false, shrunk: false, smallDevice: false },
            { text: "Org Id", value: "orgId", align: "start", hidden: true, sortable: false, shrunk: true, smallDevice: true },
            { text: "Last Feed", value: "lastFeedDateTime", align: "start", hidden: false, sortable: false, shrunk: true, smallDevice: true },
            { text: "Enabled", value: "enabled", align: "start", hidden: false, sortable: false, shrunk: false, smallDevice: true },
            { text: "createdUserId", value: "createdUserId", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: false },
            { text: "createdOrgId", value: "createdOrgId", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: false },
            { text: "modifedOrgId", value: "modifedOrgId", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: false },
            { text: "modifiedDateTime", value: "modifiedDateTime", align: "start", hidden: true, sortable: false, shrunk: false, smallDevice: false },
            { text: "", value: "action", align: "center", hidden: false, sortable: false, width: "110px", shrunk: true, smallDevice: true },
        ],
        tableOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["name"],
            sortDesc: [false],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false,
        },
        // ADD / EDIT SOURCE
        validate: {},
        source: {},

        sourceStatus: [],
        sourceGroups: [],
        sourceLevels: [],
        sourceLanguages: [],
        action: "Add",
        showInfo: false,
    }),
    // computed
    computed: {
        ...mapGetters({
            GET_currentUserProfile: "GET_currentUserProfile",
        }),
        filteredHeaders() {
            let headers = this.tableHeaders.filter((header) => !header.hidden);
            if (this.drawer) {
                headers = headers.filter((header) => header.shrunk);
            }
            if (this.isSmallDevice()) {
                headers = headers.filter((header) => header.smallDevice);
            }
            return headers;
        },
        filterQuery() {
            let query = "";
            if (this.search !== "" && this.search !== null && this.search !== undefined && this.search.length > 1) {
                query += `${this.search}*`;
            }
            if (this.filter.showDeleted) {
                query += ` @deleted:{true}`;
            } else {
                query += `@deleted:{false}`;
            }
            // Filter Log severity
            if (JSON.stringify(this.filter.sourceStatus) !== "[]") {
                query += ` @status:{${this.filter.sourceStatus.join("|")}}`;
            }
            return query;
        },
        itemsFrom() {
            return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage;
        },
        itemsTo() {
            if (this.tableOptions.page * this.tableOptions.itemsPerPage > this.itemsTotal) {
                return this.itemsTotal;
            } else {
                return this.tableOptions.page * this.tableOptions.itemsPerPage;
            }
        },
        itemsCount() {
            return this.tableOptions.itemsPerPage;
        },
        itemsSortBy() {
            let sortBy = this.tableOptions.sortBy;
            if (sortBy.length > 0) {
                sortBy = sortBy.map((x) => x);
                sortBy = sortBy.join(",");
            }
            return sortBy;
        },
        itemsSortDesc() {
            let sortDesc = this.tableOptions.sortDesc[0];
            if (sortDesc) {
                sortDesc = "DESC";
            } else {
                sortDesc = "ASC";
            }
            return sortDesc;
        },
    },
    // watch
    watch: {
        displayPassword(value) {
            this.password = value;
        },
        filterQuery: {
            handler() {
                this.tableOptions.page = 1;
                this.getTableData();
            },
            deep: true,
        },
        tableOptions: {
            handler() {
                this.getTableData();
            },
            deep: true,
        },
    },
    // methods
    methods: {
        //lookupvalue
        lookupValue(value) {
            if (value !== "") {
                let lookup = this.lookups.find((item) => item.value === value);
                if (lookup !== undefined) {
                    return lookup.text;
                } else {
                    return "";
                }
            }
        },
        // checks size and sets the columns on the table
        isSmallDevice() {
            return window.innerWidth < 880;
        },
        // Get Table Data
        async getTableData() {
            try {
                this.loading = true;
                // console.log('filterQuery = ' + this.filterQuery)
                // console.log('itemsFrom = ' + this.itemsFrom)
                // console.log('itemsCount = ' + this.itemsCount)
                // console.log('itemsSortBy = ' + this.itemsSortBy)
                // console.log('itemsSortDesc = ' + this.itemsSortDesc)

                let dataResult = await this.REDIS_searchFor("source", this.itemsFrom, this.itemsCount, this.itemsSortBy, this.itemsSortDesc, this.filterQuery);
                this.tableData = dataResult.data.documents;
                // console.log("tableData" + JSON.stringify(this.tableData, null, 2));
                this.itemsTotal = dataResult.data.total;
                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },
        // Add User
        addSource() {
            this.source = { ...this.$schema.source };
            this.action = "Add";
            this.filterDrawer = false;
            this.drawer = false;
            this.editDrawer = true;
        },
        // Open Source
        viewSource(source) {
            this.source = { ...source };
            this.action = "Open";
            this.filterDrawer = false;
            this.drawer = true;
        },
        // Edit User
        editSource(source) {
            this.source = { ...source };
            this.action = "Edit";
            // this.getOrganisations();
            this.filterDrawer = false;
            this.drawer = false;
            this.editDrawer = true;
        },
        // Delete User
        deleteSource(source) {
            this.source = { ...source };
            this.action = "Delete";
            this.confirmBox = true;
        },
        // Close Source
        closeSource(source) {
            this.source = { ...source };
            this.drawer = false;
            this.editDrawer = false;
        },
        // Confirm Delete User
        async confirmDeleteSource() {
            try {
                this.loading = true;
                this.source.modifiedUserId = this.GET_currentUserProfile.entityId;
                this.source.deleted = true;
                this.source.deletedUserId = this.GET_currentUserProfile.entityId;
                this.source.deletedDateTime = this.MIX_formatDateTimeNow("toISOString");
                await this.REDIS_update("source", this.source.entityId, this.source);
                this.loading = false;
                this.confirmBox = false;
                this.MIX_alertBox({ show: true, message: "Source Deleted", color: "success", timeout: "2000" });
                this.getTableData();
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Deleting Source", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        // Save User
        async saveSource() {
            try {
                this.loading = true;
                this.source.lastFeedDateTime = this.MIX_formatDateTimeNow("toISOString");
                this.source.modifiedDateTime = this.MIX_formatDateTimeNow("toISOString");
                this.source.modifiedUserId = this.GET_currentUserProfile.entityId;
                let validationErrors = 0;
                if (this.source.name === "") {
                    this.validate.name = "Name is required";
                    validationErrors++;
                }
            
                if (this.source.website === "") {
                    this.validate.website = "Website is required";
                    validationErrors++;
                }
                // loop through the this.source object and trim all the values
                Object.keys(this.source).forEach((key) => {
                    if (typeof this.source[key] === "string") {
                        this.source[key] = this.source[key].trim();
                        // remove any . from the end of the string
                        if (this.source[key].slice(-1) === ".") {
                            this.source[key] = this.source[key].slice(0, -1);
                        }
                    }
                });
                // If no validation errors
                if (this.action === "Add" && validationErrors === 0) {
                    this.source.createdUserId = this.GET_currentUserProfile.entityId;
                    this.source.createdDateTime = this.MIX_formatDateTimeNow("toISOString");
                    // console.log("addsource=", JSON.stringify(this.source, 2, null));
                    await this.REDIS_create("source", this.source);
                    this.loading = false;
                    this.source = { ...this.$schema.source };
                    this.drawer = false;
                    this.editDrawer = false;
                    this.MIX_alertBox({ show: true, message: "Source Saved", color: "success", timeout: "2000" });
                    this.getTableData();

                } else if (this.action === "Edit" && validationErrors === 0) {
                    // console.log("editsource=", JSON.stringify(this.source, 2, null));
                    await this.REDIS_update("source", this.source.entityId, this.source);
                    this.loading = false;
                    this.source = { ...this.$schema.source };
                    this.editDrawer = false;
                    this.drawer = false;
                    this.MIX_alertBox({ show: true, message: "Changes Saved", color: "success", timeout: "2000" });
                    this.getTableData();
                } else {
                    this.loading = false;
                }
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Saving", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        // Initialise
        async initialise() {
            this.loading = true;
            this.source = { ...this.$schema.source };
            let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{source_status}");
            this.lookups = lookupResult.data.documents;
            this.sourceStatus = lookupResult.data.documents.filter((item) => item.type === "source_status");
            this.getTableData();
            this.loading = false;
        },
        // Update Table Options
        updateTableOptions(options) {
            this.tableOptions = options;
        },
        // Get Organisations
        async getOrganisations() {
            try {
                this.loading = true;
                let query = `@deleted:{false} @type:{${this.source.orgType}}`;
                if (this.source.orgType !== "") {
                    let organisationResult = await this.REDIS_searchFor("org", "", "", "name", "asc", query);
                    this.organisations = organisationResult.data.documents;
                } else {
                    this.organisations = [];
                }
                this.loading = false;
            } catch (error) {
                this.MIX_alertBox({ show: true, message: "Error Getting Organisations", color: "error", timeout: "4000" });
                console.error(error);
            }
        },
        updateOrgName() {
            if (this.source.orgId !== "") {
                this.source.orgName = this.organisations.find((item) => item.entityId === this.source.orgId).name;
                return;
            }
        },
        updateStatus(status) {
            this.source.status = status;
            this.saveSource();
        },
        toggleFilters() {
            if (this.drawer) {
                this.drawer = false;
            }
            this.filterDrawer = !this.filterDrawer;
        },
    },
    created() {
        this.initialise();
    },
};
</script>

<style scoped>
.leftside-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control the darkness */
    z-index: 99; /* Specify a stack order in case you're using a different order for other elements */
}

.d-flex {
    gap: 15px;
}
</style>
