import Vue from "vue";
import App from "@/App.vue";
import router from "@/router.js";
import store from "@/store.js";
import vuetify from "@/vuetify.js";
import "@/registerServiceWorker.js";
import i18n from "./i18n";

// Redis Integration
import redisMixin from "@/redis/redisMixin.js";
Vue.use(redisMixin);

// Axios Integration
import axios from "axios";
Vue.prototype.$axios = axios;

// Import Personalisation
import { config } from "@/config.js";
Vue.prototype.$config = config;

//* FIREBASE INTEGRATION
const firebase = require("@/firebase/firebaseConfig.js"); // Import Firebase Config for Firebase Object Access
Vue.prototype.$firebase = firebase; // Prototype Firebase for use throughout Project $firebase
import firebaseMixin from "@/firebase/firebaseMixin";
Vue.use(firebaseMixin);

// Animations Import
import "animate.css";
// Icons8 CSS Import
import "@/assets/css/styles.min.css";

// Input Components Import
import TextField from "@/components/form/TextField.vue";
Vue.component("TextField", TextField);

import TextArea from "@/components/form/TextArea.vue";
Vue.component("TextArea", TextArea);

import DropdownAdvanced from "@/components/form/DropdownAdvanced.vue";
Vue.component("DropdownAdvanced", DropdownAdvanced);

import DropdownSimple from "@/components/form/DropdownSimple.vue";
Vue.component("DropdownSimple", DropdownSimple);

import DropdownMultiple from "@/components/form/DropdownMultiple.vue";
Vue.component("DropdownMultiple", DropdownMultiple);

import SearchField from "@/components/form/SearchField.vue";
Vue.component("SearchField", SearchField);

import DatePicker from "@/components/form/DatePicker.vue";
Vue.component("DatePicker", DatePicker);

import TimePicker from "@/components/form/TimePicker.vue";
Vue.component("TimePicker", TimePicker);

import PasswordField from "@/components/form/PasswordField.vue";
Vue.component("PasswordField", PasswordField);

import CheckboxField from "@/components/form/CheckboxField.vue";
Vue.component("CheckboxField", CheckboxField);

import RadioField from "@/components/form/RadioField.vue";
Vue.component("RadioField", RadioField);

import BrowseFile from "@/components/form/BrowseFile.vue";
Vue.component("BrowseFile", BrowseFile);

// General Components
import TermsConditions from "@/components/general/TermsConditions.vue";
Vue.component("TermsConditions", TermsConditions);
import AlertBox from "@/components/general/AlertBox.vue";
Vue.component("AlertBox", AlertBox);
import ConfirmBox from "@/components/general/ConfirmBox.vue";
Vue.component("ConfirmBox", ConfirmBox);
import ImportBox from "@/components/general/ImportBox.vue";
Vue.component("ImportBox", ImportBox);
import ExportBox from "@/components/general/ExportBox.vue";
Vue.component("ExportBox", ExportBox);
import SimpleTable from "@/components/general/SimpleTable.vue";
Vue.component("SimpleTable", SimpleTable);
import DataTable from "@/components/general/DataTable.vue";
Vue.component("DataTable", DataTable);
import AppButton from "@/components/general/AppButton.vue";
Vue.component("AppButton", AppButton);
import DropdownMenu from "@/components/general/DropdownMenu.vue";
Vue.component("DropdownMenu", DropdownMenu);
import CopyrightBanner from "@/components/general/CopyrightBanner.vue";
Vue.component("CopyrightBanner", CopyrightBanner);
import NotesComponent from "@/components/general/NotesComponent.vue";
Vue.component("NotesComponent", NotesComponent);

// Card Components
import BasicCard from "@/components/cards/BasicCard.vue";
Vue.component("BasicCard", BasicCard);
import DashCard from "@/components/cards/DashCard.vue";
Vue.component("DashCard", DashCard);
import AlertCard from "@/components/cards/AlertCard.vue";
Vue.component("AlertCard", AlertCard);

// Chart Components
import PieChart from '@/components/charts/PieChart.vue';
Vue.component('PieChart', PieChart);
import DonutChart from '@/components/charts/DonutChart.vue';
Vue.component('DonutChart', DonutChart);
import BarChart from '@/components/charts/BarChart.vue';
Vue.component('BarChart', BarChart);

// Other Components
import DateCalendar from "@/components/DateCalendar.vue";
Vue.component("DateCalendar", DateCalendar);
// import QRCodeReader from "@/components/QRCodeReader.vue";
// Vue.component("QRCodeReader", QRCodeReader);

// Date Library
import dayjs from "dayjs";
var customParseFormat = require("dayjs/plugin/customParseFormat");
Vue.prototype.$dayjs = dayjs.extend(customParseFormat);

// Mixin
import mixinGeneral from "@/mixinGeneral.js";
Vue.use(mixinGeneral);
import mixinCustom from "@/mixinCustom.js";
Vue.use(mixinCustom);
import mixinNav from "./mixinNav.js";
Vue.use(mixinNav);
import mixinSW from "./mixinSW.js";
Vue.use(mixinSW);

// Language
// import VueI18n from 'vue-i18n'
// Vue.use(VueI18n)

// Schema
import schema from "@/schema.js";
Vue.prototype.$schema = schema;

// Custom Libraries
import VueSignature from "vue-signature-pad";
Vue.use(VueSignature);
import VueMask from 'v-mask'
Vue.use(VueMask);
import VuePapaParse from 'vue-papa-parse'
import './registerServiceWorker'
Vue.use(VuePapaParse)

Vue.config.productionTip = false;

// import { getAuth, onAuthStateChanged } from "firebase/auth";

// const auth = getAuth();
// onAuthStateChanged(auth, async (user) => {
//     console.log("Firebase Auth State Changed");
//     console.log(user);
//     const url = `http://localhost:8081/api/v1/user/${user.uid}`;
//     let userProfile = await axios.get(url);
//     await store.dispatch("ACT_currentUserProfile", userProfile.data.data);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
// });
