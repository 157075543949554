import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import ResetPassword from "@/views/ResetPassword.vue";

import MatchesView from "@/views/MatchesView.vue";

// Auth
import NotAuthorised from "@/views/NotAuthorised.vue";
import PendingView from "@/views/PendingView.vue";

// Admin Views
import HomeView from "@/views/HomeView.vue";
import UsersAdmin from "@/views/UsersAdmin.vue";
import LookupAdmin from "@/views/LookupAdmin.vue";
import ClientsView from "@/views/ClientsView.vue";
import ClientView from "@/views/ClientView.vue";
import BusinessView from "@/views/BusinessView.vue";
import SourcesAdmin from "@/views/SourcesAdmin.vue";
import LogsAdmin from "@/views/LogsAdmin.vue";
import AdminView from "@/views/AdminView.vue";

// import axios from "axios";

// import { getAuth, onAuthStateChanged } from "firebase/auth";
import store from "@/store.js";

Vue.use(VueRouter);

const routes = [
	// All Users
	// Authentication Not Required
	{ path: "/login", name: "Login", component: Login },
	{ path: "/notauthorised", name: "NotAuthorised", component: NotAuthorised },
	{ path: "/register", name: "Register", component: RegisterView },
	{ path: "/resetpassword", name: "ResetPassword", component: ResetPassword },
	{ path: "/match/:orgId/:mailId", name: "MatchesView", component: MatchesView },
	// Authentication Required
	{ path: "/", name: "HomeView", component: HomeView, meta: { requiresAuth: true, hideNavbar: false, item: { level: ["staff", "manager", "director", "administrator"], status: ["approved"] } } },
	{ path: "/pending", name: "PendingView", component: PendingView, meta: { requiresAuth: true, hideNavbar: true } },
	// Project Users
	{ path: "/clients", name: "ClientsView", component: ClientsView, meta: { requiresAuth: true, hideNavbar: false, item: { level: ["staff", "manager", "director", "administrator"], status: ["approved"] } } },
	{ path: "/client/:id", name: "ClientView", component: ClientView, params: true, meta: { requiresAuth: true, hideNavbar: false, item: { level: ["staff", "manager", "director", "administrator"], status: ["approved"] } } },
	{ path: "/business", name: "BusinessView", component: BusinessView, meta: { requiresAuth: true, hideNavbar: false, item: { level: ["staff", "manager", "director", "administrator"], status: ["approved"] } } },
	{ path: "/sources", name: "SourcesAdmin", component: SourcesAdmin, meta: { requiresAuth: true, hideNavbar: false, item: { level: ["administrator"], status: ["approved"] } } },
	{ path: "/logs", name: "LogsAdmin", component: LogsAdmin, meta: { requiresAuth: true, hideNavbar: false, item: { level: ["administrator"], status: ["approved"] } } },
	{ path: "/admin", name: "AdminView", component: AdminView, meta: { requiresAuth: true, hideNavbar: false, item: { level: ["administrator"], status: ["approved"] } } },
	{ path: "/users", name: "UsersAdmin", component: UsersAdmin, meta: { requiresAuth: true, hideNavbar: false, item: { level: ["administrator"], status: ["approved"] } } },
	{ path: "/lookups", name: "LookupAdmin", component: LookupAdmin, meta: { requiresAuth: true, hideNavbar: false, item: { level: ["administrator"], status: ["approved"] } } },
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
});

const checkLevelAndStatus = (item, userProfile, userAuthenticated) => {
	if (userAuthenticated) {
		if (item.status !== undefined && item.level !== undefined) {
			let checkError = 0;
			if (!item.status.includes(userProfile.status)) {
				checkError++;
			}
			if (!item.level.includes(userProfile.level)) {
				checkError++;
			}
			if (checkError === 0) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}
};

router.beforeEach(async (to, from, next) => {
	try {
		// console.log((to.meta.requiresAuth ? 'requiresAuth' : 'noAuth') + ' ' + to.path )
		// Does route require Auth
		if (to.meta.requiresAuth) {
			// Yes - Route Requires Auth
			// Update Service Worker
			navigator.serviceWorker.register("/service-worker.js").then((reg) => {
				reg.update();
			});
			// Get User Profile
			let userProfile = store.state.currentUserProfile;
			// If User Profile is null then user needs to login
			if (userProfile === null) {
				// Redirect to Login
				next({ name: "Login" });
			} else {
				if (to.meta.item !== undefined) {
					// console.log('to.meta.item = ' + JSON.stringify(to.meta.item, null, 2))
					// console.log('userProfile = ' + JSON.stringify(userProfile, null, 2))
					if (["pending", "suspended", "archived", "rejected"].includes(userProfile.status)) {
						// console.log('Pending User');
						next("/pending");
					} else {
						// console.log('Not Pending User')
						if (checkLevelAndStatus(to.meta.item, userProfile, true)) {
							// console.log('Unauthorised Access', to.meta.item);
							// console.log('userProfile', userProfile);
							next("/notauthorised");
						} else {
							next();
						}
					}
				} else {
					// Move to Next Route
					next();
				}
			}
		} else {
			// No - Route Does Not Require Auth
			// Move to Next Route
			next();
		}
	} catch (error) {
		console.error("An Error Occured", error);
		next("/notauthorised");
	}
});

export default router;
