<template>
    <div>
        <v-row dense class="mt-5">
            <v-col cols="12" class="d-flex justify-center">
                <img src="@/assets/gsverde_colour.png" width="200" contain alt="AR Deals" />
            </v-col>
            <v-col cols="12">
                <div class="d-flex align-start px-5 mb-4 text-h4 primary--text font-weight-bold" style="height: 70px">
                    {{ business?.title }}
                </div>
            </v-col>
            <!-- BUSINESS TABS -->
            <v-col cols="12" class="grey lighten-3">
                <v-tabs v-model="businessTabs" fixed-tabs background-color="primary" dark>
                    <v-tab href="#description"> Description </v-tab>
                    <v-tab href="#financials"> Financials </v-tab>

                    <!-- DESCRIPTION -->
                    <v-tab-item value="description" class="white pa-5 pt-2" style="height: calc(100vh - 276px); overflow-y: auto">
                        <div class="d-flex justify-end pt-3"><v-btn @click="contactDialog = !contactDialog" class="elevation-0 grey white--text">Contact me with more information</v-btn></div>
                        <div class="mt-4 text-h6 primary--text">Description</div>
                        <div class="mt-2 text-body-1" v-html="business?.description"></div>

                        <div class="mt-4 text-body-1" v-if="JSON.stringify(business.location) !== '[]' && business?.location">
                            <div class="text-h6 primary--text">Location</div>
                            <v-chip label v-for="(location, index) in business.location" :key="index" class="mr-1 my-1 secondary lighten-2 primary--text">
                                <span v-if="lookupValue(location) !== ''">{{ lookupValue(location) }}</span>
                                <span v-else>{{ location }}</span>
                            </v-chip>
                        </div>
                        <div class="mt-4 text-body-1" v-if="JSON.stringify(business.sectors) !== '[]' && business?.sectors">
                            <div class="text-h6 primary--text">Sectors</div>
                            <v-chip label v-for="(sector, index) in business.sectors" :key="index" class="mr-1 my-1 secondary lighten-2 primary--text">{{ lookupValue(sector) }}</v-chip>
                        </div>
                        <div class="mt-4 text-body-1" v-if="JSON.stringify(business.subsectors) !== '[]' && business?.subsectors">
                            <div class="text-h6 primary--text">Subsectors</div>
                            <v-chip label v-for="(subsector, index) in business.subsectors" :key="index" class="mr-1 my-1 secondary lighten-2 primary--text">{{ lookupValue(subsector) }}</v-chip>
                        </div>
                        <!-- <div class="mt-4 text-body-1" :style="$vuetify.breakpoint.width >= 600 && $vuetify.breakpoint.width < 880 ? 'padding-bottom: 80px;' : ''" v-if="JSON.stringify(business.keywords) !== '[]' && business?.keywords">
                            <div class="text-h6 primary--text">Keywords</div>
                            <v-chip @click:close="addIgnoreKeyword(keyword)" close close-icon="mdi-delete" label v-for="(keyword, index) in filterIgnoreList(business.keywords)" :key="index" class="mr-1 my-1 secondary lighten-2 primary--text">{{ keyword }}</v-chip>
                        </div> -->
                    </v-tab-item>

                        <!-- FINANCIALS -->
                    <v-tab-item value="financials" class="grey lighten-3" style="height: calc(100vh - 276px); overflow-y: auto">
                        <div class="mt-2 px-5"><SimpleTable :tabledata="computedBusiness" class="my-3 white" /></div>
                    </v-tab-item>

                </v-tabs>
            </v-col>
        </v-row>

        <v-dialog style="z-index: 3000 !important" v-model="contactDialog" persistent max-width="512" >
            <v-card class="white">
                <v-card-title class="primary white--text">Confirmation: Further Information Request</v-card-title>
                <v-card-text class="mt-4">
                    <div class="font-weight-bold">Please confirm by using the button below that you wish for further information to be sent to your email in regards to this match.</div>
                    <div class="text-medium pt-2">This will send an email informing the Client Manager about the need for further information to be sent promptly.</div>
                </v-card-text>
                <v-card-actions class="text-center">
                    <!--Dialog Actions-->
                    <v-row class="ma-0 pa-0" justify="center">
                        <v-col cols="6">
                            <AppButton @click.native="contactDialog = !contactDialog" buttonclass="primary white--text" block>No</AppButton>
                        </v-col>
                        <v-col cols="6">
                            <AppButton block @click.native="sendMoreInfo()" buttonclass="green white--text">Confirm</AppButton>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'MatchesView',
    data: () => ({
        businessTabs: "details",
        business: {},
        org: {},

        mail: {},

        // LOOKUPS
        lookups: [],
        sectors: [],
        ignorekeywordlist: [],

        // DIALOGS
        contactDialog: false,
    }),
    // computed
    computed: {
        computedBusiness() {
            return [
                {
                    text: "Turnover",
                    value: this.business.turnover !== 0 ? this.MIX_formatCurrency(this.business.turnover, 0) : "-",
                },
                {
                    text: "Profit",
                    value: this.business.profit !== 0 ? this.MIX_formatCurrency(this.business.profit, 0) : "-",
                },
                {
                    text: "EBITDA",
                    value: this.business.ebitda !== 0 ? this.MIX_formatCurrency(this.business.ebitda, 0) : "-",
                },
                {
                    text: "Asking Price",
                    value: this.business.price !== 0 ? this.MIX_formatCurrency(this.business.price, 0) : "-",
                },
                {
                    text: "Financial Year",
                    value: this.business.financialYear,
                },
                {
                    text: "Source",
                    value: this.business.sourceName,
                    link: this.business.sourceURL,
                },
                {
                    text: "Ref",
                    value: this.business.ref,
                },
                {
                    text: "Listing Date",
                    value: this.MIX_formatDateTime(this.business.listingDateTime, "ISOString", "DD MMM YYYY"),
                },
                {
                    text: "Last Seen",
                    value: this.MIX_formatDateTime(this.business.lastSeenDateTime, "ISOString", "DD MMM YYYY"),
                },
            ];
        },
    },

    // methods
    methods: {
        async openBusiness(item) {
            let businessResult = await this.REDIS_read("business", item);
            this.business = businessResult.data;
        },
        async openMail(item) {
            let mailResult = await this.REDIS_read("mail", item);
            this.mail = mailResult.data;

            // Update the mail to be read
            this.mail.readDateTime = this.MIX_formatDateTimeNow("toISOString");
            await this.REDIS_update("mail", this.mail.entityId, this.mail);
        },
        lookupValue(value) {
            if (value !== "") {
                let lookup = this.lookups.find((item) => item.value === value);
                if (lookup !== undefined) {
                    return lookup.text;
                } else {
                    return "";
                }
            }
        },
        filterIgnoreList(keywords) {
            // if any of the keywords are in the ignoreList then filter them out
            const result = [];
            for (let i = 0; i < keywords.length; i++) {
                if (!this.ignorekeywordlist.includes(keywords[i])) {
                    result.push(keywords[i]);
                }
            }
            return result;
        },
        async sendMoreInfo() {
            console.log('send more info')
            
            // Send an email to the Client Manager
            let mailData = {...this.$schema.mail }
            
            mailData.fromEmail = 'deals@gsverde.group';
            mailData.fromName = 'ARDeals - GSVerde';

            var clientManager = await this.getClientManager();


            // Change the email address for testing
            mailData.toEmail = clientManager.email;
            mailData.toName = clientManager.firstName + ' ' + clientManager.lastName;
            // mailData.toEmail = 'emilie@vindico.net'
            // mailData.toName = 'Emilie'

            mailData.subject = 'Further Information Request';
            mailData.templateId = 'd-012d52f0c6fb4736946f4578b6a45071'

            mailData.toOrgId = this.mail.toOrgId;


            var data = {
                "businessName": this.business.title,
                "clientName": this.org.name ? this.org.name : this.org.contactFirstName + ' ' + this.org.contactLastName,
                "clientManagerName": clientManager.firstName + ' ' + clientManager.lastName,
            }
            mailData.data = [];
            mailData.data.push(data)

            mailData.createdName = this.mail.toName;
            mailData.createdUserId = this.mail.toOrgId;
            mailData.createdDateTime = this.MIX_formatDateTimeNow("toISOString");

            await this.REDIS_sendEmail(mailData)
            this.MIX_alertBox({ show: true, message: "Email Sent", color: "success", timeout: "2000" });
            this.contactDialog = !this.contactDialog;
        },
        async getClientManager() {
            // Get Details of the Organisation
            let orgData = await this.REDIS_read("org", this.mail.toOrgId);
            this.org = orgData.data;

            let clientManagerId = this.org.ownerUserId

            // Get details of the Owner of the Organisation (Client Manager)
            let userData = await this.REDIS_read("user", clientManagerId);
            let user = userData.data;

            return user
        }
    },

    // * watch
    watch: {
    },

    // * MOUNTED
    async mounted() {
        var orgId = this.$route.params.orgId;
        var mailId = this.$route.params.mailId;
        
        await this.openBusiness(orgId)
        await this.openMail(mailId)



        // Ignore Keywords List
        let ignorekeywordlistResult = await this.REDIS_getIgnoreKeywordList();
        this.ignorekeywordlist = ignorekeywordlistResult.data;

        // Lookups
        let lookupResult = await this.REDIS_searchFor("lookup", "", "", "text", "asc", "@deleted:{false} @type:{county|country|city|region|organisation_type|business_type|organisation_status|sector|subsector|business_ownership|organisation_engagement|nda_status|match_status|business_type}");
        this.lookups = lookupResult.data.documents;

        this.sectors = lookupResult.data.documents.filter((item) => item.type === "sector");
    },
};
</script>